import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles, Divider, List } from '@material-ui/core';
import ProfileDetailRenderer from '../../common/ProfileDetailRenderer';

const useStyles = makeStyles(theme => ({
	profilesDisplay: {
		width: '100%'
	}
}));

export default function ProfilesDisplay({ profiles }) {
	const classes = useStyles();
	const [expandedProfileId, setExpandedProfileId] = useState(0);

	useEffect(() => {
		if (profiles && profiles.length === 1) {
			setExpandedProfileId(profiles[0].profile_id);
		}
	}, [profiles]);

	function handleClick(profileId) {
		if (expandedProfileId !== profileId) {
			setExpandedProfileId(profileId);
		}
	}

	return (
		<List className={classes.profilesDisplay}>
			{profiles &&
				profiles.map((profile, index) => (
					<Fragment key={index}>
						{index !== 0 && <Divider component="li" />}
						<ProfileDetailRenderer
							profile={profile}
							expand={profile.profile_id === expandedProfileId}
							onClick={() => handleClick(profile.profile_id)}
						/>
					</Fragment>
				))}
		</List>
	);
}
