import React from 'react';
import iconPaths from './selection.js';
import PropTypes from 'prop-types';

function getPath(iconName) {
    const icon = iconPaths.icons.find(icon => icon.properties.name === iconName);

    if (icon) {
        return icon.icon.paths.join(' ');
    } else {
        console.warn(`icon ${iconName} does not exist.`);
        return '';
    }
}

const PSIcon = props => (
    <svg width="22" height="22" viewBox="0 0 1024 1024">
        <path d={getPath(props.icon)}></path>
    </svg>
);

PSIcon.propTypes = {
    icon: PropTypes.string.isRequired,
};

export default PSIcon;