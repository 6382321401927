import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function stopPropagation(event) {
	event.stopPropagation();
	event.nativeEvent.stopImmediatePropagation();
}

const useStyles = makeStyles(theme => ({
	dialogAction: {
		justifyContent: 'center'
	},
	button: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1)
		// width: "100%"
	},
	dialogContent: {
		flex: 1,
		display: 'flex'
	},
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		padding: 0
	}
}));

/**
 * Confirmation dialog that allows for customizable title, dialog content and
 * submit button text. Includes callbacks that are triggered when the user
 * either confirms or closes the dialog.
 *
 * See also `useConfirmation()` hook and `ConfirmationRequiredButton`.
 */
export function ConfirmationDialog({
	title,
	text,
	cancelLabel = 'Cancel',
	confirmLabel = 'OK',
	children,
	open,
	onClose,
	onConfirm
}) {
	const classes = useStyles();
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClick={stopPropagation}
		>
			<DialogTitle disableTypography className={classes.root}>
				<IconButton
					aria-label="close"
					color="primary"
					className={classes.closeButton}
					onClick={onClose}
				>
					<HighlightOffIcon />
				</IconButton>
			</DialogTitle>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				{text && (
					<DialogContentText id="alert-dialog-description">
						{text}
					</DialogContentText>
				)}
				{children}
			</DialogContent>
			{/* <DialogActions className={classes.dialogContent}> */}
			<Button
				onClick={onConfirm}
				variant="contained"
				color="primary"
				className={classes.button}
			>
				{confirmLabel}
			</Button>
			<Button
				onClick={onClose}
				// variant="contained"
				color="primary"
				className={classes.button}
			>
				{cancelLabel}
			</Button>
			{/* </DialogActions> */}
		</Dialog>
	);
}

export default ConfirmationDialog;
