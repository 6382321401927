import _ from 'lodash';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { actions as reportActions, reportReducer } from './reports';
import { actions as accountActions, accountReducer } from './accounts';

const FETCH_RECORD_LIMIT = 900;

const REPORT_TEMPLATE = {
	gridState: null,
	id: null,
	title: null,
	type: 'ig-accounts',
	update_ts: 0
};

const INITIAL_STATE = {
	fetchRecordLimit: FETCH_RECORD_LIMIT,
	fetchRequestTimestamp: 0,
	statusFlags: {
		loadingData: false,
		fetchingEvents: false,
		applyingReportDef: false
	},
	reportToLoad: null,
	reportDef: { ...REPORT_TEMPLATE },
	reportModified: false,
	record_dict: {},
	records: []
};

//endregion Constants

//region Action Types

const ACTION_TYPE_PREFIX = 'HASHTAGR:IG-ACCOUNTS:';

const SET_STATUS_FLAG = ACTION_TYPE_PREFIX + 'SET_STATUS_FLAG';
const SET_FETCH_REQUEST_TS = ACTION_TYPE_PREFIX + 'SET_FETCH_REQUEST_TS';
const SET_FETCH_RECORD_LIMIT = ACTION_TYPE_PREFIX + 'SET_FETCH_RECORD_LIMIT';

// report action types
const SET_REPORT_BY_ID = ACTION_TYPE_PREFIX + 'SET_REPORT_BY_ID';
const SET_REPORT_GRID_STATE = ACTION_TYPE_PREFIX + 'SET_REPORT_GRID_STATE';
const CHANGE_REPORT_TITLE = ACTION_TYPE_PREFIX + 'CHANGE_REPORT_TITLE';
const CHANGE_REPORT_ID = ACTION_TYPE_PREFIX + 'CHANGE_REPORT_ID';

//endregion Action Types

/** Sets the value of a status flag. */
function setStatusFlag(flag, value) {
	return {
		type: SET_STATUS_FLAG,
		flag: flag,
		value: value
	};
}

function setFetchRecordLimit(value) {
	return {
		type: SET_FETCH_RECORD_LIMIT,
		fetchRecordLimit: value
	};
}

function setFetchRequestTimestamp(value) {
	return {
		type: SET_FETCH_REQUEST_TS,
		fetchRequestTimestamp: value
	};
}

function fetchAllRecords() {
	return (dispatch, getState) => {
		const ts = new Date().getTime();
		dispatch(setFetchRequestTimestamp(ts));
		dispatch(setStatusFlag('loadingData', true));

		dispatch(fetchRecordsPage(ts));
	};
}

function fetchRecordsPage(ts, lastEvaluatedKey = null) {
	return (dispatch, getState) => {
		let { fetchRecordLimit, fetchRequestTimestamp } = { ...getState().ui };

		// Cancel request if newer request has been made
		if (ts !== fetchRequestTimestamp) {
			return;
		}

		// Request page of records
		let query = { limit: fetchRecordLimit };
		if (lastEvaluatedKey) {
			query.lastEvaluatedKey = lastEvaluatedKey;
		}
		dispatch(accountActions.fetchAccounts({ ts: ts }, { query: query }));
	};
}

// Report Actions

function setReportById(value) {
	return {
		type: SET_REPORT_BY_ID,
		reportId: value
	};
}

function setReportGridState(value) {
	return {
		type: SET_REPORT_GRID_STATE,
		gridState: value
	};
}

function changeReportTitle(title) {
	return {
		type: CHANGE_REPORT_TITLE,
		title: title
	};
}

function changeReportId(id) {
	return {
		type: CHANGE_REPORT_ID,
		id: id
	};
}

function createNewReport(title, id) {
	return (dispatch, getState) => {
		dispatch(changeReportId(id));
		dispatch(changeReportTitle(title));
		dispatch(reportActions.createReport(getState().ui.reportDef));
	};
}

function saveReport() {
	return (dispatch, getState) => {
		let reportDef = getState().ui.reportDef;
		dispatch(reportActions.updateReport(reportDef, { id: reportDef.id }));
	};
}

function deleteCurrentReport() {
	return (dispatch, getState) => {
		let reportId = getState().ui.reportDef.id;
		if (reportId) {
			dispatch(reportActions.deleteReport(reportId));
			dispatch(setReportById(null));
		}
	};
}

// Action Dispatching...

function useActions() {
	return {
		setStatusFlag: setStatusFlag,
		setFetchRecordLimit: setFetchRecordLimit,
		fetchAllRecords: fetchAllRecords,
		accounts: accountActions,
		reports: reportActions,

		// Report Actions
		setReportById: setReportById,
		setReportGridState: setReportGridState,
		changeReportTitle: changeReportTitle,
		createNewReport: createNewReport,
		saveReport: saveReport,
		deleteCurrentReport: deleteCurrentReport
	};
}

//endregion Actions

//region Reducer

function setReportDefById(state, reports, reportToLoad) {
	let reportDef = reports.items.find(r => r.id === reportToLoad);
	if (reportDef) {
		state.reportDef = _.cloneDeep(reportDef);
		state.reportToLoad = null;
		state.reportModified = false;
	} else {
		state.reportDef = _.cloneDeep(REPORT_TEMPLATE);
		state.reportToLoad = reportToLoad;
		state.reportModified = false;
	}
}

function eventReducer(state = INITIAL_STATE, action, reports, accounts) {
	if (action.type.startsWith(ACTION_TYPE_PREFIX)) {
		let newState = { ...state };
		switch (action.type) {
			case SET_STATUS_FLAG:
				newState.statusFlags[action.flag] = action.value;
				break;
			case SET_FETCH_RECORD_LIMIT:
				newState.fetchRecordLimit = action.fetchRecordLimit;
				break;
			case SET_FETCH_REQUEST_TS:
				newState.fetchRequestTimestamp = action.fetchRequestTimestamp;
				break;
			case SET_REPORT_BY_ID:
				setReportDefById(newState, reports, action.reportId);
				break;
			case SET_REPORT_GRID_STATE:
				if (!state.statusFlags.applyingReportDef && !newState.statusFlags.loadingData) {
					newState.reportDef.gridState = action.gridState;
					newState.reportModified = true;
				}
				break;
			case CHANGE_REPORT_TITLE:
				newState.reportDef.title = action.title;
				newState.reportModified = true;
				break;
			case CHANGE_REPORT_ID:
				newState.reportDef.id = action.id;
				newState.reportModified = true;
				break;
			default:
				console.log('HT IG-Accounts Redux Event Type not handled!', action);
		}
		return newState;
	} else if (action.type === '@@resource/REPORT/FETCH') {
		if (action.status === 'resolved' && action.body.length > 0 && state.reportToLoad) {
			let newState = { ...state };
			setReportDefById(newState, reports, newState.reportToLoad);
			return newState;
		} else {
			return state;
		}
	} else if (action.type === '@@resource/REPORT/CREATE') {
		if (action.status === 'resolved') {
			let newState = { ...state };
			newState.reportModified = false;
			return newState;
		}
	} else if (action.type === '@@resource/REPORT/UPDATE') {
		if (action.status === 'resolved' && action.body.id === state.reportDef.id) {
			let newState = { ...state };
			newState.reportModified = false;
			return newState;
		}
	} else if (action.type === '@@resource/ACCOUNT/FETCH') {
		if (action.status === 'resolved') {
			let newState = { ...state };
			if (action.body.lastEvaluatedKey) {
				action.asyncDispatch(fetchRecordsPage(action.context.ts, action.body.lastEvaluatedKey));
			} else {
				action.asyncDispatch(setStatusFlag('loadingData', false));
			}
			return newState;
		} else {
			return state;
		}
	}
	// else {
	// 	console.log('unhandled action:', action);
	// }

	return state;
}

//endregion Reducer

//region Export

const reducer = (state, action) => {
	state = state || {}; // initially, state is undefined
	let new_report_state = reportReducer(state.reports, action);
	let new_account_state = accountReducer(state.accounts, action);
	let new_ui_state = eventReducer(state.ui, action, new_report_state, new_account_state);

	// console.log(action.type, new_report_state, new_account_state, new_ui_state);

	let new_state = {
		reports: new_report_state,
		accounts: new_account_state,
		ui: new_ui_state
	};

	return new_state;
};

// This middleware will just add the property "async dispatch" to all actions
const asyncDispatchMiddleware = store => next => action => {
	let syncActivityFinished = false;
	let actionQueue = [];

	function flushQueue() {
		actionQueue.forEach(a => store.dispatch(a)); // flush queue
		actionQueue = [];
	}

	function asyncDispatch(asyncAction) {
		actionQueue = actionQueue.concat([asyncAction]);

		if (syncActivityFinished) {
			flushQueue();
		}
	}

	const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

	const res = next(actionWithAsyncDispatch);

	syncActivityFinished = true;
	flushQueue();

	return res;
};

const store = createStore(reducer, applyMiddleware(thunk, asyncDispatchMiddleware));
export { reducer, store, useActions };

//endregion Export
