const ONE_WEEK_MS = 1000 * 60 * 60 * 24 * 7;
const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

export { DAYS_OF_WEEK, MONTHS };

export function getDateFromFormattedString(dateString) {
	var dateParts = dateString.split('-');
	var year = Number(dateParts[0]);
	var month = Number(dateParts[1]) - 1;
	var day = Number(dateParts[2]);
	return new Date(year, month, day);
}

export function getFormattedDate(date) {
	if (!date) {
		date = new Date();
	}
	return date.toISOString().slice(0, 10);
}

export function addDaysToDate(date, days) {
	date.setDate(date.getDate() + days);
	return date;
}

export function dateIsValid(date) {
	return date instanceof Date && !isNaN(date);
}

export function timestampToFormattedDate(ts) {
	return getFormattedDate(new Date(ts * 1000));
}

export function secondsToStr(delta) {
	const years = Math.floor(delta / 31536000),
		days = Math.floor((delta %= 31536000) / 86400),
		hours = Math.floor((delta %= 86400) / 3600),
		minutes = Math.floor((delta %= 3600) / 60),
		seconds = delta % 60;

	if (days || hours || seconds || minutes) {
		return (
			(years ? years + 'y ' : '') +
			(days ? days + 'd ' : '') +
			(hours ? hours + 'h ' : '') +
			(minutes ? minutes + 'm ' : '') +
			Number.parseFloat(seconds).toFixed(2) +
			's'
		);
	}

	return '< 1s';
}

export function getWeekIndex(timestamp) {
	let date = new Date(timestamp * 1000);
	var start = new Date(date.getFullYear(), 0, 0);
	var diff = date - start + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
	var week = Math.floor(diff / ONE_WEEK_MS);

	return week;
}

export function getDayOfWeek(timestamp) {
	let date = new Date(timestamp * 1000);
	return DAYS_OF_WEEK[date.getDay()];
}

export function getMonth(timestamp) {
	let date = new Date(timestamp * 1000);
	return MONTHS[date.getMonth()];
}

export function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
}
