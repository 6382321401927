import {
	getDateFromFormattedString,
	timestampToFormattedDate,
	// secondsToStr,
	getWeekIndex,
	getDayOfWeek,
	getMonth,
	DAYS_OF_WEEK,
	MONTHS
} from '../utils';

const DEFAULT_COLUMN_DEF = {
	minWidth: 100,
	// allow every column to be aggregated
	enableValue: true,
	// allow every column to be grouped
	enableRowGroup: true,
	// allow every column to be pivoted
	enablePivot: true,
	sortable: true,
	filter: true,
	resizable: true,
	floatingFilter: true
};

const TIMESTAMP_FIELDS = [
	{ field: 'start_ts', headerPrefix: 'Start', colIdPrefix: 'start' },
	{ field: 'end_ts', headerPrefix: 'End', colIdPrefix: 'end' }
];

const COLUMN_DEFS = [
	{
		headerName: 'Event ID',
		field: 'event_id',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Service',
		field: 'service',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Event Status',
		field: 'status',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Status Code',
		field: 'status_code',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Scan Target',
		field: 'target',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Hashtag',
		field: 'hashtag',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Duration',
		field: 'duration',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Scan Posts Timestamp',
		field: 'scan_posts_ts',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Scan Posts Delay',
		filter: 'agNumberColumnFilter',
		valueGetter: params => {
			if (params.data && params.data.start_ts && params.data.scan_posts_ts) {
				return params.data.scan_posts_ts - params.data.start_ts;
			}
		},
		cellRenderer: ({ value }) => {
			if (value) {
				var d = Math.floor(value / 86400),
					h = ('0' + (Math.floor(value / 3600) % 24)).slice(-2),
					m = ('0' + (Math.floor(value / 60) % 60)).slice(-2),
					s = ('0' + Math.floor(value % 60)).slice(-2);
				return (
					(d > 0 ? d + 'd ' : '') +
					(d > 0 || h > 0 ? h + 'h ' : '') +
					(d > 0 || h > 0 || m > 0 ? m + 'm ' : '') +
					(value > 1 ? s + 's' : '< 1 s')
				);
			}
		}
	},
	{
		headerName: 'Posts Per Second',
		field: 'posts_per_sec',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Posts Since Last Scan',
		field: 'posts_since_last_scan',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'New Posts',
		field: 'new_posts',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Posts With Hashtag',
		field: 'posts_with_scanned_tag',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Original Post Count',
		field: 'original_related_post_count',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Related Post Count',
		field: 'related_post_count',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Hashtag Post Count',
		field: 'count',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Account in DB',
		field: 'account_exists',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Account ID',
		field: 'account_id',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Account Username',
		field: 'account_username',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Account Status',
		field: 'account_status',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Account Proxy',
		field: 'account_proxy',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Scan Group',
		field: 'scan_group',
		filter: 'agTextColumnFilter'
	}
];

TIMESTAMP_FIELDS.forEach(element => {
	COLUMN_DEFS.push(
		{
			headerName: element.headerPrefix + ' Datetime',
			colId: element.colIdPrefix + 'Datetime',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return params.data[element.field];
				}
			},
			filter: 'agTextColumnFilter',
			hide: true,
			cellRenderer: ({ value }) => {
				if (value) {
					let jsts = value * 1000;
					let jsd = new Date(jsts).toLocaleString();
					return jsd.toLocaleString();
				}
			}
		},
		{
			headerName: element.headerPrefix + ' Timestamp',
			field: element.field,
			filter: 'agNumberColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Date',
			colId: element.colIdPrefix + 'Date',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return timestampToFormattedDate(params.data[element.field]);
				}
			},
			filter: 'agDateColumnFilter',
			filterParams: {
				comparator: function(filterLocalDateAtMidnight, cellValue) {
					if (cellValue === null) return 0;

					var cellDate = getDateFromFormattedString(cellValue);

					if (cellDate < filterLocalDateAtMidnight) {
						return -1;
					} else if (cellDate > filterLocalDateAtMidnight) {
						return 1;
					} else {
						return 0;
					}
				},
				browserDatePicker: true
			}
		},
		{
			headerName: element.headerPrefix + ' Hour',
			colId: element.colIdPrefix + 'DateHour',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return Math.floor(params.data[element.field] / 60 / 60) % 24;
				}
			},
			filter: 'agNumberColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Day',
			colId: element.colIdPrefix + 'DateDay',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getDayOfWeek(params.data[element.field]);
				}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				values: DAYS_OF_WEEK,
				suppressSorting: true
			},
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Week',
			colId: element.colIdPrefix + 'DateWeek',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getWeekIndex(params.data[element.field]);
				}
			},
			filter: 'agNumberColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Month',
			colId: element.colIdPrefix + 'DateMonth',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getMonth(params.data[element.field]);
				}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				values: MONTHS,
				suppressSorting: true
			},
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Year',
			colId: element.colIdPrefix + 'DateYear',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return new Date(params.data[element.field] * 1000).getUTCFullYear();
				}
			},
			filter: 'agNumberColumnFilter',
			hide: true
		}
	);
});

export { COLUMN_DEFS, DEFAULT_COLUMN_DEF };
