import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';

//import components
import AccountEnabledRenderer from './AccountEnabledRenderer';
import AccountActionsRenderer from './AccountActionsRenderer';
import AccountStatusRenderer from './AccountStatusRenderer';
import { COLUMN_DEFS, DEFAULT_COLUMN_DEF } from './column';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';

function timestampRenderer(ts) {
	if (ts) {
		return (
			<Moment unix fromNow>
				{ts}
			</Moment>
		);
	}
}

const frameworkComponents = {
	accountEnabledRenderer: AccountEnabledRenderer,
	accountStatusRenderer: AccountStatusRenderer,
	timestampRenderer: ({ value }) => timestampRenderer(value),
	accountActionsRenderer: AccountActionsRenderer
};

function AccountTable({ data }) {
	const [gridColumnApi, setGridColumnApi] = useState(null);

	const sizeToFit = () => {
		var allColumnIds = [];
		gridColumnApi.getAllColumns().forEach(function(column) {
			allColumnIds.push(column.colId);
		});
		gridColumnApi.autoSizeColumns(allColumnIds);
	};

	const onGridReady = params => {
		setGridColumnApi(params.columnApi);
	};

	useEffect(() => {
		if (gridColumnApi) sizeToFit();
	}, [data]);

	return (
		<div
			className="ag-theme-material"
			style={{
				height: '100%',
				widht: '100%'
			}}
		>
			<AgGridReact
				rowData={data}
				columnDefs={COLUMN_DEFS}
				enableRangeSelection={true}
				rowSelection="multiple"
				rowDeselection={true}
				onGridReady={onGridReady}
				groupSelectsChildren={true}
				immutableData={true}
				getRowNodeId={data => data.account_id}
				enableCharts={false}
				animateRows
				defaultColDef={DEFAULT_COLUMN_DEF}
				sideBar={{
					toolPanels: ['columns', 'filters']
				}}
				frameworkComponents={frameworkComponents}
			/>
		</div>
	);
}

export default React.memo(AccountTable);
