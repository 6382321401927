export const TOGGLE_WAIT = "TOGGLE_WAIT";
export const FETCH_PORTAL_DATA = "FETCH_PORTAL_DATA";
export const RECEIVED_PORTAL_DATA = "RECEIVED_PORTAL_DATA";
export const DATA_RETRIEVAL_ERROR = "DATA_RETRIEVAL_ERROR";
export const UPDATE_CAMPAIGN_LIST = "UPDATE_CAMPAIGN_LIST";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const NEW_CAMPAIGN = "NEW_CAMPAIGN";
export const CANCEL_CAMPAIGN = "CANCEL_CAMPAIGN";
export const DASH_DETAIL = "DASH_DETAIL";
export const UPDATE_DASH_DETAILS = "UPDATE_DASH_DETAILS";
export const SET_USER_ACCESS = "SET_USER_ACCESS";
export const LOADING_CAMPAIGN = "LOADING_CAMPAIGN";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const TASKBAR = "TASKBAR";
export const NEW_TOPIC_MODAL = "NEW_TOPIC_MODAL";
export const HASTAGGER_VALUE_HANDLER = "HASTAGGER_VALUE_HANDLER"
export const HANDLE_TAB_INDEX_HASHTAGGER = "HANDLE_TAB_INDEX_HASHTAGGER"