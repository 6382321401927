import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles, useMediaQuery, useTheme, Divider, IconButton, List, SwipeableDrawer } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons/';
import Intercom from 'react-intercom';
import { AdminListItems, loggedInListItems, hashtagUserListItems, loggedInAdminListItems } from './menuItems.js';
import { APP_MENU_DRAWER_WIDTH } from './js/constants/misc';

const useStyles = makeStyles(theme => ({
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: APP_MENU_DRAWER_WIDTH,
			flexShrink: 0,
			whiteSpace: 'nowrap'
		}
	},
	drawerBackground: {
		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.primary.main
		}
	},
	drawerOpen: {
		[theme.breakpoints.up('sm')]: {
			width: APP_MENU_DRAWER_WIDTH,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		}
	},
	drawerClose: {
		[theme.breakpoints.up('sm')]: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9) + 1
			}
		}
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar
	},
	poundWord: {
		color: 'white',
		fontWeight: 'bold',
		fontStyle: 'italic',
		fontSize: '1rem'
	},
	socialWord: {
		color: 'white',
		fontStyle: 'italic',
		fontSize: '1rem'
	},
	chevronLeftIcon: {
		[theme.breakpoints.down('sm')]: {
			color: 'white'
		}
	}
}));

function ResponsiveDrawer({ mobile, children, ...props }) {
	if (mobile) {
		return (
			<SwipeableDrawer
				anchor="left"
				onOpen={() => {
					return null;
				}}
				{...props}
			>
				{children}
			</SwipeableDrawer>
		);
	} else {
		return (
			<SwipeableDrawer
				variant="permanent"
				onOpen={() => {
					return null;
				}}
				{...props}
			>
				{children}
			</SwipeableDrawer>
		);
	}
}

export default function AppMenuDrawer({ open, onCloseButtonClick }) {
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const userGroup = useSelector(state => state.appData.userData.userGroup);
	const isAdmin = userGroup === 'admin';
	return (
		<ResponsiveDrawer
			open={open}
			mobile={mobile}
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open
			})}
			classes={{
				paper: clsx(classes.drawerBackground, { [classes.drawerOpen]: open, [classes.drawerClose]: !open })
			}}
			onClose={onCloseButtonClick}
		>
			<div className={classes.toolbarIcon}>
				<span className={classes.poundWord}>POUND. </span>
				<span className={classes.socialWord}>SOCIAL</span>
				<IconButton onClick={onCloseButtonClick} className={classes.chevronLeftIcon}>
					<ChevronLeftIcon />
				</IconButton>
			</div>
			<Divider />
			<List>{isAdmin ? <AdminListItems /> : hashtagUserListItems}</List>
			<Divider />
			<List>{isAdmin ? loggedInAdminListItems : loggedInListItems}</List>
			{mobile && <Intercom appID="xxoausiu" />}
		</ResponsiveDrawer>
	);
}
