import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import {
	mdiDeleteOutline,
	mdiBackupRestore,
	mdiRenameBox,
	mdiContentSaveOutline,
	mdiPlusBoxOutline,
	mdiTableSettings
} from '@mdi/js';
import { ConfirmationRequiredButton, TooltipButton } from '../../../widgets';

function ActionsRenderer({ onCreateNewReport, onSaveReport, onRenameReport, onResetReport, onDeleteReport }) {
	// Json Data Button
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuOpen = event => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = event => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	const reportId = useSelector(state => state.ui.reportDef.id);
	const reportTitle = useSelector(state => state.ui.reportDef.title);
	const reportModified = useSelector(state => state.ui.reportModified);
	const deleteText = `Are you sure you want to delete report "${reportTitle || reportId}"`;

	return (
		<span>
			<TooltipButton tooltip="Report Actions" icon={mdiTableSettings} iconSize={0.75} onClick={handleMenuOpen} />
			<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
				<MenuItem onClick={handleMenuClose}>
					<TooltipButton
						tooltip="Create New Report"
						icon={mdiPlusBoxOutline}
						iconSize={0.75}
						onClick={onCreateNewReport}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<TooltipButton
						tooltip="Save Report"
						icon={mdiContentSaveOutline}
						iconSize={0.75}
						onClick={onSaveReport}
						disabled={!reportId || !reportModified}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<TooltipButton
						tooltip="Rename Report"
						icon={mdiRenameBox}
						iconSize={0.75}
						onClick={onRenameReport}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<TooltipButton
						tooltip="Reset Report"
						icon={mdiBackupRestore}
						iconSize={0.75}
						onClick={onResetReport}
						disabled={!reportModified}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<ConfirmationRequiredButton
						tooltip="Delete Report"
						icon={mdiDeleteOutline}
						iconSize={0.75}
						onConfirm={onDeleteReport}
						disabled={!reportId}
						title="Delete Report"
						text={deleteText}
					/>
				</MenuItem>
			</Menu>
		</span>
	);
}

export default ActionsRenderer;
