import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import * as Pages from './pages';

const useStyles = makeStyles(theme => ({
	main: {
		flexGrow: 1,
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.5)
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2)
		},
		height: 0
	}
}));

function ConditionalRoute({ path, condition, component, redirect = null }) {
	if (condition) {
		return <Route path={path} component={component} />;
	} else if (redirect) {
		return <Redirect to={redirect} />;
	}
	return null;
}

export default function AppContentSwitch() {
	const classes = useStyles();
	const chargeBeeID = useSelector(state => state.appData.userData.chargeBeeID);
	const access = useSelector(state => state.appData.userData.access);
	const landingPage = useSelector(state => state.appData.userData.landingPage);

	return (
		<main className={classes.main}>
			<Switch>
				<Route path="/logout" component={Pages.Logout} />
				<ConditionalRoute
					path="/dashboard"
					condition={access.dashboard}
					component={Pages.Dashboard}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/portal"
					condition={chargeBeeID}
					component={Pages.AccountPortal}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/hashtagr-app-events"
					condition={access.hashtagger}
					component={Pages.HashtagrAppEvents}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/proxies"
					condition={access.proxies}
					component={Pages.Proxies}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/hashtagr-proxy-events"
					condition={access.hashtagger}
					component={Pages.HashtagrProxyEvents}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/ig-accounts"
					condition={access.profiles}
					component={Pages.InstagramAccounts}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/ig-account-events"
					condition={access.profiles}
					component={Pages.InstagramAccountEvents}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/li-accounts/:accountId"
					condition={access.profiles}
					component={Pages.LinkedInDashboard}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/li/reports/:accountId"
					condition={access.profiles}
					component={Pages.LinkedInReports}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/li/messaging/:accountId"
					condition={access.profiles}
					component={Pages.LinkedInMessaging}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/li/connection-campaigns/:accountId"
					condition={access.profiles}
					component={Pages.LinkedInConnectionCampaigns}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/li/message-campaigns/:accountId"
					condition={access.profiles}
					component={Pages.LinkedInMessageCampaigns}
					redirect={landingPage}
				/>
				<ConditionalRoute
					path="/li-accounts"
					condition={access.profiles}
					component={Pages.LinkedInAccounts}
					redirect={landingPage}
				/>
				<Route path="/closeportal" component={Pages.ClosePortal} />
				<Route render={() => <Redirect to={landingPage} />} />
			</Switch>
		</main>
	);
}
