import React from 'react';
import { useSelector } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { makeStyles, Button, Dialog, DialogContent, DialogTitle, Divider, Grid } from '@material-ui/core';
import { JsonDisplay } from '../../../../../widgets';
import FormActions from '../../../../../widgets/forms/formActions';
import {
	CampaignIDField,
	// EnabledField,
	TitleField,
	MessagesField
} from './components';

import TemplateVariablesPopover from '../../../common/TemplateVariablesPopover';

const useStyles = makeStyles(theme => ({
	root: {
		margin: 20
	},
	title: {
		display: 'flex',
		flexDirection: 'row'
	},
	titleSpacer: {
		flexGrow: 1
	},
	titleButtons: {
		padding: '16px 24px'
	}
}));

function EditFormRenderer({ onClose, handleSubmit, form, submitting, pristine, values }) {
	let rawJsonVisible = useSelector(state => state.rawJsonVisible);
	rawJsonVisible = rawJsonVisible || false; // set to false if null

	return (
		<form onSubmit={handleSubmit} noValidate>
			<Grid container alignItems="flex-start" spacing={1}>
				<Grid item xs={12}>
					<CampaignIDField />
				</Grid>
				{/* <Grid item xs={2}>
					<EnabledField />
				</Grid> */}

				<Grid item xs={12}>
					<TitleField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>

				<Grid item xs={12}>
					<MessagesField />
				</Grid>
				<Button variant="outlined" color="secondary" onClick={() => form.mutators.push('messages', undefined)}>
					Add Autoresponse Message
				</Button>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>

				<FormActions form={form} onClose={onClose} pristine={pristine} submitting={submitting} />
			</Grid>
			<JsonDisplay data={values} visible={rawJsonVisible} />
		</form>
	);
}

function EditFormDialog({ open, data, onSubmit, onClose }) {
	var classes = useStyles();

	return (
		<Dialog
			className={classes.root}
			classes={{
				paper: classes.paper
			}}
			open={open}
			onClose={onClose}
		>
			<div className={classes.title}>
				<DialogTitle>Edit Message Campaign</DialogTitle>
				<div className={classes.titleSpacer} />
				<TemplateVariablesPopover className={classes.titleButtons} />
			</div>

			<DialogContent>
				<div style={{ padding: 2, margin: 1, maxWidth: 600 }}>
					<Form
						onSubmit={onSubmit}
						mutators={{ ...arrayMutators }}
						onClose={onClose}
						initialValues={data}
						render={EditFormRenderer}
					/>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default EditFormDialog;
