import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Grid,
	Typography
} from '@material-ui/core';
import { JsonDisplay } from '../../../../widgets';
import FormActions from '../../../../widgets/forms/formActions';
import {
	AccountIDField,
	EnabledField,
	LinkedInUsernameField,
	LinkedInPasswordField,
	SavedSearchField,
	CustomerField,
	CustomerIDField,
	ProxyHostField,
	ProxyPortField,
	ProxyUsernameField,
	ProxyPasswordField,
	ConnectionMessageEnabledField,
	ConnectionMessageField
} from './components';

function CreateFormRenderer({ onClose, handleSubmit, form, submitting, pristine, values }) {
	let rawJsonVisible = useSelector(state => state.rawJsonVisible);
	rawJsonVisible = rawJsonVisible || false; // set to false if null
	const [proxyPanelOpen, setProxyPanelOpen] = useState(true);

	return (
		<form onSubmit={handleSubmit} noValidate>
			<Grid container alignItems="flex-start" spacing={1}>
				<Grid item xs={6}>
					<AccountIDField />
				</Grid>
				<Grid item xs={6}>
					<EnabledField />
				</Grid>
				<Grid item xs={6}>
					<LinkedInUsernameField />
				</Grid>
				<Grid item xs={6}>
					<LinkedInPasswordField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={6}>
					<CustomerIDField />
				</Grid>
				<Grid item xs={6}>
					<CustomerField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={12}>
					<SavedSearchField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={6}>
					<ConnectionMessageEnabledField />
				</Grid>
				<Grid item xs={12}>
					<ConnectionMessageField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<ExpansionPanel expanded={proxyPanelOpen} onChange={() => setProxyPanelOpen(!proxyPanelOpen)}>
					<ExpansionPanelSummary aria-controls="proxy-panel-content" id="proxy-panel-header">
						<Typography>Proxy</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container alignItems="flex-start" spacing={1}>
							<Grid item xs={6}>
								<ProxyHostField />
							</Grid>
							<Grid item xs={6}>
								<ProxyPortField />
							</Grid>
							<Grid item xs={6}>
								<ProxyUsernameField />
							</Grid>
							<Grid item xs={6}>
								<ProxyPasswordField />
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<FormActions form={form} onClose={onClose} pristine={pristine} submitting={submitting} />
			</Grid>
			<JsonDisplay data={values} visible={rawJsonVisible} />
		</form>
	);
}

function CreateFormDialog({ open, onSubmit, onClose }) {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Add Account</DialogTitle>
			<DialogContent>
				<div style={{ padding: 16, margin: 'auto', maxWidth: 600 }}>
					<Form
						onSubmit={onSubmit}
						onClose={onClose}
						initialValues={{ enabled: true }}
						render={CreateFormRenderer}
					/>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default CreateFormDialog;
