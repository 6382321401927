import React from 'react';
import { Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiAccountCircle, mdiAccountGroup, mdiHelpCircle, mdiCloseCircle, mdiInstagram, mdiLinkedin } from '@mdi/js';
import { blue, grey, green, red } from '@material-ui/core/colors';

const DEFAULT_ICON = mdiHelpCircle;
const ICONS = {
	instagram: mdiInstagram,
	linkedin: mdiLinkedin,
	scanner: mdiAccountGroup,
	unassigned: mdiHelpCircle,
	disabled: mdiCloseCircle,
	customer: mdiAccountCircle
};

const DEFAULT_ICON_COLOR = red[900];
const ICON_COLORS = {
	instagram: '#DD2A7B',
	linkedin: blue[500],
	scanner: green[500],
	unassigned: grey[500],
	disabled: red[500],
	customer: red[500]
};

function ProxyTypeRenderer(props) {
	let data = props.data
	if (!data) {
		if (props.node.allLeafChildren) {
			data = {
				proxy_id:props.node.allLeafChildren[0].proxy_id,
				type:props.node.allLeafChildren[0].type
			}
			data = props.node.allLeafChildren[0].data
			let good_data = props.node.allLeafChildren.every(el => {
				return el.data.proxy_id === data.proxy_id
			});
			if (!good_data) {
				data = {}
			}
		}
	}
	let value = data.type
	if (value === undefined) {
		return <div/>
	}

	const icon = ICONS[value] || DEFAULT_ICON;
	const iconColor = ICON_COLORS[value] || DEFAULT_ICON_COLOR;
	return (
		<Tooltip title={value}>
			<Icon path={icon} size={0.75} color={iconColor} />
		</Tooltip>
	);
}

export default ProxyTypeRenderer;
