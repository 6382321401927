import React from 'react';
import { Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';
import {
	mdiCheckboxMarkedCircle,
	mdiAlertCircle,
	mdiLock,
	mdiLockAlert,
	mdiLockOpen,
	mdiHelpCircle,
	mdiCloseCircle,
	mdiLinkedin
} from '@mdi/js';
import { grey, green, orange, red, blue } from '@material-ui/core/colors';

const DEFAULT_ICON = mdiHelpCircle;
const ICONS = {
	ok: mdiCheckboxMarkedCircle,
	max_locks: mdiLock,
	max_locked: mdiLockAlert,
	released: mdiLockOpen,
	unassigned: mdiCheckboxMarkedCircle,
	disabled: mdiCloseCircle,
	error: mdiAlertCircle,
	linkedin: mdiLinkedin
};

const DEFAULT_ICON_COLOR = red[900];
const ICON_COLORS = {
	ok: green[500],
	max_locks: green[500],
	max_locked: orange[500],
	released: red[500],
	unassigned: grey[500],
	disabled: red[500],
	error: red[500],
	linkedin: blue[500]
};

function ProxyStatusRenderer(props) {
	let data = props.data
	if (!data) {
		if (props.node.allLeafChildren) {
			data = {
				proxy_id:props.node.allLeafChildren[0].proxy_id,
				status:props.node.allLeafChildren[0].status
			}
			data = props.node.allLeafChildren[0].data
			let good_data = props.node.allLeafChildren.every(el => {
				return el.data.proxy_id === data.proxy_id
			});
			if (!good_data) {
				data = {}
			}
		}
	}
	let value = data.status
	if (value === undefined) {
		return <div/>
	}


	const icon = ICONS[value] || DEFAULT_ICON;
	const iconColor = ICON_COLORS[value] || DEFAULT_ICON_COLOR;
	return (
		<Tooltip title={value}>
			<Icon path={icon} size={0.75} color={iconColor} />
		</Tooltip>
	);
}

// const ProxyTableStatusRenderer = (status, tableMeta, updateValue) => {
// 	return <StatusRenderer status={status} />;
// };

export default ProxyStatusRenderer;
