import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, List, Typography } from '@material-ui/core';
import { mdiCodeJson, mdiEmailOpen } from '@mdi/js';
import { JsonDisplay, TooltipButton } from '../../../../widgets';
import { useLiMessagingActions } from '../store';
import MessagingControls from './MessagingControls';
import PastEventDisplay from './PastEventDisplay';
import PendingEventDisplay from './PendingEventDisplay';

const useStyles = makeStyles(theme => ({
	conversationDetails: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		'& ul': {
			padding: 0
		}
	},
	headerContainer: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		backgroundColor: theme.palette.secondary.lightest,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline'
	},
	headerTitle: {
		flexGrow: 1
	},
	headerControls: {},
	messagesContainer: {
		flexGrow: 3,
		height: 0,
		overflow: 'auto'
	},
	messages: {
		height: '100%'
	},
	messageControls: {},
	pendingContainer: {
		flexGrow: 1,
		height: 0,
		overflow: 'auto'
	},
	pendingMessages: {
		height: '100%'
	},
	jsonContainer: {
		overflow: 'auto',
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'row',
		height: 0,
		width: '100%'
	},
	jsonDisplay: {
		padding: theme.spacing(2),
		overflow: 'auto',
		flexGrow: 1,
		width: 0
	},
	upcomingText: {
		fontWeight: 'bold',
		fontSize: '1rem',
		textAlign: 'center',
		padding: theme.spacing(0.5)
	}
}));

export default function ConversationDetails() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const actions = useLiMessagingActions();
	const selectedMessaging = useSelector(state => state.selectedMessaging);
	const account = useSelector(state => state.account);
	let { name, events, profiles, pending } = selectedMessaging;
	let all_profiles = [...profiles, { ...account, profile_id: account.account_id }];

	// Title
	let title = name;
	if (!name) {
		title = 'Conversation with ' + profiles[0].fullName;
		if (profiles.length > 1) {
			title = 'Conversation with ' + profiles.reduce((a, p) => [].concat(a, [p.fullName]), []).join(', ');
		}
	}

	// Message / Events
	const messagesEndRef = useRef(null);
	const scrollToBottom = () => {
		if (messagesEndRef && messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView();
		}
	};
	useEffect(scrollToBottom, [events]);

	let displayEvents = [];
	events.forEach(event => {
		let displayEvent = { ...event };
		displayEvent.profile = all_profiles.find(p => p.profile_id === displayEvent.from);
		displayEvent.fromAccount = displayEvent.from === account.account_id;
		displayEvent.displayName = displayEvent.fromAccount ? 'You' : displayEvent.profile.fullName;
		displayEvents.push(displayEvent);
	});

	// Controls
	const [showJson, setShowJson] = useState(false);
	function handleToggleJsonDisplay() {
		setShowJson(!showJson);
	}

	function handleMarkAsRead() {
		dispatch(actions.markSelectedAsRead());
	}

	function handleSubmitMessage(text) {
		dispatch(actions.sendMessage(selectedMessaging.account_profile_id, text));
	}

	function handleDeleteMessage(messageIndex) {
		console.log(messageIndex);
		dispatch(actions.deleteMessage(selectedMessaging.account_profile_id, messageIndex));
	}

	function handleToggleMessagePaused(messageIndex, paused) {
		let pause = !Boolean(paused);
		dispatch(actions.pauseMessage(selectedMessaging.account_profile_id, messageIndex, pause));
	}

	return (
		<div className={classes.conversationDetails}>
			<div className={classes.headerContainer}>
				<Typography variant="h6" component="div" className={classes.headerTitle}>
					{title}
				</Typography>
				<div>
					<TooltipButton
						tooltip="Mark as Read"
						icon={mdiEmailOpen}
						iconSize={0.75}
						onClick={handleMarkAsRead}
						disabled={selectedMessaging.read}
					/>
					<TooltipButton
						tooltip="Show Debug Data"
						icon={mdiCodeJson}
						iconSize={0.75}
						onClick={handleToggleJsonDisplay}
					/>
				</div>
			</div>
			{showJson ? (
				<div className={classes.jsonContainer}>
					<div className={classes.jsonDisplay}>
						<JsonDisplay data={selectedMessaging} />
					</div>
				</div>
			) : (
				<Fragment>
					<div className={classes.messagesContainer}>
						<List className={classes.messages}>
							{displayEvents &&
								displayEvents.map((event, i) => <PastEventDisplay key={'event-' + i} event={event} />)}
							{pending && pending.length > 0 && (
								<li>
									<Typography
										component="div"
										variant="h6"
										color="textPrimary"
										className={classes.upcomingText}
									>
										Upcoming Messages
									</Typography>
								</li>
							)}
							{pending &&
								pending.map((event, i) => (
									<PendingEventDisplay
										key={'event-' + i}
										event={event}
										onDeleteMessage={() => handleDeleteMessage(i)}
										onToggleMessagePaused={() => handleToggleMessagePaused(i, event.paused)}
									/>
								))}
							<div ref={messagesEndRef} />
						</List>
					</div>
					<div className={classes.messageControls}>
						<MessagingControls onSubmitMessage={handleSubmitMessage} />
					</div>
				</Fragment>
			)}
		</div>
	);
}
