import 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormControl, Grid, InputLabel, MenuItem, Select, Button, ButtonGroup } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useProxyEventActions, TIMEFRAMES, TIMEFRAME_DEFS } from './store';

const useStyles = makeStyles(theme => ({
	root: {},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	pickerGroup: {},
	selectContainer: {
		marginRight: theme.spacing(4)
	},
	buttonContainer: {},
	picker: {
		marginTop: 0,
		marginRight: theme.spacing(4)
	}
}));

export default function Toolbar({ onChangeReportId }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const actions = useProxyEventActions();

	const cachedStart = useSelector(state => state.events.startTime);
	const [start, setStart] = useState(cachedStart * 1000);
	useEffect(() => {
		setStart(cachedStart * 1000);
	}, [cachedStart]);
	function handleStartChange(datetime) {
		setStart(datetime.getTime());
	}

	const cachedEnd = useSelector(state => state.events.endTime);
	const [end, setEnd] = useState(cachedEnd * 1000);
	useEffect(() => {
		setEnd(cachedEnd * 1000);
	}, [cachedEnd]);
	function handleEndChange(datetime) {
		setEnd(datetime.getTime());
	}

	// Submit button
	function handleSubmit(event) {
		dispatch(actions.changeDateTimeRange(start / 1000, end / 1000));
	}

	// Report Type
	const reports = useSelector(state => state.reports.items);
	const reportId = useSelector(state => state.events.reportDef.id);
	function handleChangeReportId(event) {
		// determine new report ID
		let reportId = event.target.value;
		if (reportId === 'no-report') {
			reportId = null;
		}
		// Modify history
		let params = new URLSearchParams(history.location.search);
		if (reportId) {
			params.set('reportId', reportId);
		} else {
			params.delete('reportId');
		}
		history.push({
			pathname: history.location.pathname,
			search: '?' + params.toString()
		});
	}

	// timeframe
	const cachedTimeframe = useSelector(state => state.events.timeframe);
	function handleChangeTimeframe(timeframe) {
		dispatch(actions.changeTimeframe(timeframe));
		// Modify history
		let params = new URLSearchParams(history.location.search);
		params.set('timeframe', timeframe);
		history.push({
			pathname: history.location.pathname,
			search: '?' + params.toString()
		});
	}

	// IP Type
	const ipType = useSelector(state => state.events.ipType);
	function handleChangeIpType(event) {
		dispatch(actions.changeIpType(event.target.value));
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Grid container justify="space-between" className={classes.root}>
				<div className={classes.pickerGroup}>
					<FormControl className={classes.selectContainer}>
						<InputLabel id="report-select-label">Report</InputLabel>
						<Select
							labelId="report-select-label"
							id="report-select"
							value={reportId || 'no-report'}
							onChange={handleChangeReportId}
						>
							<MenuItem key={-1} value={'no-report'}>
								(No Report)
							</MenuItem>
							{reports.map((report, i) => (
								<MenuItem key={i} value={report.id}>
									{report.title || '(Untitled Report)'}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl className={classes.selectContainer}>
						<InputLabel id="report-type-select-label">IP Type</InputLabel>
						<Select
							labelId="report-type-select-label"
							id="ip-type-select"
							value={ipType}
							onChange={handleChangeIpType}
						>
							<MenuItem key="proxy" value="proxy">
								Proxy
							</MenuItem>
							<MenuItem key="aws" value="aws">
								AWS
							</MenuItem>
						</Select>
					</FormControl>
					<KeyboardDateTimePicker
						variant="inline"
						format="MM/dd/yyyy HH:mm"
						margin="normal"
						id="start-date-picker"
						label="Start Date"
						value={start}
						onChange={handleStartChange}
						KeyboardButtonProps={{
							'aria-label': 'change date'
						}}
						disableFuture={true}
						maxDate={end}
						className={classes.picker}
					/>
					<KeyboardDateTimePicker
						variant="inline"
						format="MM/dd/yyyy HH:mm"
						margin="normal"
						id="end-date-picker"
						label="End Date"
						value={end}
						onChange={handleEndChange}
						KeyboardButtonProps={{
							'aria-label': 'change date'
						}}
						minDate={start}
						className={classes.picker}
					/>
					<ButtonGroup size="small" variant="contained">
						{TIMEFRAMES.map((timeframe, i) => (
							<Button
								key={i}
								color={cachedTimeframe === timeframe && 'secondary'}
								onClick={() => handleChangeTimeframe(timeframe)}
							>
								{TIMEFRAME_DEFS[timeframe].label}
							</Button>
						))}
					</ButtonGroup>
				</div>
				<div className={classes.buttonContainer}>
					<Button onClick={handleSubmit} className={classes.submitButton} variant="contained" color="primary">
						Submit
					</Button>
				</div>
			</Grid>
		</MuiPickersUtilsProvider>
	);
}
