import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { createSelector } from 'reselect';
import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Paper,
	TextField
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import { JsonDisplay } from '../../../widgets';
import { useActions, store } from './store';

import { CreateFormDialog, EditFormDialog } from './forms';
import ReportGrid from './grid';
import Toolbar from './Toolbar';
import ActionsRenderer from './MoreAction';
import TitleBar from '../../../widgets/TitleBar';

// style
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column'
	},
	toolbar: {},
	contentContainer: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		height: 0,
		flexGrow: 1
	},
	chart: {
		height: 0,
		flexGrow: 3
	},
	grid: {
		height: 0,
		flexGrow: 1
	},
	spacer: {
		height: theme.spacing(2)
	}
}));

// selectors
const reportId = state => state.ui.reportDef.id;
const records = state => state.proxies.items;
const hasRecordsSelector = createSelector(
	records,
	records => {
		return records && records.length > 0;
	}
);
const reportTitle = state => state.ui.reportDef.title;
const reportModified = state => state.ui.reportModified;
const reportToLoad = state => state.ui.reportToLoad;
const reportDisplayTitleSelector = createSelector(
	reportId,
	reportTitle,
	reportModified,
	reportToLoad,
	(reportId, reportTitle, reportModified, reportToLoad) => {
		let displayTitle = '';
		if (reportToLoad) {
			displayTitle = 'Loading...';
		} else {
			displayTitle = reportId || reportModified ? reportTitle || '(Untitled Report)' : '(No Report Selected)';
			if (reportModified) displayTitle += '*';
		}
		return displayTitle;
	}
);

// components
export function WrappedReportsPage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const actions = useActions();
	const history = useHistory();
	const location = useLocation();
	const [gridApi, setGridApi] = useState();
	const [columnApi, setColumnApi] = useState();
	const [chartRef, setChartRef] = useState();
	const [cachedChartReportId, setCachedChartReportId] = useState();

	// Selectors
	const records = useSelector(state => state.proxies.items);
	const reportDef = useSelector(state => state.ui.reportDef);
	const reportDisplayTitle = useSelector(reportDisplayTitleSelector);
	const loadingData = useSelector(state => state.ui.statusFlags.loadingData);
	const applyingReportDef = useSelector(state => state.ui.statusFlags.applyingReportDef);

	// Fetch initial data
	useEffect(() => {
		dispatch(actions.reports.fetchReports());
		dispatch(actions.fetchAllRecords());
	}, []);

	// Apply Grid State
	function applyReportDef() {
		if (gridApi && columnApi && reportDef) {
			// console.log('**************** APPLYING REPORT DEF ****************');
			let chartContainerSelector = document.querySelector('#report-chart-container');
			let spacerSelector = document.querySelector('#report-chart-spacer');

			const gridState = reportDef.gridState;
			if (gridState) {
				columnApi.applyColumnState({ state: gridState.colState, applyOrder: true });
				columnApi.setColumnGroupState(gridState.groupState);
				columnApi.setPivotMode(gridState.pivotMode);
				gridApi.filterManager.setFilterModel(gridState.filterState);

				let chartDestroyed = false;
				if (chartRef && reportDef.id !== cachedChartReportId) {
					console.log('destroy chart');
					chartRef.destroyChart();
					setChartRef(null);
					chartDestroyed = true;
				}

				if (gridState['chartParams'] && records.length > 0 && (!chartRef || chartDestroyed)) {
					chartContainerSelector.style.flexGrow = 1;
					spacerSelector.style.display = 'block';
					gridState.chartParams.chartContainer = document.querySelector('#report-chart-container');

					if (gridState.pivotMode) {
						setChartRef(gridApi.createPivotChart(gridState.chartParams));
					} else {
						setChartRef(gridApi.createRangeChart(gridState.chartParams));
					}

					setCachedChartReportId(reportDef.id);
				}
				if (!gridState['chartParams']) {
					chartContainerSelector.style.flexGrow = 0;
					spacerSelector.style.display = 'none';
				}
			} else {
				columnApi.resetColumnState();
				columnApi.resetColumnGroupState();
				columnApi.setPivotMode(false);
				gridApi.filterManager.setFilterModel(null);
				chartContainerSelector.style.flexGrow = 0;
				spacerSelector.style.display = 'none';
			}
		}
	}

	// Handle changes to Grid State
	useEffect(() => {
		// Do twice to ensure column order is preserved ¯\_(ツ)_/¯
		dispatch(actions.setStatusFlag('applyingReportDef', true));
		applyReportDef();
		applyReportDef();
		setTimeout(() => {
			dispatch(actions.setStatusFlag('applyingReportDef', false));
		}, 0);
	}, [gridApi, columnApi, reportDef]);

	useEffect(() => {
		function handleGridStateChange(event) {
			if (event.source !== 'api') {
				// Save Grid State to Redux
				var colState = columnApi.getColumnState();
				var groupState = columnApi.getColumnGroupState();
				var filterState = gridApi.filterManager.getFilterModel();
				var pivotMode = columnApi.isPivotMode();
				let gridState = {
					colState: colState,
					groupState: groupState,
					filterState: filterState,
					pivotMode: pivotMode
				};
				dispatch(actions.setReportGridState(gridState));
			}
		}

		if (gridApi && columnApi) {
			gridApi.addEventListener('sortChanged', handleGridStateChange);
			gridApi.addEventListener('filterChanged', handleGridStateChange);
			gridApi.addEventListener('columnResized', handleGridStateChange);
			gridApi.addEventListener('columnPinned', handleGridStateChange);
			gridApi.addEventListener('columnMoved', handleGridStateChange);
			gridApi.addEventListener('columnVisible', handleGridStateChange);
			gridApi.addEventListener('columnValueChanged', handleGridStateChange);
			gridApi.addEventListener('columnPivotChanged', handleGridStateChange);
			gridApi.addEventListener('columnPivotModeChanged', handleGridStateChange);
			return () => {
				gridApi.removeEventListener('sortChanged', handleGridStateChange);
				gridApi.removeEventListener('filterChanged', handleGridStateChange);
				gridApi.removeEventListener('columnResized', handleGridStateChange);
				gridApi.removeEventListener('columnPinned', handleGridStateChange);
				gridApi.removeEventListener('columnMoved', handleGridStateChange);
				gridApi.removeEventListener('columnVisible', handleGridStateChange);
				gridApi.removeEventListener('columnValueChanged', handleGridStateChange);
				gridApi.removeEventListener('columnPivotChanged', handleGridStateChange);
				gridApi.removeEventListener('columnPivotModeChanged', handleGridStateChange);
			};
		}
	}, [gridApi, columnApi, loadingData, applyingReportDef]);

	// Grid State Functions
	function handleGridReady(params) {
		setGridApi(params.api);
		setColumnApi(params.columnApi);
		window.grid = params;
	}
	// function handleResetGridState(event) {
	// 	event.stopPropagation();
	// 	// TODO: reset the reportDef back to the selected report's default state
	// 	dispatch(actions.setStatusFlag('applyingReportDef', true));
	// 	applyReportDef();
	// 	setTimeout(() => {
	// 		dispatch(actions.setStatusFlag('applyingReportDef', false));
	// 	}, 0);
	// }
	function handleRefreshData(event) {
		event.stopPropagation();
		dispatch(actions.fetchAllRecords());
	}

	// Grid Data Loading Management
	const hasRecords = useSelector(hasRecordsSelector);
	const [prevLoadingData, setPrevLoadingData] = useState(loadingData);
	useEffect(() => {
		function handleDataLoadingChanged() {
			if (gridApi) {
				if (loadingData) {
					gridApi.showLoadingOverlay();
				} else if (hasRecords) {
					gridApi.hideOverlay();
				} else {
					gridApi.showNoRowsOverlay();
				}
			}
		}
		if (gridApi) {
			handleDataLoadingChanged();
			gridApi.addEventListener('rowDataUpdated', handleDataLoadingChanged);
			return () => {
				gridApi.removeEventListener('rowDataUpdated', handleDataLoadingChanged);
			};
		}
	}, [gridApi, loadingData, hasRecords]);
	useEffect(() => {
		if (gridApi && columnApi && loadingData !== prevLoadingData) {
			if (!loadingData) {
				setTimeout(() => {
					dispatch(actions.setStatusFlag('applyingReportDef', true));
					applyReportDef();
					setTimeout(() => {
						dispatch(actions.setStatusFlag('applyingReportDef', false));
					}, 0);
				}, 0);
			}
			setPrevLoadingData(loadingData);
		}
	}, [gridApi, columnApi, loadingData, prevLoadingData]);

	// Report ID Query Parameters
	useEffect(() => {
		let params = new URLSearchParams(location.search);
		let reportIdParam = params.get('reportId');
		if (reportDef.id !== reportIdParam) {
			dispatch(actions.setReportById(reportIdParam));
		}
	}, [location]);
	function setReportIdUrlParameter(reportId) {
		let params = new URLSearchParams(history.location.search);
		if (reportId) {
			params.set('reportId', reportId);
		} else {
			params.delete('reportId');
		}
		history.push({
			pathname: history.location.pathname,
			search: '?' + params.toString()
		});
	}

	// Report Title Input
	const [inputReportTitle, setInputReportTitle] = useState();
	function handleChangeInputReportTitle(event) {
		setInputReportTitle(event.target.value);
	}

	// Create New Report
	const [showNewReportTitleDialog, setShowNewReportTitleDialog] = useState(false);
	function handleStartCreateNewReport() {
		setInputReportTitle(reportDef.title || '');
		setShowNewReportTitleDialog(true);
	}
	function handleCancelCreateNewReport() {
		setInputReportTitle('');
		setShowNewReportTitleDialog(false);
	}
	function handleSubmitCreateNewReport() {
		setShowNewReportTitleDialog(false);
		let uuid = uuidv4();
		dispatch(actions.createNewReport(inputReportTitle, uuid));
		setReportIdUrlParameter(uuid);
	}

	// Create New Report
	const [showRenameReportTitleDialog, setShowRenameReportTitleDialog] = useState(false);
	function handleStartRenameReport() {
		setInputReportTitle(reportDef.title || '');
		setShowRenameReportTitleDialog(true);
	}
	function handleCancelRenameReport() {
		setInputReportTitle('');
		setShowRenameReportTitleDialog(false);
	}
	function handleSubmitRenameReport() {
		setShowRenameReportTitleDialog(false);
		dispatch(actions.changeReportTitle(inputReportTitle));
	}

	// Save, Reset, Delete Report
	function handleSaveReport() {
		dispatch(actions.saveReport());
	}
	function handleResetReport() {
		dispatch(actions.setReportById(reportDef.id));
	}
	function handleDeleteReport() {
		dispatch(actions.deleteCurrentReport());
		setReportIdUrlParameter(null);
	}

	// Create Proxy Dialog
	const creationFormVisible = useSelector(state => state.proxies.creationFormVisible);
	function handleClickCreateButton(event) {
		dispatch(actions.proxies.displayCreationFormProxy(true));
	}
	function handleCreateFormSubmit(data) {
		dispatch(actions.proxies.createProxy(data));
		dispatch(actions.proxies.displayCreationFormProxy(false));
	}
	function handleCreateFormClose() {
		dispatch(actions.proxies.displayCreationFormProxy(false));
	}

	// Edit Proxy Dialog
	const editFormVisible = useSelector(state => state.proxies.editFormVisible);
	const editFormData = useSelector(state => state.proxies.editFormData);
	function handleEditFormSubmit(data) {
		dispatch(actions.proxies.updateProxy(data));
		dispatch(actions.proxies.displayEditFormProxy({ visible: false, id: null }));
	}
	function handleEditFormClose() {
		dispatch(actions.proxies.displayEditFormProxy({ visible: false, id: null }));
	}

	// Debug
	const state = useSelector(state => state.ui);

	return (
		<div className={classes.root}>
			<TitleBar
				title={`Proxies: ${reportDisplayTitle}`}
				onClickRefreshButton={handleRefreshData}
				onClickAddButton={handleClickCreateButton}
				customButton={
					<ActionsRenderer
						onCreateNewReport={handleStartCreateNewReport}
						onSaveReport={handleSaveReport}
						onRenameReport={handleStartRenameReport}
						onResetReport={handleResetReport}
						onDeleteReport={handleDeleteReport}
					/>
				}
			>
				<Toolbar />
			</TitleBar>

			{/* Create New Report Naming Dialog */}
			<Dialog
				open={showNewReportTitleDialog}
				onClose={handleCancelCreateNewReport}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="new-report-form-dialog-title">Create New Report</DialogTitle>
				<DialogContent>
					<DialogContentText>Please provide a title for this report.</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="new-report-title"
						label="Report Title"
						onChange={handleChangeInputReportTitle}
						value={inputReportTitle}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelCreateNewReport} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSubmitCreateNewReport} color="primary">
						Submit
					</Button>
				</DialogActions>
			</Dialog>

			{/* Rename Report Dialog */}
			<Dialog
				open={showRenameReportTitleDialog}
				onClose={handleCancelRenameReport}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="rename-report-form-dialog-title">Rename Report</DialogTitle>
				<DialogContent>
					<DialogContentText>Please provide a new title for this report.</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="rename-report-title"
						label="Report Title"
						onChange={handleChangeInputReportTitle}
						value={inputReportTitle}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelRenameReport} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSubmitRenameReport} color="primary">
						Submit
					</Button>
				</DialogActions>
			</Dialog>

			{/* Proxy Create Dialogs */}
			<CreateFormDialog
				open={creationFormVisible || false}
				onSubmit={handleCreateFormSubmit}
				onClose={handleCreateFormClose}
			/>

			{/* Proxy Edit Dialogs */}
			<EditFormDialog
				open={editFormVisible || false}
				data={editFormData}
				onSubmit={handleEditFormSubmit}
				onClose={handleEditFormClose}
			/>

			{/* Report Chart & Grid */}
			<div className={classes.spacer} />
			<div className={classes.contentContainer}>
				<Paper className={classes.content} elevation={2} id="report-chart-container" />
				<div className={classes.spacer} id="report-chart-spacer" />
				<Paper elevation={2} className={classes.grid}>
					<ReportGrid onGridReady={handleGridReady} />
				</Paper>
			</div>
			{state.statusFlags.rawJsonVisible && <JsonDisplay data={state} />}
		</div>
	);
}

export default function ReportsPage(props) {
	return (
		<Provider store={store}>
			<WrappedReportsPage />
		</Provider>
	);
}
