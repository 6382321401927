import React from 'react';
import { useDispatch } from 'react-redux';
import { mdiSquareEditOutline, mdiTrashCan } from '@mdi/js';
import { ConfirmationRequiredButton, TooltipButton } from '../../../../../../widgets';
import { actions } from '../../store';

export default function ActionsRenderer({ data, ...props }) {
	const dispatch = useDispatch();

	function handleEditClicked(event) {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		dispatch(
			actions.displayEditFormCampaign({
				visible: true,
				id: data.message_campaign_id,
				message_campaign_id: data.message_campaign_id
			})
		);
	}

	function handleDeleteConfirmed() {
		dispatch(
			actions.deleteCampaign({
				id: data.message_campaign_id,
				message_campaign_id: data.message_campaign_id
			})
		);
	}

	return (
		<span>
			<TooltipButton tooltip="Edit" icon={mdiSquareEditOutline} iconSize={0.75} onClick={handleEditClicked} />
			<ConfirmationRequiredButton
				onConfirm={handleDeleteConfirmed}
				text={"Delete campaign '" + data.title + "'?"}
				tooltip="Delete"
				submitLabel="Delete"
				icon={mdiTrashCan}
			/>
		</span>
	);
}
