import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

//components
import Chart from './chart';

//helper functions
import { processData, calculateLegendNumbers } from './helpers';

//constant
import { COUNT_TYPES, TYPENAME, PLOT_COLORS } from './constants';

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	lengendContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	legend: {
		flexGrow: 1,
		margin: theme.spacing(1),
		textAlign: 'center',
		width: '100%'
	},
	display: {
		flexGrow: 1,
		width: '100%'
	}
}));

function ChartDashboard() {
	const classes = useStyles();
	const [plotData, setPlotData] = useState([]);
	const [lengendData, setLegendData] = useState([]);

	const state = useSelector(state => state);

	useEffect(() => {
		//process raw to plot data
		let processedPlotData = processData(state.records, COUNT_TYPES);
		setPlotData(processedPlotData);
		setLegendData(calculateLegendNumbers(processedPlotData, 'y', COUNT_TYPES));
	}, [Object.keys(state.records)[0], state.startDate, state.endDate]);

	return (
		<div className={classes.root}>
			<div className={classes.lengendContainer}>
				{plotData.length
					? TYPENAME.map((key, index) => {
							return (
								<Paper
									elevation={2}
									className={classes.legend}
									key={key}
									style={{
										border: `2px solid ${PLOT_COLORS[index]}`,
										color: `${PLOT_COLORS[index]}`
									}}
								>
									<Grid>{`${key}:`}</Grid>
									<Divider variant="middle" />
									<Grid>{`Sum: ${lengendData[index][0]}`}</Grid>
									<Grid>{`Average: ${lengendData[index][1]}`}</Grid>
									<Grid>{`Latest: ${
										plotData.length ? plotData[plotData.length - 1][COUNT_TYPES[index]] : 0
									}`}</Grid>
								</Paper>
							);
					  })
					: null}
			</div>
			<Paper className={classes.display}>
				<Chart plotData={plotData} />
			</Paper>
		</div>
	);
}

export default React.memo(ChartDashboard);
