import React from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import {
	AccountActionsRenderer,
	AccountEnabledRenderer,
	AccountStatusRenderer,
	RowDetailToolPanel
} from './components';
import { COLUMN_DEFS, DEFAULT_COLUMN_DEF } from './columns';

function timestampRenderer(ts) {
	if (ts) {
		return (
			<Moment unix fromNow>
				{ts}
			</Moment>
		);
	} else {
		return null;
	}
}

const SIDE_BAR_DEF = {
	toolPanels: [
		{
			id: 'columns',
			labelDefault: 'Columns',
			labelKey: 'columns',
			iconKey: 'columns',
			toolPanel: 'agColumnsToolPanel',
			width: 400
		},
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel',
			width: 400
		},
		{
			id: 'details',
			labelKey: 'details',
			labelDefault: 'Details',
			iconKey: 'columns',
			toolPanel: 'rowDetailToolPanel',
			width: 400
		}
	]
};

const STATUS_BAR_DEF = {
	statusPanels: [
		{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
		{ statusPanel: 'agTotalRowCountComponent', align: 'center' },
		{ statusPanel: 'agFilteredRowCountComponent' },
		{ statusPanel: 'agSelectedRowCountComponent' },
		{ statusPanel: 'agAggregationComponent' }
	]
};

function ReportGrid({ onGridReady }) {
	const data = useSelector(state => state.accounts.items);

	function handleGridReady(params) {
		if (onGridReady) {
			onGridReady(params);
		}
	}

	return (
		<div
			className="ag-theme-material"
			style={{
				height: '100%'
			}}
		>
			<AgGridReact
				columnDefs={COLUMN_DEFS}
				rowData={data}
				enableRangeSelection={true}
				rowSelection="multiple"
				rowDeselection={true}
				onGridReady={handleGridReady}
				groupSelectsChildren={true}
				immutableData={true}
				getRowNodeId={n => n.account_id}
				enableCharts={true}
				animateRows
				defaultColDef={DEFAULT_COLUMN_DEF}
				sideBar={SIDE_BAR_DEF}
				statusBar={STATUS_BAR_DEF}
				suppressFieldDotNotation={true}
				frameworkComponents={{
					timestampRenderer: ({ value }) => timestampRenderer(value),
					accountEnabledRenderer: AccountEnabledRenderer,
					accountStatusRenderer: AccountStatusRenderer,
					accountActionsRenderer: AccountActionsRenderer,
					rowDetailToolPanel: RowDetailToolPanel
				}}
			/>
		</div>
	);
}

export default ReportGrid;
