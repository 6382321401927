import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { mdiDotsVerticalCircleOutline, mdiConsole, mdiExport, mdiImport, mdiBackupRestore } from '@mdi/js';
import { TooltipButton } from '../../../widgets';

function ActionsRenderer({ onLogGridState, onSaveGridState, onRestoreGridState, onResetGridState }) {
	// Json Data Button
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuOpen = event => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = event => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<span>
			<TooltipButton
				tooltip="More Actions"
				icon={mdiDotsVerticalCircleOutline}
				iconSize={0.75}
				onClick={handleMenuOpen}
			/>
			<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
				<MenuItem onClick={handleMenuClose}>
					<TooltipButton
						tooltip="Log Grid State"
						icon={mdiConsole}
						iconSize={0.75}
						onClick={onLogGridState}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<TooltipButton
						tooltip="Export Grid State"
						icon={mdiExport}
						iconSize={0.75}
						onClick={onSaveGridState}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<TooltipButton
						tooltip="Import Grid State"
						icon={mdiImport}
						iconSize={0.75}
						onClick={onRestoreGridState}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<TooltipButton
						tooltip="Reset Grid State"
						icon={mdiBackupRestore}
						iconSize={0.75}
						onClick={onResetGridState}
					/>
				</MenuItem>
			</Menu>
		</span>
	);
}

export default ActionsRenderer;
