// these 3 arrays have the same order
const COUNT_TYPES = [
	'num-connections',
	'count-connection-accepted',
	'count-connection-requested',
	'count-connection-request-withdrawn',
	'count-connection-removed',
	'pending-connections'
];
const TYPENAME = ['Total', 'Accepted', 'Requested', 'Withdrawn', 'Removed', 'Pending'];
const PLOT_COLORS = ['black', 'green', 'grey', 'blue', 'red', 'orange'];
const NUMBER_OF_DISPLAY_TICKS_IN_X = 10;

export { COUNT_TYPES, TYPENAME, PLOT_COLORS, NUMBER_OF_DISPLAY_TICKS_IN_X };
