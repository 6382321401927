const COLUMN_DEFS = [
	// Displayed Columns
	{
		field: 'enabled',
		headerName: 'Enabled',
		cellRenderer: 'accountEnabledRenderer',
		pinned: 'left'
	},
	{
		field: 'status',
		headerName: 'Status',
		cellRenderer: 'accountStatusRenderer',
		filter: 'agSetColumnFilter',
		pinned: 'left'
	},
	{
		field: 'created',
		headerName: 'Created',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer'
	},
	{
		field: 'modified',
		headerName: 'Modified',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: ({ value }) => {
			if (value) {
				value = Date.now() / 1000 - value;
				let future = value < 0;
				if (future) value = -value;
				var d = Math.floor(value / 86400),
					h = ('0' + (Math.floor(value / 3600) % 24)).slice(-2),
					m = ('0' + (Math.floor(value / 60) % 60)).slice(-2),
					s = ('0' + Math.floor(value % 60)).slice(-2);
				return (
					(future ? 'in ' : '') +
					(d > 0 ? d + 'd ' : '') +
					(d > 0 || h > 0 ? h + 'h ' : '') +
					(d > 0 || h > 0 || m > 0 ? m + 'm ' : '') +
					(value !== 0 ? s + 's' : '') +
					(future ? '' : ' ago')
				);
			}
		},
		sort: 'desc'
	},
	{
		field: 'session_ts',
		headerName: 'Session',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: ({ value }) => {
			if (value) {
				value = Date.now() / 1000 - value;
				let future = value < 0;
				if (future) value = -value;
				var d = Math.floor(value / 86400),
					h = ('0' + (Math.floor(value / 3600) % 24)).slice(-2),
					m = ('0' + (Math.floor(value / 60) % 60)).slice(-2),
					s = ('0' + Math.floor(value % 60)).slice(-2);
				return (
					(future ? 'in ' : '') +
					(d > 0 ? d + 'd ' : '') +
					(d > 0 || h > 0 ? h + 'h ' : '') +
					(d > 0 || h > 0 || m > 0 ? m + 'm ' : '') +
					(value !== 0 ? s + 's' : '') +
					(future ? '' : ' ago')
				);
			}
		}
	},
	{
		field: 'session_expiration_ts',
		headerName: 'Session Expiration',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: ({ value }) => {
			if (value) {
				value = Date.now() / 1000 - value;
				let future = value < 0;
				if (future) value = -value;
				var d = Math.floor(value / 86400),
					h = ('0' + (Math.floor(value / 3600) % 24)).slice(-2),
					m = ('0' + (Math.floor(value / 60) % 60)).slice(-2),
					s = ('0' + Math.floor(value % 60)).slice(-2);
				return (
					(future ? 'in ' : '') +
					(d > 0 ? d + 'd ' : '') +
					(d > 0 || h > 0 ? h + 'h ' : '') +
					(d > 0 || h > 0 || m > 0 ? m + 'm ' : '') +
					(value !== 0 ? s + 's' : '') +
					(future ? '' : ' ago')
				);
			}
		}
	},
	{
		field: 'last_use',
		headerName: 'Last Use',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer',
		hide: true
	},
	{
		field: 'account_id',
		headerName: 'Account ID'
	},
	{
		field: 'ig_username',
		headerName: 'Username',
		filter: 'agTextColumnFilter'
	},
	{
		field: 'email',
		headerName: 'E-mail',
		filter: 'agTextColumnFilter',
		hide: true
	},
	{
		field: 'phone',
		headerName: 'Phone Number',
		filter: 'agTextColumnFilter',
		hide: true
	},
	{
		field: 'proxy_host',
		headerName: 'Proxy Host'
	},
	{
		field: 'proxy_port',
		headerName: 'Proxy Port',
		hide: true
	},
	{
		field: 'owner_id',
		headerName: 'Customer ID',
		hide: true
	},
	{
		field: 'owner',
		headerName: 'Customer',
		filter: 'agTextColumnFilter'
	},
	{
		field: 'account_id',
		headerName: 'Actions',
		filter: false,
		sort: false,
		cellRenderer: 'accountActionsRenderer',
		suppressSizeToFit: true,
		minWidth: 60,
		pinned: 'right'
	},
	{
		headerName: 'Hashtag Post Scans',
		field: 'hashtag_post_scans',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Hashtag Count Scans',
		field: 'tag_count_scans',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Place Post Scans',
		field: 'place_post_scans',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Profile Scans',
		field: 'profile_scans',
		filter: 'agNumberColumnFilter'
	}
];

const DEFAULT_COLUMN_DEF = {
	// flex: 1,
	minWidth: 100,
	// allow every column to be aggregated
	enableValue: true,
	// allow every column to be grouped
	enableRowGroup: true,
	// allow every column to be pivoted
	sortable: true,
	filter: true,
	resizable: true,
	floatingFilter: true
};

export { COLUMN_DEFS, DEFAULT_COLUMN_DEF };
