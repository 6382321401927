import { createResource } from 'redux-rest-resource';
import { PROXY_API_HOST } from '../../../../js/constants/env-settings';

const stage = process.env.REACT_APP_RUN_ENVIRONMENT;
const API_DEV = 'https://2l27ehegh6.execute-api.us-east-2.amazonaws.com/dev';
const API_PROD = 'https://bblpakjpo0.execute-api.us-east-1.amazonaws.com/dev';
const API_HOSTS = {
	local: 'http://localhost:3100',
	dev: API_DEV,
	qa: API_DEV,
	prod: API_PROD
};
export const API_HOST = API_HOSTS[stage];

const OPTIONAL_PROPERTIES = [
	'created',
	'enabled',
	'email',
	'email_password',
	'last_use',
	'modified',
	'owner',
	'owner_id',
	'phone',
	'usage_status',
	'security_code',
	'status',
	'proxy_host',
	'proxy_port',
	'proxy_password',
	'proxy_username'
];

function validate_response_data(data) {
	data.id = data.account_id;
	OPTIONAL_PROPERTIES.forEach(prop => {
		if (data[prop] === undefined) {
			data[prop] = '';
		}
	});
	if (data.cookies && data.cookies.shbts) {
		data.session_ts = parseInt(data.cookies.shbts.substring(1, 11));
	} else {
		data.session_ts = null;
	}
	if (data.session_data && data.session_data.expiration_ts) {
		data.session_expiration_ts = data.session_data.expiration_ts;
	} else {
		data.session_expiration_ts = null;
	}
	return data;
}

function validate_request_data(data) {
	delete data.id;
	OPTIONAL_PROPERTIES.forEach(prop => {
		if (!data[prop]) {
			delete data[prop];
		}
	});
	if ('phone' in data && data.phone) {
		data.phone = parseInt(data.phone);
	}
	if ('proxy_port' in data && data.proxy_port) {
		data.proxy_port = parseInt(data.proxy_port);
	}
	delete data.session_ts;
	delete data.session_expiration_ts;
	return data;
}

function account_fetch_reducer(state, action) {
	let newState = { ...state };
	switch (action.status) {
		case 'pending':
			newState.isFetching = true;
			return newState;
		case 'resolved': {
			newState.isFetching = false;

			const items = state.items.slice();
			action.body.items.forEach(item => {
				const itemIndex = items.findIndex(el => el.id === item.id);
				if (itemIndex !== -1) {
					items[itemIndex] = item;
				} else {
					items.push(item);
				}
			});
			newState.items = items;
			return newState;
		}
		case 'rejected':
			newState.isFetching = false;
			return newState;
		default:
			return state;
	}
}

function account_update_reducer(action_state_field) {
	return (state, action) => {
		let newState = { ...state };
		switch (action.status) {
			case 'pending':
				newState[action_state_field] = true;
				return newState;
			case 'resolved': {
				const id = action.context.id;
				const update = action.body;
				const listItemIndex = state.items.findIndex(el => el.id === id);
				const updatedItems = state.items.slice();
				if (listItemIndex !== -1) {
					updatedItems[listItemIndex] = {
						...updatedItems[listItemIndex],
						...update
					};
				}
				newState[action_state_field] = false;
				newState.items = updatedItems;
				return newState;
			}
			case 'rejected':
				newState[action_state_field] = false;
				return newState;
			default:
				return state;
		}
	};
}

const { types, actions, rootReducer } = createResource({
	name: 'account',
	pluralName: 'accounts',
	url: `${API_HOST}/accounts/:account_id`,
	actions: {
		fetch: {
			url: `${API_HOST}/accounts`,
			transformResponse: res => {
				for (var i in res.body.items) {
					res.body.items[i] = validate_response_data(res.body.items[i]);
				}
				return res;
			},
			reduce: account_fetch_reducer
		},
		create: {
			url: `${API_HOST}/accounts`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		update: {
			method: 'PUT',
			assignResponse: true,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		enable: {
			method: 'PATCH',
			url: `${API_HOST}/enable/:account_id`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			},
			reduce: account_update_reducer('isEnablingAccount')
		},
		reassignProxy: {
			method: 'PUT',
			url: `${PROXY_API_HOST}/assign/:account_id`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			reduce: account_update_reducer('isReassigningProxy'),
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		submitSecurityCode: {
			method: 'PATCH',
			url: `${API_HOST}/submit-code/:account_id`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			},
			reduce: account_update_reducer('isSubmittingSecurityCode')
		},
		displayCreationForm: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				creationFormVisible: action.context
			})
		},
		displayEditForm: {
			isPure: true,
			reduce: (state, action) => {
				return {
					...state,
					editFormVisible: action.context.visible,
					editFormData: state.items.find(item => item.account_id === action.context.account_id)
				};
			}
		}
	}
});

export { types, actions, rootReducer as accountReducer };
