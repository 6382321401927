import React from 'react';
import clsx from 'clsx';
import { makeStyles, AppBar, IconButton, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons/';
import { Route, Switch } from 'react-router-dom';
import poundLogoWhite from './assets/images/poundLogoWhite.png';
import { APP_MENU_DRAWER_WIDTH } from './js/constants/misc';

const useStyles = makeStyles(theme => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: APP_MENU_DRAWER_WIDTH,
			width: `calc(100% - ${APP_MENU_DRAWER_WIDTH}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		}
	},
	menuButton: {
		marginRight: 36
	},
	menuButtonOpen: {
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	logo: {
		height: '43px'
	}
}));

export default function AppHeaderBar({ open, onMenuButtonClicked }) {
	const classes = useStyles();
	// const theme = useTheme();
	// const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<AppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open
			})}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={onMenuButtonClicked}
					edge="start"
					className={clsx(classes.menuButton, {
						[classes.menuButtonOpen]: open
					})}
				>
					<MenuIcon />
				</IconButton>
				<Switch>
					<Route>
						<img className={classes.logo} src={poundLogoWhite} alt="Pound Social Logo" />
					</Route>
				</Switch>
			</Toolbar>
		</AppBar>
	);
}
