import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { JsonDisplay } from '../../../../../../widgets';

// const SUMMARY_PREVIEW_LENGTH = 40;
const LINK_COLOR = blue[700];

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1),
		margin: theme.spacing(1)
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	profilePicture: {
		height: '50px',
		width: '50px',
		borderRadius: '25px'
	},
	headerName: {
		flex: 1,
		paddingLeft: theme.spacing(1)
	},
	headerLink: {
		color: LINK_COLOR,
		fontWeight: 'bold'
	},
	contactLink: {
		color: LINK_COLOR
	},
	fieldTitle: {
		fontWeight: 'bold'
	},
	textLinkButton: {
		fontWeight: 'bold',
		color: LINK_COLOR
	},
	interactionDate: {
		fontWeight: 'bold'
	}
	// textField: {
	// 	gridColumn: 'span 5',
	// 	marginLeft: theme.spacing(1),
	// 	marginRight: theme.spacing(1),
	// 	width: '25ch',
	// 	marginTop: theme.spacing(2),
	// 	marginBottom: theme.spacing(1)
	// },
	// buttonContainer: {
	// 	gridColumn: 'span 2',
	// 	marginTop: theme.spacing(3),
	// 	marginBottom: theme.spacing(2)
	// }
}));

// function FieldTitle({ title, className, variant = 'subtitle2', component = 'div', onClick }) {
// 	const classes = useStyles();
// 	return (
// 		<Typography variant={variant} component={component} className={classes.fieldTitle} onClick={onClick}>
// 			{title}
// 		</Typography>
// 	);
// }

// function FieldDisplay({ title, value, expandable = false, children, defaultExpanded = false }) {
// 	const classes = useStyles();

// 	let undefinedValue = typeof value == 'undefined';
// 	let undefinedChildren = typeof children == 'undefined';

// 	if (undefinedValue && undefinedChildren) {
// 		return '';
// 	}

// 	const [expanded, setExpanded] = useState(defaultExpanded);
// 	function toggleExpand() {
// 		setExpanded(!expanded);
// 	}

// 	let expand = !expandable || (expandable && expanded);

// 	return (
// 		<div onClick={toggleExpand}>
// 			<Typography variant="subtitle2" component="span" className={classes.fieldTitle}>
// 				{title}
// 				{!expand ? '...' : ': '}
// 			</Typography>
// 			{!undefinedValue && (
// 				<Typography variant="body2" component="span">
// 					{String(value)}
// 				</Typography>
// 			)}
// 			{!undefinedChildren && expand && children}
// 		</div>
// 	);
// }

export default function RowDetailRenderer({ node, expand = false }) {
	const classes = useStyles();

	// if (!expand) {
	return (
		<Paper className={classes.root}>
			<JsonDisplay data={node.data} />
			{/* {Object.keys(node.data).map((key, i) => (
				<FieldDisplay key={i} title={key} value={node.data[key]} />
			))} */}
		</Paper>
	);
}
