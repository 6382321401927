import React from 'react';
import { Field } from 'react-final-form';
import { Checkbox, TextField } from 'final-form-material-ui';
import { validators } from '../../../../widgets';

export function EnabledField(props) {
	return (
		<>
			<Field name="enabled" component={Checkbox} type="checkbox" />
			<label>Enabled</label>
		</>
	);
}

export function AccountIDField(props) {
	return (
		<Field
			name="account_id"
			component={TextField}
			type="number"
			label="IG User ID"
			validate={validators.all(validators.required, validators.isNumber)}
			fullWidth
			required
			{...props}
		/>
	);
}

export function InstagramUsernameField(props) {
	return (
		<Field
			name="ig_username"
			component={TextField}
			type="text"
			label="IG Username"
			validate={validators.required}
			fullWidth
			required
			{...props}
		/>
	);
}

export function InstagramPasswordField(props) {
	return (
		<Field
			name="ig_password"
			component={TextField}
			type="input"
			label="IG Password"
			validate={validators.required}
			fullWidth
			required
			{...props}
		/>
	);
}

export function EmailField(props) {
	return <Field name="email" component={TextField} type="text" label="Email" fullWidth {...props} />;
}

export function EmailPasswordField(props) {
	return (
		<Field name="email_password" component={TextField} type="input" label="Email Password" fullWidth {...props} />
	);
}

export function PhoneField(props) {
	return (
		<Field
			name="phone"
			component={TextField}
			type="number"
			label="Phone Number"
			fullWidth
			validate={validators.isNumber}
			{...props}
		/>
	);
}

export function CustomerIDField(props) {
	return <Field name="owner_id" component={TextField} type="text" label="Customer ID" fullWidth {...props} />;
}

export function CustomerField(props) {
	return <Field name="owner" component={TextField} type="text" label="Customer" fullWidth {...props} />;
}

export function ProxyHostField(props) {
	return <Field name="proxy_host" component={TextField} type="text" label="Host" fullWidth {...props} />;
}

export function ProxyPortField(props) {
	return (
		<Field
			name="proxy_port"
			component={TextField}
			type="number"
			label="Port"
			validate={validators.portNumber}
			fullWidth
			{...props}
		/>
	);
}

export function ProxyUsernameField(props) {
	return <Field name="proxy_username" component={TextField} type="text" label="Username" fullWidth {...props} />;
}

export function ProxyPasswordField(props) {
	return <Field name="proxy_password" component={TextField} type="input" label="Password" fullWidth {...props} />;
}
