import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { API_HOST as ACCOUNTS_API_HOST } from '../reports/store';

//region Constants

const stage = process.env.REACT_APP_RUN_ENVIRONMENT;
const API_DEV = 'https://5eq0xi2ls0.execute-api.us-east-2.amazonaws.com/dev';
const API_PROD = 'https://hgbq7xhzp1.execute-api.us-east-1.amazonaws.com/dev';
const API_HOSTS = {
	local: 'http://localhost:3203',
	dev: API_DEV,
	qa: API_PROD,
	prod: API_PROD
};
export const API_HOST = API_HOSTS[stage];

const INITIAL_STATE = {
	accountId: null,
	statusFlags: {},
	selectedMessagingID: null,
	selectedMessaging: null,
	messagingList: [],
	account: {}
};

//endregion Constants

//region Action Types

const ACTION_TYPE_PREFIX = 'LI:MESSAGING:';

const SET_STATUS_FLAG = ACTION_TYPE_PREFIX + 'SET_STATUS_FLAG';
const SAVE_ACCOUNT_ID = ACTION_TYPE_PREFIX + 'SAVE_ACCOUNT_ID';
const SAVE_ACCOUNT_DATA = ACTION_TYPE_PREFIX + 'SAVE_ACCOUNT_DATA';
const SAVE_MESSAGING_LIST = ACTION_TYPE_PREFIX + 'SAVE_MESSAGING_LIST';
const SAVE_SELECTED_MESSAGING_ID = ACTION_TYPE_PREFIX + 'SET_SELECTED_MESSAGING_ID';
const SAVE_SELECTED_MESSAGING_DATA = ACTION_TYPE_PREFIX + 'SET_SELECTED_MESSAGING_DATA';
const CLEAR_MESSAGING_DATA = ACTION_TYPE_PREFIX + 'CLEAR_MESSAGING_DATA';

//endregion Action Types

function cleanConversationData(convo) {
	if (convo.events) {
		convo.events.forEach((event, i) => {
			if (event.profile) {
				delete event.profile;
			}
			if (event.fromAccount) {
				delete event.fromAccount;
			}
			if (event.displayName) {
				delete event.displayName;
			}
		});
	}
}

/** Sets the value of a status flag. */
function setStatusFlag(flag, value) {
	return {
		type: SET_STATUS_FLAG,
		flag: flag,
		value: value
	};
}

function initializeWithAccountID(accountId) {
	return (dispatch, getState) => {
		// Set Account ID
		let cachedAccountId = getState().accountId;
		if (cachedAccountId === accountId) return;

		dispatch(clearMessagingData());
		dispatch(setAccountId(accountId));

		// Fetch records
		dispatch(fetchMessagingList());
	};
}

function clearMessagingData() {
	return {
		type: CLEAR_MESSAGING_DATA
	};
}

/** Sets the new account ID and triggers data load */
function setAccountId(accountId) {
	return (dispatch, getState) => {
		let cachedAccountId = { ...getState().accountId };
		if (cachedAccountId === accountId) return;
		dispatch(saveAccountId(accountId));
		dispatch(fetchAccountInfo());
	};
}

/** Triggers the account ID to be saved in the reducer. */
function saveAccountId(accountId) {
	return {
		type: SAVE_ACCOUNT_ID,
		accountId: accountId
	};
}

/** Fetches account data from the server. */
function fetchAccountInfo() {
	return (dispatch, getState) => {
		let { accountId } = { ...getState() };
		const url = ACCOUNTS_API_HOST + `/accounts/${accountId}`;

		dispatch(setStatusFlag('fetchingAccountData', true));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				dispatch(saveAccountData(data));
				dispatch(setStatusFlag('fetchingAccountData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingAccountData', 'error'));
			});
	};
}

/** Triggers the account Data to be saved in the reducer. */
function saveAccountData(accountData) {
	return {
		type: SAVE_ACCOUNT_DATA,
		accountData: accountData
	};
}

/** Fetches account data from the server. */
function fetchMessagingList() {
	return (dispatch, getState) => {
		let accountId = getState().accountId;
		const url = API_HOST + `/messaging?account_id=` + accountId;

		dispatch(setStatusFlag('fetchingMessagingList', true));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				dispatch(saveMessagingList(data));
				dispatch(setStatusFlag('fetchingMessagingList', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingMessagingList', 'error'));
			});
	};
}

/** Triggers the account Data to be saved in the reducer. */
function saveMessagingList(data) {
	return {
		type: SAVE_MESSAGING_LIST,
		messagingList: data
	};
}

function saveSelectedMessagingId(account_profile_id) {
	return {
		type: SAVE_SELECTED_MESSAGING_ID,
		selectedMessagingID: account_profile_id
	};
}

function saveSelectedMessagingData(data) {
	return {
		type: SAVE_SELECTED_MESSAGING_DATA,
		data: data
	};
}

function fetchMessagingData(account_profile_id) {
	return dispatch => {
		const url = API_HOST + `/messaging/` + account_profile_id;

		dispatch(setStatusFlag('fetchingMessagingData', true));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				dispatch(saveSelectedMessagingData(data));
				dispatch(setStatusFlag('fetchingMessagingData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingMessagingData', 'error'));
			});
	};
}

function setSelectedMessagingId(account_profile_id) {
	return dispatch => {
		dispatch(saveSelectedMessagingId(account_profile_id));
		dispatch(fetchMessagingData(account_profile_id));
	};
}

function sendMessage(account_profile_id, messageText) {
	return (dispatch, getState) => {
		dispatch(setStatusFlag('postingMessageData', true));

		let postData = getState().selectedMessaging;
		if (!postData.hasOwnProperty('pending')) {
			postData['pending'] = [];
		}
		postData['pending'].unshift({
			paused: false,
			message: messageText,
			send_ts: Math.floor(Date.now() / 1000)
		});
		postData['pending'].sort((a, b) => a.send_ts - b.send_ts);
		postData['read'] = true;
		postData['unreadCount'] = 0;

		// Assume it worked; will be updated from the server momentarily
		cleanConversationData(postData);
		dispatch(saveSelectedMessagingData(postData));

		const url = API_HOST + `/messaging/` + account_profile_id;
		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(postData)
		})
			.then(res => res.json())
			.then(data => {
				dispatch(saveSelectedMessagingData(data));
				dispatch(setStatusFlag('postingMessageData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingMessagingData', 'error'));
			});
	};
}

function deleteMessage(account_profile_id, messageIndex) {
	return (dispatch, getState) => {
		dispatch(setStatusFlag('postingMessageData', true));

		let postData = getState().selectedMessaging;
		postData.pending.splice(messageIndex, 1);

		// Assume it worked; will be updated from the server momentarily
		cleanConversationData(postData);
		dispatch(saveSelectedMessagingData(postData));

		const url = API_HOST + `/messaging/` + account_profile_id;
		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(postData)
		})
			.then(res => res.json())
			.then(data => {
				dispatch(saveSelectedMessagingData(data));
				dispatch(setStatusFlag('postingMessageData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingMessagingData', 'error'));
			});
	};
}

function pauseMessage(account_profile_id, messageIndex, pause) {
	return (dispatch, getState) => {
		dispatch(setStatusFlag('postingMessageData', true));

		let postData = getState().selectedMessaging;
		postData.pending[messageIndex].paused = pause;

		// Assume it worked; will be updated from the server momentarily
		cleanConversationData(postData);
		dispatch(saveSelectedMessagingData(postData));

		const url = API_HOST + `/messaging/` + account_profile_id;
		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(postData)
		})
			.then(res => res.json())
			.then(data => {
				dispatch(saveSelectedMessagingData(data));
				dispatch(setStatusFlag('postingMessageData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingMessagingData', 'error'));
			});
	};
}

function markSelectedAsRead() {
	return (dispatch, getState) => {
		dispatch(setStatusFlag('postingMessageData', true));

		let postData = getState().selectedMessaging;
		let account_profile_id = postData.account_profile_id;
		postData['unreadCount'] = 0;
		postData['read'] = true;
		postData['read_status'] = 'mark-read';
		if (postData.events) {
			postData.events.forEach(message => {
				message['unread'] = false;
			});
		}

		// Assume it worked; will be updated from the server momentarily
		cleanConversationData(postData);
		dispatch(saveSelectedMessagingData(postData));

		const url = API_HOST + `/messaging/` + account_profile_id;
		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(postData)
		})
			.then(res => res.json())
			.then(data => {
				dispatch(saveSelectedMessagingData(data));
				dispatch(setStatusFlag('postingMessageData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingMessagingData', 'error'));
			});
	};
}

function refreshData() {
	return (dispatch, getState) => {
		dispatch(fetchMessagingList());
		let selectedMessagingID = getState().selectedMessagingID;
		if (selectedMessagingID) {
			dispatch(setSelectedMessagingId(selectedMessagingID));
		}
	};
}

//region Actions

// Action Dispatching...
function useLiMessagingActions() {
	return {
		setStatusFlag: setStatusFlag,
		initializeWithAccountID: initializeWithAccountID,
		fetchMessagingList: fetchMessagingList,
		setSelectedMessagingId: setSelectedMessagingId,
		sendMessage: sendMessage,
		deleteMessage: deleteMessage,
		pauseMessage: pauseMessage,
		markSelectedAsRead: markSelectedAsRead,
		refreshData: refreshData
	};
}

//endregion Actions

//region Reducer

function reducer(state = INITIAL_STATE, action) {
	if (action.type.startsWith(ACTION_TYPE_PREFIX)) {
		let newState = { ...state };
		switch (action.type) {
			case SET_STATUS_FLAG:
				newState.statusFlags[action.flag] = action.value;
				break;
			case CLEAR_MESSAGING_DATA:
				newState.accountId = null;
				newState.statusFlags = {};
				newState.selectedMessagingID = null;
				newState.selectedMessaging = null;
				newState.messagingList = [];
				newState.account = {};
				break;
			case SAVE_ACCOUNT_ID:
				newState.accountId = action.accountId;
				break;
			case SAVE_ACCOUNT_DATA:
				newState.account = action.accountData;
				break;
			case SAVE_MESSAGING_LIST:
				newState.messagingList = action.messagingList;
				break;
			case SAVE_SELECTED_MESSAGING_ID:
				newState.selectedMessagingID = action.selectedMessagingID;
				break;
			case SAVE_SELECTED_MESSAGING_DATA:
				newState.selectedMessaging = action.data;

				const index = state.messagingList.findIndex(
					el => el.account_profile_id === action.data.account_profile_id
				);
				const updatedItems = state.messagingList.slice();
				if (index !== -1) {
					updatedItems[index] = {
						...updatedItems[index],
						...action.data
					};
				}
				newState.messagingList = updatedItems;

				break;
			default:
				console.log('LI DASH Event Type not handled!', action);
		}
		return newState;
	}

	return state;
}

//endregion Reducer

//region Export

const store = createStore(reducer, applyMiddleware(thunk));
export { reducer, store, useLiMessagingActions };

//endregion Export
