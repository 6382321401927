import React from 'react';
import {PropTypes} from 'prop-types';
import Button from '@material-ui/core/Button/index';
import CssBaseline from '@material-ui/core/CssBaseline/index';
import Paper from '@material-ui/core/Paper/index';
import Typography from '@material-ui/core/Typography/index';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Auth} from 'aws-amplify';
import * as baseActions from "../../js/actions";

class Logout extends React.Component {

    render() {
        const { classes } = this.props;

        const processLogout = () => {
            Auth.signOut({ global: true })
                .then(data => console.log(data))
                .catch(err => console.log(err));
        }

        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Typography>
                        Are you sure you want to logout?
                    </Typography>
                    <Link to={"/"} style={{textDecoration: 'none', width: '100%'}}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                this.props.resetDefault();
                                processLogout()
                            }}
                        >
                            logout
                        </Button>
                    </Link>
                    <Link to={"/dashboard"} style={{textDecoration: 'none', width: '100%'}}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.button}

                        >
                            cancel
                        </Button>
                    </Link>
                </Paper>
            </main>
        )
    };
}

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit,
    },
});

Logout.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        appData: state.appData
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => dispatch(baseActions.logoutUser())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Logout));