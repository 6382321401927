import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import initReducer from './initialize';
// import { rootReducer as proxiesReducer } from '../../pages/proxies/store';
import * as actionTypes from '../constants/action-types';

const initialState = {
	waitStateOn: false,
	authenticated: false,
	getDataError: false,
	dataRetrievalError: {},
	chargeBeeConnect: {},
	dashList: [],
	showDashDetail: '0',
	dashDetail: {},
	campaignList: [],
	campaignFormState: 'list',
	campaignFormEdit: {},
	userData: {},
	intercomObject: {
		user_id: '',
		email: '',
		name: ''
	},
	campaignLoading: false,
	maxima: {},

	//Taskbar value
	taskbar: '',
	showCreateTopicDialog: false,
	currentHashtaggerValue: 0
};

function appReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.TOGGLE_WAIT:
			return {
				...state,
				waitStateOn: !state.waitStateOn
			};
		case actionTypes.FETCH_PORTAL_DATA:
			return {
				...state,
				waitStateOn: !state.waitStateOn
			};
		case actionTypes.RECEIVED_PORTAL_DATA: {
			return {
				...state,
				chargeBeeConnect: action.portalData.return,
				hasActiveSubscription: action.portalData.return.linked_customers[0].hasActiveSubscription
			};
		}
		case actionTypes.DATA_RETRIEVAL_ERROR: {
			return {
				...state,
				getDataError: true,
				dataRetrievalError: action.errorData
			};
		}
		case actionTypes.UPDATE_CAMPAIGN_LIST: {
			return {
				...state,
				campaignLoading: false,
				campaignList: action.campaignList,
				campaignFormState: 'list',
				campaignFormEdit: state.campaignFormInit
			};
		}
		case actionTypes.EDIT_CAMPAIGN: {
			return {
				...state,
				campaignFormEdit: action.campaignData,
				campaignFormState: 'edit'
			};
		}
		case actionTypes.NEW_CAMPAIGN: {
			return {
				...state,
				campaignFormEdit: state.campaignFormInit,
				campaignFormState: 'edit'
			};
		}
		case actionTypes.CANCEL_CAMPAIGN: {
			return {
				...state,
				campaignFormEdit: state.campaignFormInit,
				campaignFormState: 'list'
			};
		}
		case actionTypes.DASH_DETAIL: {
			return {
				...state,
				showDashDetail: action.accountID
			};
		}
		case actionTypes.UPDATE_DASH_DETAILS: {
			let dashboardDetails = {
				...state.dashDetail
			};
			let maximaDetails = {
				...state.maxima
			};
			dashboardDetails[action.accountID] = action.accountDetails.sort((a, b) =>
				a.logged_at > b.logged_at ? 1 : -1
			);
			let values = ['total_posts', 'followers', 'following'];
			let tempMaximaArray = {};
			values.forEach(v => {
				let tempArray = dashboardDetails[action.accountID].map(data => data[v]);
				tempMaximaArray[v] = Math.max(...tempArray);
			});
			maximaDetails[action.accountID] = {
				follow: Math.max(tempMaximaArray.followers, tempMaximaArray.following),
				total_posts: tempMaximaArray.total_posts
			};
			return {
				...state,
				dashDetail: dashboardDetails,
				maxima: maximaDetails
			};
		}
		case actionTypes.SET_USER_ACCESS: {
			return {
				...state,
				authenticated: true,
				userData: action.userObj
			};
		}
		case actionTypes.LOG_OUT_USER: {
			return {
				...state,
				authenticated: false,
				userData: {}
			};
		}
		case actionTypes.LOADING_CAMPAIGN: {
			return {
				...state,
				campaignLoading: true
			};
		}

		//Taskbar handler
		case actionTypes.TASKBAR: {
			return {
				...state,
				taskbar: action.landingPage
			};
		}
		case actionTypes.NEW_TOPIC_MODAL: {
			return {
				...state,
				showCreateTopicDialog: action.open
			};
		}
		case actionTypes.HASTAGGER_VALUE_HANDLER: {
			return {
				...state,
				currentHashtaggerValue: action.value
			};
		}

		default:
			return state;
	}
}

const rootReducer = combineReducers({
	appData: appReducer,
	formInit: initReducer,
	form: formReducer,
	// proxies: proxiesReducer
});

export default rootReducer;
