import React from 'react';
import { Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiHelpCircle, mdiInstagram, mdiLinkedin } from '@mdi/js';
import { blue, grey, red } from '@material-ui/core/colors';

const DEFAULT_ICON = mdiHelpCircle;
const ICONS = {
	ig: mdiInstagram,
	li: mdiLinkedin,
	unassigned: mdiHelpCircle,
};

const DEFAULT_ICON_COLOR = red[900];
const ICON_COLORS = {
	ig: '#DD2A7B',
	li: blue[500],
	unassigned: grey[500],
};

function ProxyLockTypeRenderer(props) {
	let data = props.data
	if (!data) {
		data = {}
	}
	let value = data.account_type
	if (value === undefined) {
		return <div/>
	}

	const icon = ICONS[value] || DEFAULT_ICON;
	const iconColor = ICON_COLORS[value] || DEFAULT_ICON_COLOR;
	return (
		<Tooltip title={value}>
			<Icon path={icon} size={0.75} color={iconColor} />
		</Tooltip>
	);
}

export default ProxyLockTypeRenderer;
