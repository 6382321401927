import React from 'react';
import { Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import { MenuItem } from '@material-ui/core';
import { validators } from '../../../../widgets';

export function HostField(props) {
	return (
		<Field
			name="host"
			component={TextField}
			type="text"
			label="Host"
			validate={validators.required}
			fullWidth
			required
			{...props}
		/>
	);
}

export function PortField(props) {
	return (
		<Field
			name="port"
			component={TextField}
			type="number"
			label="Port"
			validate={validators.all(validators.required, validators.portNumber)}
			fullWidth
			required
			{...props}
		/>
	);
}

export function UsernameField(props) {
	return (
		<Field
			name="username"
			component={TextField}
			type="text"
			label="Username"
			validate={validators.required}
			fullWidth
			required
			{...props}
		/>
	);
}

export function PasswordField(props) {
	return (
		<Field
			name="password"
			component={TextField}
			type="password"
			label="Password"
			validate={validators.required}
			fullWidth
			required
			{...props}
		/>
	);
}

export function LocationField(props) {
	return <Field name="location" component={TextField} type="text" label="Location" fullWidth {...props} />;
}

export function SourceField(props) {
	return <Field name="source" component={TextField} type="text" label="Proxy Source" fullWidth {...props} />;
}

export function TypeField(props) {
	return (
		<Field
			fullWidth
			name="type"
			component={Select}
			label="Usage Type"
			formControlProps={{ fullWidth: true }}
			{...props}
		>
			<MenuItem value="unassigned">Unassigned</MenuItem>
			<MenuItem value="linkedin">Linked In</MenuItem>
			<MenuItem value="instagram">Instagram</MenuItem>
			<MenuItem value="scanner">Scanner</MenuItem>
			<MenuItem value="disabled">Disabled</MenuItem>
		</Field>
	);
}

export function NotesField(props) {
	return <Field fullWidth name="notes" component={TextField} label="Notes" multiline="true" {...props} />;
}
