import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { ConfirmationDialog, useConfirmation } from '../../../../../../widgets';
import { actions } from '../../store';

const EnabledSwitch = withStyles({
	switchBase: {
		color: red[200],
		'&$checked': {
			color: green[500]
		}
	},
	checked: {}
})(Switch);

function EnableToggleRenderer({ value, setValue, data, ...props }) {
	const dispatch = useDispatch();
	function handleConfirm() {
		let enabled = !value;
		setValue(enabled);
		dispatch(
			actions.patchCampaign({
				id: data.message_campaign_id,
				message_campaign_id: data.message_campaign_id,
				account_id: data.account_id,
				enabled: enabled
			})
		);
	}

	const [open, onClick, onClose, onConfirmWrap] = useConfirmation(handleConfirm);
	const confirmLabel = value ? 'Disable' : 'Enable';
	const text = confirmLabel + " Campaign '" + data.message_campaign_id + "'?";

	return (
		<Fragment>
			<ConfirmationDialog
				text={text}
				open={open}
				onClose={onClose}
				onConfirm={onConfirmWrap}
				confirmLabel={confirmLabel}
			/>
			<EnabledSwitch checked={value} onClick={onClick} color="default" />
		</Fragment>
	);
}

export default EnableToggleRenderer;
