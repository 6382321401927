import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-enterprise';

import frameworkComponents from './components';

const DEFAULT_COLUMN_DEF = {
	minWidth: 100,
	// allow every column to be aggregated
	enableValue: true,
	// allow every column to be grouped
	enableRowGroup: true,
	// allow every column to be pivoted
	enablePivot: true,
	sortable: true,
	filter: true,
	resizable: true,
	floatingFilter: true
};

const COLUMN_DEFS = [
	// Displayed Columns
	{
		field: 'enabled',
		headerName: 'Enabled',
		cellRenderer: 'enableToggleRenderer',
		pinned: 'left'
	},
	{
		field: 'connection_campaign_id',
		headerName: 'Campaign ID',
		hidden: true,
		filter: 'agTextColumnFilter'
	},
	{
		field: 'title',
		headerName: 'Title',
		filter: 'agTextColumnFilter'
	},
	{
		field: 'daily_connection_count',
		headerName: 'Daily Connection Count',
		filter: 'agNumberColumnFilter'
	},
	{
		field: 'message_campaign_id',
		headerName: 'Message Campaign',
		filter: true,
		cellRenderer: 'messageCampaignRenderer'
	},
	{
		field: 'saved_search',
		headerName: 'Saved Search',
		filter: false
	},
	{
		field: 'connection_message_enabled',
		headerName: 'Use Connection Message',
		filter: false
	},
	{
		field: 'connection_message',
		headerName: 'Connection Message',
		filter: false
	},
	{
		field: 'modified',
		headerName: 'Modified',
		filter: false,
		cellRenderer: 'timestampRenderer',
		sort: 'desc'
	},
	{
		field: 'created',
		headerName: 'Created',
		filter: false,
		cellRenderer: 'timestampRenderer'
	},
	{
		headerName: 'Actions',
		filter: false,
		sort: false,
		cellRenderer: 'actionsRenderer',
		suppressSizeToFit: true,
		pinned: 'right'
	}
];

const SIDE_BAR_DEF = {
	toolPanels: ['columns', 'filters']
};

function CampaignGrid({ data }) {
	// const classes = useStyles();

	// Grid API
	// const [gridColumnApi, setGridColumnApi] = useState(null);
	// const onGridReady = params => {
	// 	setGridColumnApi(params.columnApi);
	// };

	return (
		<div
			className="ag-theme-material"
			style={{
				height: '100%',
				widht: '100%'
			}}
		>
			<AgGridReact
				// onGridReady={onGridReady}
				rowData={data}
				getRowNodeId={data => data.connection_campaign_id}
				immutableData={true}
				columnDefs={COLUMN_DEFS}
				defaultColDef={DEFAULT_COLUMN_DEF}
				animateRows
				enableRangeSelection={true}
				rowSelection="multiple"
				rowDeselection={true}
				groupSelectsChildren={true}
				sideBar={SIDE_BAR_DEF}
				// statusBar={STATUS_BAR_DEF}
				frameworkComponents={frameworkComponents}
			/>
		</div>
	);
}

export default CampaignGrid; //React.memo(CampaignGrid);
