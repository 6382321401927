import 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useLiDashboardActions } from '../dashboard/store';
import { getFormattedDate, dateIsValid } from '../dashboard/utils';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%'
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
		marginBottom: theme.spacing(1)
	},
	submitButton: {
		marginLeft: theme.spacing(4)
	},
	picker: {
		marginTop: 0,
		marginRight: theme.spacing(4)
	},
	pickerGroup: {},
	buttonGroup: {
		paddingRight: theme.spacing(2)
	},
	content: {}
}));

export default function MaterialUIPickers({ account, setLineDisplay }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const dashboardActions = useLiDashboardActions();
	const [modified, setModified] = useState(false);

	const startDateStr = useSelector(state => state.startDate);
	const [start, setStartDate] = useState(new Date(startDateStr));
	function handleStartChange(date) {
		setStartDate(date);
		setModified(true);
	}

	const endDateStr = useSelector(state => state.endDate);
	const [end, setEndDate] = useState(new Date(endDateStr));
	function handleEndChange(date) {
		setEndDate(date);
		setModified(true);
	}

	function handleSubmit(event) {
		setModified(false);
		dispatch(dashboardActions.setDateRange(start, end));
	}

	let disableSubmit =
		!dateIsValid(start) || !dateIsValid(end) || !modified || getFormattedDate(start) > getFormattedDate(end);

	return (
		<div className={classes.root}>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid container justify="space-between" classes={{ marginNormal: { marginTop: 0 } }}>
					<div className={classes.pickerGroup}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="start-date-picker"
							label="Start Date"
							value={start}
							onChange={handleStartChange}
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
							disableFuture={true}
							maxDate={end}
							className={classes.picker}
						/>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="end-date-picker"
							label="End Date"
							value={end}
							onChange={handleEndChange}
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
							disableFuture={true}
							minDate={start}
							className={classes.picker}
						/>
					</div>
					<div className={classes.ButtonGroup}>
						<Button
							onClick={handleSubmit}
							className={classes.submitButton}
							variant="contained"
							color="primary"
							disabled={disableSubmit}
						>
							Submit
						</Button>
					</div>
				</Grid>
			</MuiPickersUtilsProvider>
		</div>
	);
}
