import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createResource } from 'redux-rest-resource';

const stage = process.env.REACT_APP_RUN_ENVIRONMENT;
const API_DEV = 'https://t7qogvmfnd.execute-api.us-east-2.amazonaws.com/dev';
const API_PROD = 'https://wwcldhp8be.execute-api.us-east-1.amazonaws.com/dev';
const API_HOSTS = {
	local: 'http://localhost:3202',
	dev: API_DEV,
	qa: API_PROD,
	prod: API_PROD
};
export const API_HOST = API_HOSTS[stage];

const OPTIONAL_PROPERTIES = [];

function validate_response_data(data) {
	data.id = data.connection_campaign_id;
	OPTIONAL_PROPERTIES.forEach(prop => {
		if (data[prop] === undefined) {
			data[prop] = '';
		}
	});
	return data;
}

function validate_request_data(data) {
	delete data.id;
	OPTIONAL_PROPERTIES.forEach(prop => {
		if (!data[prop]) {
			delete data[prop];
		}
	});
	return data;
}

function campaign_update_reducer(action_state_field) {
	return (state, action) => {
		let newState = { ...state };
		switch (action.status) {
			case 'pending':
				newState[action_state_field] = true;
				return newState;
			case 'resolved': {
				const id = action.context.id;
				const update = action.body;
				const listItemIndex = state.items.findIndex(el => el.id === id);
				const updatedItems = state.items.slice();
				if (listItemIndex !== -1) {
					updatedItems[listItemIndex] = {
						...updatedItems[listItemIndex],
						...update
					};
				}
				newState[action_state_field] = false;
				newState.items = updatedItems;
				return newState;
			}
			case 'rejected':
				newState[action_state_field] = false;
				return newState;
			default:
				return state;
		}
	};
}

function message_campaign_reducer() {
	return (state, action) => {
		let newState = { ...state };
		switch (action.status) {
			case 'pending':
				newState['isFetchingMessageCampaigns'] = true;
				return newState;
			case 'resolved': {
				newState['isFetchingMessageCampaigns'] = false;
				newState.message_campaigns = action.body;
				return newState;
			}
			case 'rejected':
				newState['isFetchingMessageCampaigns'] = false;
				return newState;
			default:
				return state;
		}
	};
}

const { types, actions, rootReducer } = createResource({
	name: 'campaign',
	pluralName: 'campaigns',
	url: `${API_HOST}/connection-campaigns/:connection_campaign_id`,
	actions: {
		fetch: {
			transformResponse: res => {
				for (var i in res.body) {
					res.body[i] = validate_response_data(res.body[i]);
				}
				return res;
			},
			query: (getState, { actionId, context, contextOpts }) => {
				const state = getState();
				return { account_id: state.accountId };
			}
		},
		fetchMessage: {
			url: `${API_HOST}/message-campaigns`,
			// transformResponse: res => {
			// 	for (var i in res.body) {
			// 		res.body[i] = validate_response_data(res.body[i]);
			// 	}
			// 	return res;
			// },
			query: (getState, { actionId, context, contextOpts }) => {
				const state = getState();
				return { account_id: state.accountId };
			},
			isArray: true,
			reduce: message_campaign_reducer()
		},
		create: {
			url: `${API_HOST}/connection-campaigns`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				const state = getState();
				return validate_request_data({
					...context,
					account_id: state.accountId
				});
			}
		},
		update: {
			method: 'PUT',
			assignResponse: true,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		patch: {
			method: 'PATCH',
			url: `${API_HOST}/connection-campaigns`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			},
			reduce: campaign_update_reducer('isPatchingCampaign')
		},
		displayCreationForm: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				creationFormVisible: action.context
			})
		},
		displayEditForm: {
			isPure: true,
			reduce: (state, action) => {
				return {
					...state,
					editFormVisible: action.context.visible,
					editFormData: state.items.find(
						item => item.connection_campaign_id === action.context.connection_campaign_id
					)
				};
			}
		},
		displayRawJson: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				rawJsonVisible: action.context
			})
		},
		setAccountID: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				accountId: action.context,
				items: []
			})
		}
	}
});

//region Export

const store = createStore(rootReducer, applyMiddleware(thunk));
export { types, actions, rootReducer as reducer, store };

//endregion Export
