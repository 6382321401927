import * as actionTypes from '../constants/action-types';
import * as envConstants from '../../js/constants/env-settings';
import store from '../store/index';
import moment from 'moment';

export function toggleWaitState() {
	return {
		type: actionTypes.TOGGLE_WAIT
	};
}

// API data action
export function dataRetrievalError(errorData) {
	// console.log('data error');
	// console.log(errorData);
	return {
		type: actionTypes.DATA_RETRIEVAL_ERROR,
		errorData: errorData
	};
}

// user data
export function fetchPortalData() {
	return {
		type: actionTypes.FETCH_PORTAL_DATA
	};
}

export function receivedPortalData(data) {
	//console.log('ChargeBee data: ');
	//console.log(data);
	return {
		type: actionTypes.RECEIVED_PORTAL_DATA,
		portalData: data
	};
}

export const retrieveChargeBeePortal = userID => {
	store.dispatch(fetchPortalData());
	let retrievePortalURL = envConstants.CHARGEBEE_MIDDLEWARE_URL + userID;
	return function(dispatch) {
		return fetch(retrievePortalURL)
			.then(data => data.json())
			.then(data => {
				dispatch(receivedPortalData(data));
			})
			.catch(err => dispatch(dataRetrievalError(err)));
	};
};

// campaign
export const submitCampaignConfig = campaignConfig => {
	//console.log('sending config');
	//console.log(JSON.stringify(campaignConfig));
	let headers = new Headers({
		'Content-Type': 'text/json'
	});
	return function(dispatch) {
		fetch('https://aa9fo5s1xd.execute-api.us-east-1.amazonaws.com/dev/campaign', {
			method: 'POST',
			mode: 'no-cors',
			headers: headers,
			redirect: 'follow',
			referrer: 'no-referrer',
			body: JSON.stringify(campaignConfig)
		}).then(
			res => {
				dispatch(getCampaignList());
			},
			error => {
				console.log('error:');
				console.log(error);
			}
		);
	};
};
export const getDashDetails = () => {
	return function(dispatch) {
		fetch('https://aa9fo5s1xd.execute-api.us-east-1.amazonaws.com/dev/campaign')
			.then(res => res.json())
			.then(data => {
				// console.log('Campaign list:');
				//console.log(data);
				// console.log("Storage: ");
				// Storage.get('LunaBoona/logs/LunaBoona/followed.txt')
				//     .then(result => console.log(result))
				//     .catch(err => console.log(err));

				let startDate = moment(moment().subtract(365, 'days')).format('YYYY-MM-DD') + ' 00:00:00';
				let endDate = moment(moment().add(1, 'days')).format('YYYY-MM-DD') + ' 00:00:00';
				data.forEach((account, i) => {
					data[i].stats = {
						logged_at: '-1',
						total_posts: 0,
						followers: 0,
						following: 0
					};
					let statsURL =
						'https://aa9fo5s1xd.execute-api.us-east-1.amazonaws.com/dev/progress?profile_name=' +
						account.instaUser +
						'&start_time=' +
						startDate +
						'&end_time=' +
						endDate;
					fetch(statsURL)
						.then(res => res.json())
						.then(statsData => {
							if (statsData.length > 0) {
								statsData.sort((a, b) => (a.logged_at < b.logged_at ? 1 : -1));
								data[i].stats = {
									logged_at: statsData[0].logged_at,
									total_posts: statsData[0].total_posts,
									followers: statsData[0].followers,
									following: statsData[0].following
								};
							} else {
								data[i].stats = {
									logged_at: '0',
									total_posts: 0,
									followers: 0,
									following: 0
								};
							}
							dispatch(updateDashDetails(account.campaignID, statsData));
						});
					dispatch(updateCampaignList(data));
					return null;
				});
			});
	};
};

export const loadingCampaign = () => {
	return {
		type: actionTypes.LOADING_CAMPAIGN
	};
};

export const getCampaignList = () => {
	return function(dispatch) {
		fetch('https://aa9fo5s1xd.execute-api.us-east-1.amazonaws.com/dev/campaign')
			.then(res => res.json())
			.then(data => {
				//console=.log('Campaign list:');
				//console.log(data);
				dispatch(updateCampaignList(data));
			});
	};
};
export function updateCampaignList(campaignList) {
	return {
		type: actionTypes.UPDATE_CAMPAIGN_LIST,
		campaignList: campaignList
	};
}
export const editCampaign = campaignID => {
	return function(dispatch) {
		fetch('https://aa9fo5s1xd.execute-api.us-east-1.amazonaws.com/dev/campaign?campaignID=' + campaignID)
			.then(res => res.json())
			.then(data => {
				dispatch(setEditCampaign(data));
			});
	};
};
export const setEditCampaign = campaignData => {
	return {
		type: actionTypes.EDIT_CAMPAIGN,
		campaignData: campaignData
	};
};
export const newCampaign = () => {
	return {
		type: actionTypes.NEW_CAMPAIGN
	};
};
export const cancelCampaignForm = () => {
	return {
		type: actionTypes.CANCEL_CAMPAIGN
	};
};
export const updateDashDetails = (accountID, dashDetails) => {
	return {
		type: actionTypes.UPDATE_DASH_DETAILS,
		accountID: accountID,
		accountDetails: dashDetails
	};
};
export const showDashDetails = accountID => {
	return {
		type: actionTypes.DASH_DETAIL,
		accountID: accountID
	};
};
export const setUserDetails = userObj => {
	return {
		type: actionTypes.SET_USER_ACCESS,
		userObj: userObj
	};
};
export const logoutUser = () => {
	return {
		type: actionTypes.LOG_OUT_USER
	};
};
export const taskbar = page => {
	return {
		type: actionTypes.TASKBAR,
		landingPage: page
	};
};
export const showCreateTopicDialog = open => {
	return {
		type: actionTypes.NEW_TOPIC_MODAL,
		open: open
	};
};
export const hashtaggerValueHandler = value => {
	return {
		type: actionTypes.HASTAGGER_VALUE_HANDLER,
		value: value
	};
};
