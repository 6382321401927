import React, { useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { TooltipButton } from '../../../../widgets';
import { mdiMessageBulletedOff, mdiSend } from '@mdi/js';
import TemplateVariablesPopover from '../../common/TemplateVariablesPopover';

const useStyles = makeStyles(theme => ({
	messagingControls: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(2),
		background: theme.palette.secondary.lightest
	},
	textField: {
		flexGrow: 1,
		height: '100%',
		backgroundColor: theme.palette.background.paper,
		marginRight: theme.spacing(2)
	},
	buttons: {
		display: 'flex',
		flexDirection: 'column'
	},
	buttonSpacer: {
		flexGrow: 1
	}
}));

export default function MessagingControls({ onSubmitMessage }) {
	const classes = useStyles();

	const [messageText, setMessageText] = useState('');

	function handeMessageTextChange(event) {
		setMessageText(event.target.value);
	}

	function handleSubmitMessage() {
		if (onSubmitMessage && messageText) {
			onSubmitMessage(messageText);
			setMessageText('');
		}
	}

	return (
		<div className={classes.messagingControls}>
			<TextField
				variant="outlined"
				className={classes.textField}
				multiline
				rows={5}
				value={messageText}
				onChange={handeMessageTextChange}
			/>
			<div className={classes.buttons}>
				<TemplateVariablesPopover />
				<TooltipButton
					tooltip="Do Not Contact"
					icon={mdiMessageBulletedOff}
					iconSize={0.75}
					disabled={true}
					// onClick={handleMenuOpen}
				/>
				<div className={classes.buttonSpacer} />
				<TooltipButton
					tooltip="Send Message"
					icon={mdiSend}
					iconSize={0.75}
					onClick={handleSubmitMessage}
					disabled={!messageText}
				/>
			</div>
		</div>
	);
}
