import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from "react-redux";
import queryString from 'query-string'
import loadingSpinner from '../../assets/images/loadingSpinner.gif';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as Actions from "../../js/actions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import PoundTheme from "../../theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import classNames from "classnames";
import {Auth} from 'aws-amplify';
import {Redirect} from "react-router-dom";
import {mdiCheckBold} from "@mdi/js";
import Icon from "@mdi/react";


class NewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chargeBeeID: "",
            username: "",
            email:"",
            v1password: "",
            v2password: "",
            waitingConfirmation: false,
            authenticationcode: "",
            displayForm: false,
            wait: false,
            redirect:false,
            showErrorPanel:false,
            errorMessage:"There was an unknown error",
            passwordValid: {
                has8: true,
                hasLowerCase: false,
                hasUpperCase: false,
                hasNumber: false,
                hasSpecialChr: false,
                passwordsMatch: false
            },
            submitDisabled: true
        };
        this.passwordInput=this.passwordInput.bind(this);
        this.password2Input=this.password2Input.bind(this);
        this.checkPasswordMatch=this.checkPasswordMatch.bind(this);
        this.signUpUser=this.signUpUser.bind(this);
        this.confirmUser=this.confirmUser.bind(this);
        this.confirmCodeInput=this.confirmCodeInput.bind(this);
    }

    componentDidMount() {
        let urlData = queryString.parse(this.props.location.search);
        if(urlData.email && urlData.cbid){
            this.setState({
                username: urlData.email,
                v1password: "",
                v2password: "",
                email: urlData.email,
                chargeBeeID: urlData.cbid,
                displayForm: true,
                passwordValid: {
                    has8: false,
                    hasLowerCase: false,
                    hasUpperCase: false,
                    hasNumber: false,
                    hasSpecialChr: false,
                    passwordsMatch: false
                },
                submitDisabled:true
            })
        }

    }
    checkPasswordMatch(){
        const disableSubmit = (obj) => {
            for(var o in obj)
                if(!obj[o]) return true;

            return false;
        }
        let pvState = this.state.passwordValid;
        pvState.passwordsMatch = this.state.v1password && (this.state.v1password === this.state.v2password);

        this.setState({
            passwordValid:pvState,
            submitDisabled: disableSubmit(pvState)
        })
    }

    passwordInput(e){
        let checkPW = e.target.value;
        this.setState({
            v1password:checkPW,
            passwordValid: {
                has8: checkPW.length > 8,
                hasLowerCase: /[a-z]/.test(checkPW),
                hasUpperCase: /[A-Z]/.test(checkPW),
                hasNumber: /[0-9]/.test(checkPW),
                hasSpecialChr: /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g.test(checkPW)
            }
        },() => this.checkPasswordMatch());
    }
    password2Input(e){
        this.setState({v2password:e.target.value}, () => this.checkPasswordMatch());
    }

    confirmCodeInput(e){
        this.setState({authenticationcode:e.target.value})
    }

    signUpUser(){
        const{chargeBeeID, username, email, v1password} = this.state;
        let password = v1password;
        this.setState({wait:true});
        Auth.signUp({
            'username': username,
            'password': password,
            'attributes': {
                'email': email,
                'custom:chargebee_id': chargeBeeID
            }
        }).then(res => {
            this.setState({
                wait:false,
                waitingConfirmation: true
            });
            //console.log('User Registration Success');
        }, err =>{
            this.setState({
                wait:false,
                showErrorPanel:true,
                errorMessage:err.message
            });
            //console.log('Returned Error');
            //console.log(err);
        })
    }

    confirmUser(){
        const{username, authenticationcode} = this.state;

        this.setState({wait:true});
        Auth.confirmSignUp(username, authenticationcode
        ).then(res => {
            this.setState({wait:false});
            alert('you have verified your account successfully');
            this.setState({redirect:true});
        }, err =>{
            this.setState({wait:false});
            console.log('Account Verification Error');
            console.log(err);
        })
    }





    render() {
        const {classes} = this.props;
        const renderNoChargeBeeData = () => {
            return (
                <Paper className={classNames(classes.paper)}>
                    <Typography component="h1" variant="h5">
                        You shouldn't be here.
                    </Typography>
                </Paper>
            )
        };
        const renderCompleteUserSetup = (passwordValid) => {
            return (
                <Paper className={classNames(classes.paper)}>
                    {this.state.wait?
                        <div>
                            <Typography component="h1" variant="h5">
                                Registering user please Wait
                            </Typography>
                            <img src={loadingSpinner} alt="Wait Spinner" height="50"/>
                        </div>:
                        <div>
                            {this.state.showErrorPanel?
                                <div>
                                    <Typography component="h1" variant="h5">
                                        An Error occurred
                                    </Typography>
                                    <Typography>
                                        {this.state.errorMessage}
                                    </Typography>
                                </div>:
                                <div>
                                    {this.state.waitingConfirmation ?
                                        <div>
                                            <Typography component="h1" variant="h5">
                                                Confirm your Email address
                                            </Typography>
                                            <Typography>
                                                A confirmation code has been sent to your Email address. Enter it below to
                                                confirm your account
                                            </Typography>
                                            <form className={classes.form}>
                                                <FormControl margin="normal" required fullWidth>
                                                    <InputLabel htmlFor="confirmationCode">Confirmation Code</InputLabel>
                                                    <Input name="confirmationCode"
                                                           type="text"
                                                           id="confirmationCode"
                                                           autoComplete="confirmationCode"
                                                           onChange={this.confirmCodeInput}
                                                    />
                                                </FormControl>


                                                <Button
                                                    type="button"
                                                    fullWidth
                                                    variant="contained"
                                                    color="secondary"
                                                    className={classes.submit}
                                                    onClick={() => this.confirmUser()}
                                                >
                                                    Confirm
                                                </Button>
                                            </form>
                                        </div> :
                                        <div>
                                            <Typography component="h1" variant="h5">
                                                Add a password to Complete your Pound Social account setup
                                            </Typography>
                                            <Typography component="p" variant="subheading">
                                                For Account: {this.state.username}
                                            </Typography>
                                            <Typography component="p" variant="body2">
                                                Your password must contain:
                                            </Typography>
                                            <Typography component="p" variant="body2">
                                                {passwordValid.has8 ?
                                                <Icon
                                                    path={mdiCheckBold}
                                                    size={.5}
                                                    color={'green'}
                                                />: null} at least 8 characters
                                            </Typography>
                                            <Typography component="p" variant="body2">
                                                {passwordValid.hasUpperCase ? <Icon
                                                    path={mdiCheckBold}
                                                    size={.5}
                                                    color={'green'}
                                                /> : null} An uppercase letter
                                            </Typography>
                                            <Typography component="p" variant="body2">
                                                {passwordValid.hasLowerCase ?
                                                    <Icon
                                                        path={mdiCheckBold}
                                                        size={.5}
                                                        color={'green'}
                                                    /> : null}
                                                A lowercase letter
                                            </Typography>
                                            <Typography component="p" variant="body2">
                                                {passwordValid.hasNumber ?
                                                    <Icon
                                                        path={mdiCheckBold}
                                                        size={.5}
                                                        color={'green'}
                                                    /> : null}
                                                A number
                                            </Typography>
                                            <Typography component="p" variant="body2">
                                                {passwordValid.hasSpecialChr ?
                                                    <Icon
                                                        path={mdiCheckBold}
                                                        size={.5}
                                                        color={'green'}
                                                    /> : null}
                                                A special character (!@#$%^& ....)
                                            </Typography>
                                            <form className={classes.form}>
                                                <FormControl margin="normal" required fullWidth>
                                                    <InputLabel htmlFor="password">password</InputLabel>
                                                    <Input name="password1"
                                                           type="password"
                                                           id="password1"
                                                           autoComplete="new-password"
                                                           onChange={this.passwordInput}/>
                                                </FormControl>
                                                <FormControl margin="normal" required fullWidth>
                                                    <InputLabel htmlFor="password2">Verrify password</InputLabel>
                                                    <Input name="password2"
                                                           type="password"
                                                           id="password2"
                                                           autoComplete="new-password"
                                                           onChange={this.password2Input}/>
                                                </FormControl>

                                                <Button
                                                    type="button"
                                                    fullWidth
                                                    variant="contained"
                                                    color="secondary"
                                                    className={classes.submit}
                                                    disabled={ this.state.submitDisabled }
                                                    onClick={() => this.signUpUser()}
                                                >
                                                    Submit
                                                </Button>
                                            </form>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }

                </Paper>
            )
        };

        return (
            <MuiThemeProvider theme={PoundTheme}>
                {this.state.redirect ?
                    <Redirect to='/'/> :
                    <main className={classes.main}>
                        <CssBaseline/>
                        {this.state.displayForm ?
                            renderCompleteUserSetup(this.state.passwordValid) :
                            renderNoChargeBeeData()
                        }
                    </main>
                }
            </MuiThemeProvider>
        )
    }
}

const styles = PoundTheme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: PoundTheme.spacing.unit * 3,
        marginRight: PoundTheme.spacing.unit * 3,
        [PoundTheme.breakpoints.up(400 + PoundTheme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: PoundTheme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${PoundTheme.spacing.unit * 2}px ${PoundTheme.spacing.unit * 3}px ${PoundTheme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: PoundTheme.spacing.unit,
        backgroundColor: "#FF2951",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: PoundTheme.spacing.unit,
    },
    submit: {
        marginTop: PoundTheme.spacing.unit * 3,
        marginBottom: PoundTheme.spacing.unit
    },
    button: {
        marginBottom: PoundTheme.spacing.unit
    }
});

NewUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        appData: state.appData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveChargeBeePortal: (chargeBeeID) => dispatch(Actions.retrieveChargeBeePortal(chargeBeeID)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(withStyles(styles)(NewUser));