//process raw to plot data

export function processData(data) {
	let plotData = [];

	for (let date in data) {
		plotData.push(data[date]);
	}
	plotData = plotData.reverse();
	return plotData;
}

//calculate the difference for the total and sum of other types of connection
export function calculateLegendNumbers(data, fieldName, COUNT_TYPES) {
	let calculatedSumsOfData = [];
	COUNT_TYPES.forEach(typeName => {
		let dayData = [];
		let differenceOfArray = different(data, typeName);
		let arraySum = sumOfArray(data, typeName);
		let averageOfArray = Math.floor(arraySum / data.length);

		if (typeName === COUNT_TYPES[0] || typeName === COUNT_TYPES[5]) {
			dayData.push(differenceOfArray);
		} else {
			dayData.push(arraySum);
		}
		dayData.push(averageOfArray);
		calculatedSumsOfData.push(dayData);
	});
	return calculatedSumsOfData;
}
//Different between first and latest
function different(data, typeName) {
	if (data.length === 0) return 0;
	return data[data.length - 1][typeName] - data[0][typeName];
}

function sumOfArray(data, typeName) {
	const sumOfCounts = data.reduce((sum, currentValue) => {
		return sum + currentValue[typeName];
	}, 0);
	return sumOfCounts;
}

//Find maxima for normalizing data
export function calculateMaxima(data, COUNT_TYPES) {
	let maximals = [];
	COUNT_TYPES.forEach(key => {
		maximals.push(Math.max(...data[key].map(d => d.y)));
	});
	return maximals;
}

//calculate ticks and labels in axis
export function calculateAxisLabels(data, yAxisMaxima, COUNT_TYPES) {
	let leftLabel = [];
	let leftFormat = [];
	let rightLabel = [];
	let rightFormat = [];
	COUNT_TYPES.forEach((typeName, index) => {
		let lengthOfPlotData = data[typeName].length;

		if (index === 0) {
			leftLabel.push(data[typeName][0] ? data[typeName][0].y / yAxisMaxima.left : 0);
			rightLabel.push(lengthOfPlotData > 0 ? data[typeName][lengthOfPlotData - 1].y / yAxisMaxima.left : 0);
		} else {
			leftLabel.push(data[typeName][0] ? data[typeName][0].y / yAxisMaxima.right : 0);
			rightLabel.push(lengthOfPlotData > 0 ? data[typeName][lengthOfPlotData - 1].y / yAxisMaxima.right : 0);
		}
		leftFormat.push(data[typeName][0] ? data[typeName][0].y : 0);
		rightFormat.push(lengthOfPlotData > 0 ? data[typeName][lengthOfPlotData - 1].y : 0);
	});
	leftLabel.push(1.1);

	return {
		leftTicks: leftLabel,
		rightTicks: rightLabel,
		leftFormats: leftFormat,
		rightFormats: rightFormat
	};
}
