import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles, Badge, Menu, MenuItem } from '@material-ui/core';
import {
	mdiAccountClockOutline,
	mdiChartLine,
	mdiChartTree,
	mdiMessageBulleted,
	mdiMessageTextClockOutline,
	mdiShieldCheck,
	mdiShieldLinkVariant,
	mdiShieldRemove,
	mdiSquareEditOutline,
	mdiTrashCan,
	mdiDotsVerticalCircleOutline
} from '@mdi/js';
import { ConfirmationRequiredButton, TooltipButton } from '../../../../widgets';
import { actions as accountActions } from '../store';
import { actions as proxyActions } from '../../../proxy/proxies/store/proxies';

const StyledBadge = withStyles(theme => ({
	badge: {
		right: 7,
		top: 20,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px'
	}
}))(Badge);

function AccountActionsRenderer({ value, data }) {
	const account_id = value;
	const username = data.li_username;
	const unreadCount = data.unread_count;

	const dispatch = useDispatch();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	function handleViewDashboard(event) {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		history.push(`/li/accounts/${account_id}`);
	}

	function handleViewReport(event) {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		history.push(`/li/reports/${account_id}`);
	}

	function handleViewMessaging(event) {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		history.push(`/li/messaging/${account_id}`);
	}

	function handleViewConnectionCampaigns(event) {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		history.push(`/li/connection-campaigns/${account_id}`);
	}

	function handleViewMessageCampaigns(event) {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		history.push(`/li/message-campaigns/${account_id}`);
	}

	function handleEdit(event) {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		dispatch(
			accountActions.displayEditFormAccount({
				visible: true,
				account_id: account_id
			})
		);
	}

	function handleDeleteAccount() {
		dispatch(
			accountActions.deleteAccount({
				id: account_id,
				account_id: account_id
			})
		);
	}

	function handlePersistProxy() {
		dispatch(
			proxyActions.persistAccountProxy({
				account_id: account_id,
				account_type: 'li'
			})
		);
	}

	function handleReassignProxy() {
		dispatch(
			accountActions.reassignProxyAccount({
				id: account_id,
				account_id: account_id,
				account_type: 'li'
			})
		);
	}

	function handleRemoveProxy() {
		dispatch(
			accountActions.reassignProxyAccount(
				{ id: account_id, account_id: account_id, account_type: 'li' },
				{ query: { reassign: false } }
			)
		);
	}

	return (
		<span>
			<TooltipButton tooltip="View Dashboard" icon={mdiChartLine} iconSize={0.75} onClick={handleViewDashboard} />
			<TooltipButton tooltip="View Report" icon={mdiChartTree} iconSize={0.75} onClick={handleViewReport} />
			<StyledBadge badgeContent={unreadCount} color="primary">
				<TooltipButton
					tooltip="View Messaging"
					icon={mdiMessageBulleted}
					iconSize={0.75}
					onClick={handleViewMessaging}
				/>
			</StyledBadge>
			<TooltipButton
				tooltip="View Message Campaigns"
				icon={mdiMessageTextClockOutline}
				iconSize={0.75}
				onClick={handleViewMessageCampaigns}
			/>
			<TooltipButton
				tooltip="View Connection Campaigns"
				icon={mdiAccountClockOutline}
				iconSize={0.75}
				onClick={handleViewConnectionCampaigns}
			/>
			<TooltipButton tooltip="Edit" icon={mdiSquareEditOutline} iconSize={0.75} onClick={handleEdit} />
			<TooltipButton
				tooltip="More Actions"
				icon={mdiDotsVerticalCircleOutline}
				iconSize={0.75}
				onClick={handleMenuOpen}
			/>
			<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
				<MenuItem onClick={handleMenuClose}>
					<ConfirmationRequiredButton
						onConfirm={handleDeleteAccount}
						text={"Delete account '" + username + "'?"}
						tooltip="Delete"
						submitLabel="Delete"
						icon={mdiTrashCan}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<ConfirmationRequiredButton
						onConfirm={handlePersistProxy}
						text={"Create Proxy from account '" + username + "'?"}
						tooltip="Create Proxy"
						submitLabel="Create Proxy"
						icon={mdiShieldCheck}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<ConfirmationRequiredButton
						onConfirm={handleReassignProxy}
						text={"Assign new proxy for account '" + username + "'?"}
						tooltip="Reassign Proxy"
						submitLabel="Reassign Proxy"
						icon={mdiShieldLinkVariant}
					/>
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<ConfirmationRequiredButton
						onConfirm={handleRemoveProxy}
						text={"Remove proxy from account '" + username + "'?"}
						tooltip="Remove Proxy"
						submitLabel="Remove Proxy"
						icon={mdiShieldRemove}
					/>
				</MenuItem>
			</Menu>
		</span>
	);
}

export default AccountActionsRenderer;
