export default function get_report_type(startDateString, endDateString) {
	return {
		colState: [
			{
				colId: 'profile_id',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'status',
				hide: false,
				aggFunc: 'count',
				width: 200,
				pivotIndex: 0,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'statusDate',
				hide: false,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: 0,
				flex: 0
			},
			{
				colId: 'statusTime',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'statusDateWeek',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'statusDateDay',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'statusDateMonth',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'statusDateYear',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'fullName',
				hide: false,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'numOfConnections',
				hide: false,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'location',
				hide: false,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'industry',
				hide: false,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'headline',
				hide: false,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'defaultPosition.title',
				hide: false,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'summary',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'memorialized',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'requestDate',
				hide: false,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'requestDateWeek',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'requestDateDay',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'requestDateMonth',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'requestDateYear',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'connectDate',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'connectDateWeek',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'connectDateDay',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'connectDateMonth',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'connectDateYear',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'connectDelay',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'withdrawDate',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'withdrawDateWeek',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'withdrawDateDay',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'withdrawDateMonth',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'withdrawDateYear',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'disconnectDate',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'disconnectDateWeek',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'disconnectDateDay',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'disconnectDateMonth',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			},
			{
				colId: 'disconnectDateYear',
				hide: true,
				aggFunc: null,
				width: 200,
				pivotIndex: null,
				pinned: null,
				rowGroupIndex: null,
				flex: 0
			}
		],
		groupState: [
			{ groupId: 'FAKE_PATH_ag-Grid-AutoColumn}_0', open: null },
			{ groupId: 'pivot2', open: false },
			{ groupId: 'pivot6', open: false },
			{ groupId: 'pivot0', open: false },
			{ groupId: 'pivot4', open: false }
		],
		sortState: [{ colId: 'ag-Grid-AutoColumn', sort: 'asc' }],
		filterState: {},
		pivotMode: true,
		chartParams: {
			chartType: 'line'
		}
	};
}
