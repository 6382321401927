import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import RowDetailRenderer from './rowDetailRenderer';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	}
}));

const RowDetailToolPanel = forwardRef((props, ref) => {
	const classes = useStyles();
	// const inputRef = useRef();

	// Grid Callbacks
	useImperativeHandle(ref, () => {
		return {
			getReactContainerStyle: () => {
				return {
					width: '100%'
				};
			}
		};
	});

	// Listen for selection change events
	const [selectedNodes, setSelectedNodes] = useState([]);
	useEffect(() => {
		function handleSelectionChanged(params) {
			setSelectedNodes(params.api.getSelectedNodes());
		}
		props.api.addEventListener('selectionChanged', handleSelectionChanged);
		return () => {
			props.api.removeEventListener('selectionChanged', handleSelectionChanged);
		};
	}, [props.api]);

	// Display instructions if no nodes selected
	if (selectedNodes.length < 1) {
		return (
			<div className={classes.root}>
				<ul>
					<li>Click a row to select it</li>
					<li>SHIFT + drag to select a group of rows</li>
					<li>[Mac] CMD + click to select or deselect multiple rows</li>
					<li>[Windows] CTRL + click to select or deselect multiple rows</li>
				</ul>
			</div>
		);
	}

	// Display profile detail cards
	let expand = selectedNodes.length === 1;
	return (
		<div className={classes.root}>
			{selectedNodes.map((node, i) => (
				<RowDetailRenderer key={i} node={node} expand={expand} />
			))}
		</div>
	);
});

export default RowDetailToolPanel;
