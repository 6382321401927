import React from 'react';
import Moment from 'react-moment';
import { default as EnableToggleRenderer } from './EnableToggleRenderer';
import { default as ActionsRenderer } from './ActionsRenderer';

function timestampRenderer(ts) {
	if (ts) {
		return (
			<Moment unix fromNow>
				{ts}
			</Moment>
		);
	}
}

const frameworkComponents = {
	enableToggleRenderer: EnableToggleRenderer,
	actionsRenderer: ActionsRenderer,
	timestampRenderer: ({ value }) => timestampRenderer(value)
};

export default frameworkComponents;
