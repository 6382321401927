import React, { useEffect } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import store from "./js/store/index";
import "./index.css";
import App from "./App";
import NewUser from "./pages/authentication/newUser";

async function isUserLoggedIn() {
  let promise = new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
  let result = await promise;
  return result;
}

const mobileRender = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/newaccount" component={NewUser} />
        <Route render={() => <App />} />
      </Switch>
    </HashRouter>
  );
};

function WebRenderer() {
  const history = useHistory();

  useEffect(() => {
    let path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];
    if (path) {
      history.replace(path);
    }
  }, []);

  return (
    <Switch>
      <Route path="/newaccount" component={NewUser} />
      <Route
        path="/login"
        render={() => {
          return <App />;
        }}
        exact
      />
      <Route render={() => <App />} />
    </Switch>
  );
}

const webRender = (loggedin) => {
  return (
    <BrowserRouter>
      <WebRenderer />
    </BrowserRouter>
  );
};

const startApp = (environment, loggedin) => {
  render(
    <Provider store={store}>
      <Helmet>
        <title>#Pound Social</title>
      </Helmet>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML:
              "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
              "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
              "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
              "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
              "})(window,document,'script','dataLayer','GTM-WQ78S7L');",
          },
        ]}
      />
      {environment === "web" ? webRender(loggedin) : mobileRender()}
    </Provider>,
    document.getElementById("root")
  );
};

if (window.cordova) {
  console.log("Running cordova");
  document.addEventListener("deviceready", startApp, false);
} else {
  console.log("Running without cordova");
  isUserLoggedIn().then((result) => {
    startApp("web", result);
  });
}
