import React from 'react';
import clsx from 'clsx';
import { makeStyles, Divider, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import {
	mdiAccount,
	mdiInstagram,
	mdiLinkedin,
	mdiLogout,
	mdiSecurity,
	mdiSecurityNetwork,
	mdiFolderPound,
	mdiPoundBox,
	mdiShieldAlertOutline,
	mdiCellphoneInformation,
	mdiAccountAlertOutline
} from '@mdi/js';
import PSIcon from './customIcons/ps-icons';

const useStyles = makeStyles(theme => ({
	menuIcon: {
		marginLeft: '6px',
		marginRight: '24px'
	},
	listText: {
		[theme.breakpoints.down('sm')]: {
			color: 'white'
		},
		[theme.breakpoints.up('sm')]: {
			color: '#808080'
		}
	},
	desktopButton: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	mobileButton: {
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	listSubheader: {
		[theme.breakpoints.up('sm')]: {
			color: theme.palette.primary.main
		},
		[theme.breakpoints.down('sm')]: {
			color: 'white'
		}
	}
}));

function MenuItem({ path, icon, text, psIcon = false }) {
	const classes = useStyles();
	return (
		<ListItem button component={Link} to={path} edge="start">
			<div className={clsx(classes.menuIcon, classes.listText)}>
				{psIcon ? (
					<PSIcon icon={icon} />
				) : (
					<>
						<span className={classes.desktopButton}>
							<Icon path={icon} size={1.1} color="#808080" />
						</span>
						<span className={classes.mobileButton}>
							<Icon path={icon} size={1.1} color="white" />
						</span>
					</>
				)}
			</div>
			<ListItemText primary={text} className={classes.listText} />
		</ListItem>
	);
}

export function AdminListItems() {
	const classes = useStyles();
	return (
		<div>
			{/* <MenuItem path="/dashboard" icon={mdiViewDashboard} text="Dashboard" />
			<MenuItem path="/posts" icon={mdiSend} text="Posts" /> */}
			<ListSubheader inset className={classes.listSubheader} icon={mdiSecurityNetwork}>
				HASHTAGR
			</ListSubheader>
			<MenuItem path="/hashtagr-app-events" icon={mdiCellphoneInformation} text="Hashta.gr App Events" />
			<ListSubheader inset className={classes.listSubheader}>
				PROXIES
			</ListSubheader>
			<MenuItem path="/proxies" icon={mdiSecurity} text="Proxies" />
			<MenuItem path="/hashtagr-proxy-events" icon={mdiShieldAlertOutline} text="Hashta.gr Proxy Events" />
			<ListSubheader inset className={classes.listSubheader}>
				INSTAGRAM
			</ListSubheader>
			<MenuItem path="/ig-accounts" icon={mdiInstagram} text="Accounts" />
			<MenuItem path="/ig-account-events" icon={mdiAccountAlertOutline} text="Account Events" />
			<ListSubheader inset className={classes.listSubheader}>
				LINKED IN
			</ListSubheader>
			<MenuItem path="/li-accounts" icon={mdiLinkedin} text="LinkedIn Accounts" />
		</div>
	);
}

export const hashtagUserListItems = (
	<div>
		<ListSubheader inset color="primary">
			Hashtagr
		</ListSubheader>
		<MenuItem path="/hashtagger" icon={mdiPoundBox} text="Search" />
		<MenuItem path="/collections" icon={mdiFolderPound} text="Collections" />
	</div>
);

export const loggedInListItems = (
	<div>
		<ListSubheader inset color="primary">
			Account
		</ListSubheader>
		<MenuItem path="/portal" icon={mdiAccount} text="Manage Account" />
		<MenuItem path="/logout" icon={mdiLogout} text="Logout" />
	</div>
);

export const loggedInAdminListItems = (
	<div>
		{/* <MenuItem path="/portal" icon={mdiAccount} text="Manage Account"/> */}
		<MenuItem path="/logout" icon={mdiLogout} text="Logout" />
	</div>
);

export const loggedOutListItems = (
	<div>
		<ListSubheader inset color="primary">
			Account
		</ListSubheader>
		<ListItem button component={Link} to="/login">
			<ListItemIcon>
				<AccountCircleIcon />
			</ListItemIcon>
			<ListItemText primary="Login" />
		</ListItem>
		<a href="https://www.pound.social/pricing" style={{ textDecoration: 'none' }}>
			<ListItem button>
				<ListItemIcon>
					<AccountCircleIcon />
				</ListItemIcon>
				<ListItemText primary="Get Account " />
			</ListItem>
		</a>
	</div>
);

export const demoNoLoginListItems = (
	<div>
		<ListSubheader inset color="primary">
			Account
		</ListSubheader>

		<a href="/#demo" style={{ textDecoration: 'none' }}>
			<ListItem button>
				<ListItemIcon>
					<AccountCircleIcon />
				</ListItemIcon>
				<ListItemText primary="Get Pricing " />
			</ListItem>
		</a>

		<Divider />
		<ListItem button component={Link} to="/login">
			<ListItemIcon>
				<AccountCircleIcon />
			</ListItemIcon>
			<ListItemText primary="Login" />
		</ListItem>
	</div>
);
