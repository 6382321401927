 export function formatNumber(num) {
    if (num) {
        let [whole, fractional] = num.toString().split('.');
        whole = whole.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        return whole + (fractional ? ("." + fractional) : "")
    } else if (num === 0) {
        return 0;
    }
    return null
};

export function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.ceil(("" + value).length / 3) - 1;
        var shortValue = '';
        shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2));
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
}

export function truncateWithEllipses(text, max) {
    return text.substr(0, max) + (text.length > max ? '...' : '');
}

export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNumberString
}

