/**
 * Requires the field to meet all validation requirements.
 * Returns the first validation requirement that is not met.
 */
export const all = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

/**
 * Requires the field's value to be specified.
 */
export const required = value => ((value == null) ? "Required" : undefined)

/**
 * Requires the field's value be a number, if specified.
 */
export const isNumber = value => (( value && isNaN(value)) ? 'Must be a number' : undefined)

/**
 * Requires the field's value be greater than or equal to a specified value.
 */
export const minValue = min => value =>
    isNaN(value) || value >= min ? undefined : `Must be greater than or equal to ${min}`

/**
 * Requires the field's value be less than or equal to a specified value.
 */
export const maxValue = max => value =>
    isNaN(value) || value <= max ? undefined : `Must be less than or equal to ${max}`

/**
 * Requires the field's value be a valid port number (ie, [1, 65535])
 */
export const portNumber = value =>
    all(isNumber, minValue(1), maxValue(65535))(value)