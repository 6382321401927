import React from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Amplify, { Auth } from 'aws-amplify';
import { CssBaseline } from '@material-ui/core';
import { ConfirmSignIn, ConfirmSignUp, RequireNewPassword, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import Iframe from 'react-iframe';
import NoScript from 'react-noscript';
import { ForgotPasswordForm, NewPasswordForm, SignInForm, SignUpForm } from './pages/authentication/authForms';
import PoundTheme from './theme';
import * as Actions from './js/actions';
import * as envConstants from './js/constants/env-settings';
import { version } from '../package.json';

import AppLoadingOverlay from './AppLoadingOverlay';
import AppContentSwitch from './AppContentSwitch';
import AppHeaderBar from './AppHeaderBar';
import AppMenuDrawer from './AppMenuDrawer';

require('dotenv').config();

Amplify.configure(envConstants.AMPLIFY_CONFIGURE);

const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		height: 'auto',
		minHeight: '100%',
		width: '100%'
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		width: '0'
	},
	appBarSpacer: theme.mixins.toolbar
});

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			fileUrl: '',
			file: '',
			fileName: ''
		};
	}

	componentDidMount() {
		let userObj = {};
		Auth.currentAuthenticatedUser({ bypassCache: true })
			.then(user => {
				//console.log('user data from Cognito: ');
				//console.log(user);
				let chargebeeIDKey = 'custom:chargebee_id';
				this.props.retrieveChargeBeePortal(user.attributes[chargebeeIDKey]);
				if (user.attributes[chargebeeIDKey] && user.attributes[chargebeeIDKey] !== 'administrator') {
					userObj = {
						access: {
							hashtagger: true,
							dashboard: false,
							posts: false
						},
						landingPage: '/hashtagger',
						userGroup: 'customer',
						chargeBeeID: user.attributes[chargebeeIDKey],
						creator_id: user.username,
						email: user.attributes.email
					};
				} else {
					userObj = {
						access: {
							hashtagger: true,
							dashboard: true,
							posts: true,
							profiles: true,
							proxies: true
						},
						landingPage: '/hashtagr-app-events',
						userGroup: 'admin',
						chargeBeeID: 'admin',
						creator_id: user.username
					};
				}
				this.props.setUserDetails(userObj);
			})
			.catch(err => console.log(err));

		console.log('App version: ' + version);
		console.log('Running Environment: ' + process.env.REACT_APP_RUN_ENVIRONMENT);
	}

	handleDrawerOpen() {
		this.setState({ open: true });
	}

	handleDrawerClose() {
		this.setState({ open: false });
	}

	toggleDrawer = open => {
		this.setState({ open: open });
	};

	render() {
		const { classes } = this.props;
		let authenticated = this.props.appData.authenticated;

		if (!authenticated) {
			return (
				<MuiThemeProvider theme={PoundTheme}>
					<CssBaseline />
					<AppLoadingOverlay />
				</MuiThemeProvider>
			);
		}

		return (
			<MuiThemeProvider theme={PoundTheme}>
				<div className={classes.root}>
					<NoScript>
						<Iframe url="https://www.googletagmanager.com/ns.html?id=GTM-WQ78S7L" height={0} width={0} />
					</NoScript>
					<CssBaseline />
					<AppHeaderBar open={this.state.open} onMenuButtonClicked={() => this.handleDrawerOpen()} />
					<AppMenuDrawer open={this.state.open} onCloseButtonClick={() => this.handleDrawerClose()} />
					<div className={classes.contentContainer}>
						<div className={classes.appBarSpacer} />
						<AppContentSwitch />
					</div>
				</div>
			</MuiThemeProvider>
		);
	}
}

App.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	return {
		appData: state.appData,
		hashData: state.hashData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setUserDetails: userObj => dispatch(Actions.setUserDetails(userObj)),
		retrieveChargeBeePortal: userID => dispatch(Actions.retrieveChargeBeePortal(userID)),
		showCreateTopicDialog: open => dispatch(Actions.showCreateTopicDialog(open)),
		hashtaggerValueHandler: value => dispatch(Actions.hashtaggerValueHandler(value))
	};
};

export default withAuthenticator(
	withRouter(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(withStyles(styles)(App))
	),
	false,
	[
		<SignInForm />,
		<SignUpForm />,
		<ConfirmSignIn />,
		<ConfirmSignUp />,
		<VerifyContact />,
		<ForgotPasswordForm />,
		<NewPasswordForm />,
		<RequireNewPassword />
	]
);
