import React, { useState } from 'react';
import { makeStyles, Popover, Typography } from '@material-ui/core';
import { TooltipButton } from '../../../widgets';

import { mdiMessageFlashOutline } from '@mdi/js';

const useStyles = makeStyles(theme => ({
	popover: {
		padding: theme.spacing(1)
	},
	paper: {
		padding: theme.spacing(1)
	}
}));

export default function TemplateVariablesPopover({ ...props }) {
	var classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const openPopover = Boolean(anchorEl);

	function handlePopoverOpen(event) {
		setAnchorEl(event.currentTarget);
	}

	function handlePopoverClose() {
		setAnchorEl(null);
	}

	return (
		<div {...props}>
			<div>
				<TooltipButton
					tooltip="View Message Template Variables"
					icon={mdiMessageFlashOutline}
					iconSize={0.75}
					onClick={handlePopoverOpen}
				/>
			</div>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper
				}}
				open={openPopover}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography>{'{firstName} - first name'}</Typography>
				<Typography>{'{lastName} - last name'}</Typography>
				<Typography>{'{fullName} - full name'}</Typography>
				<Typography>{'{industry} - industry'}</Typography>
				<Typography>{'{location} - location'}</Typography>
			</Popover>
		</div>
	);
}
