import React from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { default as ActionsRenderer } from './ActionsRenderer';
import { default as EnableToggleRenderer } from './EnableToggleRenderer';

function timestampRenderer(ts) {
	if (ts) {
		return (
			<Moment unix fromNow>
				{ts}
			</Moment>
		);
	}
}

function messageCampaignRenderer({ data, ...props }) {
	let message_campaigns = useSelector(state => state.message_campaigns);

	let displayText = data.message_campaign_id;
	if (message_campaigns) {
		const message_campaign = message_campaigns.find(el => el.message_campaign_id === data.message_campaign_id);
		if (message_campaign && message_campaign.title) {
			displayText = message_campaign.title;
		}
	}

	return displayText || null;
}

const frameworkComponents = {
	actionsRenderer: ActionsRenderer,
	enableToggleRenderer: EnableToggleRenderer,
	messageCampaignRenderer: messageCampaignRenderer,
	timestampRenderer: ({ value }) => timestampRenderer(value)
	// accountActionsRenderer: AccountActionsRenderer
};

export default frameworkComponents;
