import { createMuiTheme } from '@material-ui/core/styles';

import { cyan } from '@material-ui/core/colors';

export default createMuiTheme({
	palette: {
		primary: {
			main: '#FF2951',
			compliment: 'rgba(255,41,81,.56)'
		},
		secondary: {
			lightest: cyan[50],
			light: '#65faff',
			main: '#0cc7cc',
			dark: '#00969b'
		},
		text: {
			primary: '#424242'
		}
	},
	MuiListItemIcon: {
		root: {
			height: '3rem'
		}
	},
	typography: {
		useNextVariants: true,
		suppressDeprecationWarnings: true
	},
	overrides: {
		MUIDataTable: {
			paper: {
				boxShadow: 'none'
			}
		},
		MUIDataTableSelectCell: {
			expandDisabled: {
				visibility: 'hidden'
			}
		},
		MUIDataTableHeadCell: {
			root: {
				backgroundColor: '#000',
				padding: '10px 0 10px 10px'
			}
		},
		MuiTableCell: {
			root: {
				lineHeight: 2,
				padding: 0
			}
		},
		SignInFormComp: {
			paper: {
				2: {
					padding: '0'
				}
			}
		}
	}
});
