import React from 'react';
import clsx from 'clsx';
import Moment from 'react-moment';
import { makeStyles, Avatar, ListItem, ListItemText, ListItemAvatar, Typography } from '@material-ui/core';
import { TooltipButton } from '../../../../widgets';
import { mdiPauseCircle, mdiPlayCircle, mdiTrashCan } from '@mdi/js';

import { grey, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: '100%',
		backgroundColor: grey[200]
	},
	paused: {
		backgroundColor: red[50]
	},
	titleContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline'
	},
	titleText: {
		fontWeight: 'bold'
	},
	titleSpacer: {
		flexGrow: 1
	},
	titleControls: {}
}));

export default function PendingEventDisplay({ event, onDeleteMessage, onToggleMessagePaused }) {
	const classes = useStyles();
	let event_ts = event.send_ts;
	let displayName = (event.message_campaign_id ? 'Campaign' : 'Response') + ' Message - ';
	if (event.paused) {
		displayName += 'Paused';
	} else {
		let now = new Date().getTime();
		if (event_ts > now / 1000) {
			displayName += 'Scheduled';
		} else {
			displayName += 'Queued';
		}
	}

	let pauseTooltip = event.paused ? 'Enable Message' : 'Disable Message';
	let pauseIcon = event.paused ? mdiPlayCircle : mdiPauseCircle;

	return (
		<ListItem
			alignItems="flex-start"
			className={clsx(classes.root, {
				[classes.paused]: event.paused
			})}
		>
			<ListItemAvatar>
				<Avatar alt={displayName} src={event.pictureUrl} />
			</ListItemAvatar>
			<ListItemText
				disableTypography={true}
				primary={
					<div className={classes.titleContainer}>
						<Typography component="div" variant="body2" color="textPrimary" className={classes.titleText}>
							{displayName}
						</Typography>
						<div className={classes.titleSpacer} />
						<div className={classes.titleControls}>
							<TooltipButton
								tooltip={pauseTooltip}
								icon={pauseIcon}
								iconSize={0.75}
								onClick={onToggleMessagePaused}
								// disabled={!messageText}
							/>
							<TooltipButton
								tooltip="Delete Message"
								icon={mdiTrashCan}
								iconSize={0.75}
								onClick={onDeleteMessage}
								// disabled={!messageText}
							/>
						</div>
					</div>
				}
				secondary={
					<React.Fragment>
						{event.hasAttachments && (
							<Typography component="div" variant="caption" color="textPrimary">
								Attached file
							</Typography>
						)}
						<Typography component="div" variant="body2" color="textPrimary">
							{event.message}
						</Typography>
						<Typography component="div" variant="caption" color="textPrimary">
							<Moment unix format="MM/DD/YY hh:mm:ss">
								{event_ts}
							</Moment>
							{'  -  '}
							<Moment unix fromNow>
								{event_ts}
							</Moment>
						</Typography>
					</React.Fragment>
				}
			/>
		</ListItem>
	);
}
