// Set the REACT_APP_RUN_ENVIRONMENT variable in the .env file to change the stage for running locally
export const stage = process.env.REACT_APP_RUN_ENVIRONMENT;
console.log('stage:', stage);

// Charge Bee
const CHARGEBEE_MIDDLEWARE_URLS = {
	local: 'https://k7z4qhcfeg.execute-api.us-east-1.amazonaws.com/dev/qa/portal/',
	dev: 'https://k7z4qhcfeg.execute-api.us-east-1.amazonaws.com/dev/qa/portal/',
	qa: 'https://k7z4qhcfeg.execute-api.us-east-1.amazonaws.com/dev/qa/portal/',
	prod: 'https://k7z4qhcfeg.execute-api.us-east-1.amazonaws.com/dev/portal/'
};
export const CHARGEBEE_MIDDLEWARE_URL = CHARGEBEE_MIDDLEWARE_URLS[stage];

// Amplify
const AMPLIFY_CONFIGURES = {
	local: {
		Auth: {
			mandatorySignIn: false,
			region: 'us-east-1',
			userPoolId: 'us-east-1_30r2KY44M',
			identityPoolId: 'us-east-1:3d7d5848-151a-4f2b-8bfc-2370ca67a933',
			userPoolWebClientId: '6eogdhmi1frkinc0c8td7mfpq7'
		}
	},
	dev: {
		Auth: {
			mandatorySignIn: false,
			region: 'us-east-1',
			userPoolId: 'us-east-1_30r2KY44M',
			identityPoolId: 'us-east-1:3d7d5848-151a-4f2b-8bfc-2370ca67a933',
			userPoolWebClientId: '6eogdhmi1frkinc0c8td7mfpq7'
		}
	},
	qa: {
		Auth: {
			mandatorySignIn: false,
			region: 'us-east-1',
			userPoolId: 'us-east-1_30r2KY44M',
			identityPoolId: 'us-east-1:3d7d5848-151a-4f2b-8bfc-2370ca67a933',
			userPoolWebClientId: '6eogdhmi1frkinc0c8td7mfpq7'
		}
	},
	prod: {
		Auth: {
			mandatorySignIn: false,
			region: 'us-east-1',
			userPoolId: 'us-east-1_UvJ8pMmvF',
			identityPoolId: 'us-east-1:014952561591:userpool/us-east-1_UvJ8pMmvF',
			userPoolWebClientId: '1n49s23crtrga71keaghh8s1sc'
		}
	}
};
export const AMPLIFY_CONFIGURE = AMPLIFY_CONFIGURES[stage];

// Proxy API
const PROXY_API_DEV = 'https://rur635uvr4.execute-api.us-east-2.amazonaws.com/dev';
const PROXY_API_PROD = 'https://v7z26zkoyf.execute-api.us-east-1.amazonaws.com/dev';
const PROXY_API_HOSTS = {
	local: 'http://localhost:3001',
	dev: PROXY_API_DEV,
	qa: PROXY_API_DEV,
	prod: PROXY_API_PROD
};
export const PROXY_API_HOST = PROXY_API_HOSTS[stage];
