import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles, Paper } from '@material-ui/core';
import { JsonDisplay } from '../../../../widgets';
import TitleBar from '../../../../widgets/TitleBar';
import { CreateFormDialog, EditFormDialog } from './forms';
import CampaignGrid from './grid';
import { actions, store } from './store';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	spacer: {
		height: theme.spacing(2)
	},
	display: {
		flexGrow: 1
	},
	json: {
		padding: theme.spacing(2)
	}
}));

function WrappedMessageCampaignsPage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const data = useSelector(state => state);
	const [showData, setShowData] = useState(false);

	// Handle changes to the provided account ID
	const { accountId } = useParams();
	const cachedAccountId = useSelector(state => state.accountId);
	useEffect(() => {
		if (accountId !== cachedAccountId) {
			dispatch(actions.setAccountIDCampaign(accountId));
		}
	}, [accountId, cachedAccountId]);

	// Fetch Campaigns
	useEffect(() => {
		if (cachedAccountId) {
			dispatch(actions.fetchCampaigns());
		}
	}, [cachedAccountId]);

	// Creation Form
	const creationFormVisible = useSelector(state => state.creationFormVisible);
	function handleClickAddButton(event) {
		dispatch(actions.displayCreationFormCampaign(true));
	}
	function handleCreateFormSubmit(data) {
		dispatch(actions.createCampaign(data));
		dispatch(actions.displayCreationFormCampaign(false));
	}
	function handleCreateFormClose() {
		dispatch(actions.displayCreationFormCampaign(false));
	}

	// Edit Form
	const editFormVisible = useSelector(state => state.editFormVisible);
	const editFormData = useSelector(state => state.editFormData);
	function handleEditFormSubmit(data) {
		dispatch(actions.updateCampaign(data));
		dispatch(actions.displayEditFormCampaign({ visible: false, id: null }));
	}
	function handleEditFormClose() {
		dispatch(actions.displayEditFormCampaign({ visible: false, id: null }));
	}

	// Misc Actions
	function handleClickRefreshButton(event) {
		if (cachedAccountId) {
			dispatch(actions.fetchCampaigns());
		}
	}
	function handleClickDataButton(event) {
		setShowData(!showData);
	}

	return (
		<div className={classes.root}>
			<TitleBar
				title={'Message Campaigns: ' + cachedAccountId}
				onClickRefreshButton={handleClickRefreshButton}
				onClickAddButton={handleClickAddButton}
				onClickDataButton={handleClickDataButton}
			/>
			<div className={classes.spacer} />
			<CreateFormDialog
				open={creationFormVisible || false}
				onSubmit={handleCreateFormSubmit}
				onClose={handleCreateFormClose}
			/>
			<EditFormDialog
				open={editFormVisible || false}
				data={editFormData}
				onSubmit={handleEditFormSubmit}
				onClose={handleEditFormClose}
			/>
			<Paper className={classes.display} elevation={2}>
				{showData ? (
					<div className={classes.json}>
						<JsonDisplay data={data} />
					</div>
				) : (
					<CampaignGrid data={data.items} />
				)}
			</Paper>
		</div>
	);
}

export default function MessageCampaignsPage() {
	return (
		<Provider store={store}>
			<WrappedMessageCampaignsPage />
		</Provider>
	);
}
