import { useState } from "react";

function stopPropagation(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
}

export function useConfirmation(onConfirm, onCancel) {
    const [open, setOpen] = useState(false);
    function handleOpen(event) {
        stopPropagation(event);
        setOpen(true);
    }
    function handleDialogClose(event) {
        stopPropagation(event);
        setOpen(false);
        if (onCancel) {
            onCancel(event);
        }
    }
    function handleDialogConfirm(event) {
        stopPropagation(event);
        setOpen(false);
        if (onConfirm) {
            onConfirm(event);
        }
    }

    return [open, handleOpen, handleDialogClose, handleDialogConfirm];
}

export default useConfirmation;