import React from 'react';
import {BrowserRouter as Router, Redirect} from "react-router-dom";

class ClosePortal extends React.Component {

    render() {
        return(
            <div>
                <Router>
                {window.open("/", "_top")}
                    <Redirect to={"/"}/>
                </Router>
            </div>
        )
    }
}

export default ClosePortal;