import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { makeStyles, Dialog, DialogContent, DialogTitle, Divider, Grid } from '@material-ui/core';
import { JsonDisplay } from '../../../../../widgets';
import FormActions from '../../../../../widgets/forms/formActions';
import {
	CampaignIDField,
	EnabledField,
	TitleField,
	SavedSearchField,
	ConnectionMessageEnabledField,
	ConnectionMessageField,
	DailyConnectionCountField,
	MessageCampaignSelectField
} from './components';
import TemplateVariablesPopover from '../../../common/TemplateVariablesPopover';

const useStyles = makeStyles(theme => ({
	paper: {
		margin: 20
	},
	title: {
		display: 'flex',
		flexDirection: 'row'
	},
	titleSpacer: {
		flexGrow: 1
	},
	titleButtons: {
		padding: '16px 24px'
	}
}));

function EditFormRenderer({ onClose, handleSubmit, form, submitting, pristine, values }) {
	let rawJsonVisible = useSelector(state => state.rawJsonVisible);
	let message_campaigns = useSelector(state => state.message_campaigns);
	rawJsonVisible = rawJsonVisible || false; // set to false if null

	return (
		<form onSubmit={handleSubmit} noValidate>
			<Grid container alignItems="flex-start" spacing={1}>
				<Grid item xs={6}>
					<CampaignIDField />
				</Grid>
				<Grid item xs={6}>
					<EnabledField />
				</Grid>

				<Grid item xs={12}>
					<TitleField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={12}>
					<SavedSearchField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={6}>
					<ConnectionMessageEnabledField />
				</Grid>
				<Grid item xs={12}>
					<ConnectionMessageField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={12}>
					<DailyConnectionCountField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={12}>
					<MessageCampaignSelectField options={message_campaigns} />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>

				<FormActions form={form} onClose={onClose} pristine={pristine} submitting={submitting} />
			</Grid>
			<JsonDisplay data={values} visible={rawJsonVisible} />
		</form>
	);
}

function EditFormDialog({ open, data, onSubmit, onClose }) {
	var classes = useStyles();
	return (
		<Dialog
			className={classes.root}
			classes={{
				paper: classes.paper
			}}
			open={open}
			onClose={onClose}
		>
			<div className={classes.title}>
				<DialogTitle>Edit Connection Campaign</DialogTitle>
				<div className={classes.titleSpacer} />
				<TemplateVariablesPopover className={classes.titleButtons} />
			</div>
			<DialogContent>
				<div style={{ padding: 2, margin: 1, maxWidth: 600 }}>
					<Form onSubmit={onSubmit} onClose={onClose} initialValues={data} render={EditFormRenderer} />
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default EditFormDialog;
