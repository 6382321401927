import {
	getDateFromFormattedString,
	timestampToFormattedDate,
	secondsToStr,
	getWeekIndex,
	getDayOfWeek,
	getMonth,
	DAYS_OF_WEEK,
	MONTHS
} from '../utils';

const DEFAULT_COLUMN_DEF = {
	minWidth: 100,
	// allow every column to be aggregated
	enableValue: true,
	// allow every column to be grouped
	enableRowGroup: true,
	// allow every column to be pivoted
	enablePivot: true,
	sortable: true,
	filter: true,
	resizable: true,
	floatingFilter: true
};

const TIMESTAMP_FIELDS = [
	{ field: 'statusTime', headerPrefix: 'Status', colIdPrefix: 'status' },
	{ field: 'connectionRequestTime', headerPrefix: 'Request', colIdPrefix: 'request' },
	{ field: 'connectionTime', headerPrefix: 'Connection', colIdPrefix: 'connect' },
	{ field: 'connectionWithdrawnTime', headerPrefix: 'Request Withdrawn', colIdPrefix: 'withdraw' },
	{ field: 'disconnectionTime', headerPrefix: 'Disconnection', colIdPrefix: 'disconnect' },
	{ field: 'blockedTime', headerPrefix: 'Blocked', colIdPrefix: 'blocked' }
];

const COLUMN_DEFS = [
	{
		headerName: 'ID',
		field: 'profile_id',
		filter: 'agNumberColumnFilter',
		hide: true
	},
	{
		headerName: 'Public ID',
		field: 'public_id',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Sales Navigator ID',
		field: 'sn_profile_id',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Status',
		field: 'status'
	},
	{
		headerName: 'Name',
		field: 'fullName',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Connections',
		field: 'numOfConnections',
		filter: 'agNumberColumnFilter'
	},
	{
		headerName: 'Location',
		field: 'location',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Industry',
		field: 'industry'
	},
	{
		headerName: 'Headline',
		field: 'headline',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Title',
		field: 'defaultPosition.title',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Summary',
		field: 'summary',
		filter: 'agTextColumnFilter',
		hide: true
	},
	{
		headerName: 'Memorialized',
		field: 'memorialized',
		hide: true
	},
	{
		headerName: 'Connection Delay',
		valueGetter: params => {
			if (!params.data || !params.data.connectionRequestTime || !params.data.connectionTime) {
				return;
			}
			return secondsToStr(params.data.connectionTime - params.data.connectionRequestTime);
		},
		hide: true,
		colId: 'connectDelay'
	},
	{
		headerName: 'Connection Campaign',
		field: 'connection_campaign_id',
		cellRenderer: 'connectionCampaignRenderer'
	},
	{
		headerName: 'Message Campaign',
		field: 'message_campaign_id',
		cellRenderer: 'messageCampaignRenderer'
	}
];

TIMESTAMP_FIELDS.forEach(element => {
	COLUMN_DEFS.push(
		{
			headerName: element.headerPrefix + ' Timestamp',
			field: element.field,
			filter: 'agNumberColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Date',
			colId: element.colIdPrefix + 'Date',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return timestampToFormattedDate(params.data[element.field]);
				}
			},
			filter: 'agDateColumnFilter',
			filterParams: {
				comparator: function(filterLocalDateAtMidnight, cellValue) {
					if (cellValue === null) return 0;

					var cellDate = getDateFromFormattedString(cellValue);

					if (cellDate < filterLocalDateAtMidnight) {
						return -1;
					} else if (cellDate > filterLocalDateAtMidnight) {
						return 1;
					} else {
						return 0;
					}
				},
				browserDatePicker: true
			}
		},
		{
			headerName: element.headerPrefix + ' Week',
			colId: element.colIdPrefix + 'DateWeek',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getWeekIndex(params.data[element.field]);
				}
			},
			filter: 'agNumberColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Day',
			colId: element.colIdPrefix + 'DateDay',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getDayOfWeek(params.data[element.field]);
				}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				values: DAYS_OF_WEEK,
				suppressSorting: true
			},
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Month',
			colId: element.colIdPrefix + 'DateMonth',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getMonth(params.data[element.field]);
				}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				values: MONTHS,
				suppressSorting: true
			},
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Year',
			colId: element.colIdPrefix + 'DateYear',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return new Date(params.data[element.field] * 1000).getUTCFullYear();
				}
			},
			filter: 'agNumberColumnFilter',
			hide: true
		}
	);
});

export { COLUMN_DEFS, DEFAULT_COLUMN_DEF };
