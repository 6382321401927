import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as Actions from "../../js/actions";
import Iframe from 'react-iframe'
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";
// import closeAccountPortal from "./closeAcountPortal"


const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        flexGrow: 1,
    };
}

class Portal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
    }

    componentDidMount() {
        this.props.retrieveChargeBeePortal(this.props.appData.userData.chargeBeeID);
    }

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.open}
                onClose={this.handleClose}
            >

                <Paper style={getModalStyle()} className={classes.paper}>

                    <Grid container alignItems={"center"} alignContent={"center"}>
                        <Grid item xs={11}>
                            <Typography style={{margin: 0}}>
                                To manage your subscription, select the product.
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <a href={"/"}>
                            <IconButton
                                aria-label="Delete Tag"
                                onClick={this.handleClose}
                            >
                                <Icon
                                    path={mdiClose}
                                    size={.7}
                                    color={'#666666'}
                                />
                            </IconButton>
                            </a>
                        </Grid>
                    </Grid>
                    {this.props.appData.chargeBeeConnect.access_url ?

                        <Iframe
                            url={this.props.appData.chargeBeeConnect.access_url}
                            height="550px"
                            position="relative"/>
                        :
                        <div>
                            <Typography
                                component="h1"
                                variant="h5"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                Loading portal data
                            </Typography>
                        </div>
                    }
                </Paper>
            </Modal>
        );
    }
}

const styles = theme => ({
    root: {
        width: '200px',
        overflowX: 'auto',
        paddingTop: 20,
    },
    paper: {
        position: 'absolute',
        maxWidth: theme.spacing.unit * 45,
        minHeight: theme.spacing.unit * 40,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
});

Portal.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        appData: state.appData
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveChargeBeePortal: (chargeBeeID) => dispatch(Actions.retrieveChargeBeePortal(chargeBeeID)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(withStyles(styles)(Portal));