import React, { useEffect, useState } from 'react';

export default props => {
	// Listen for changes to displayed data
	const [platformUniqueUserCounts, setPlatformUniqueUserCounts] = useState({});
	useEffect(() => {
		function handleVisibleDataChanged(params) {
			var uniqueUsersByPlatform = {};
			params.api.forEachLeafNode((node, index) => {
				if (node && node.data) {
					let platform = node.data['userDetail.platform'];
					if (!platform) {
						platform = 'null';
					}
					let userId = node.data['userDetail.userID'];
					if (!uniqueUsersByPlatform.hasOwnProperty(platform)) {
						uniqueUsersByPlatform[platform] = new Set();
					}
					uniqueUsersByPlatform[platform].add(userId);
				}
			});
			var platformUserCounts = { total: 0 };
			for (const [key, value] of Object.entries(uniqueUsersByPlatform)) {
				platformUserCounts[key] = value.size;
				platformUserCounts.total += value.size;
			}
			setPlatformUniqueUserCounts(platformUserCounts);
		}
		props.api.addEventListener('rowDataUpdated', handleVisibleDataChanged);
		props.api.addEventListener('filterChanged', handleVisibleDataChanged);
		return () => {
			props.api.removeEventListener('rowDataUpdated', handleVisibleDataChanged);
			props.api.removeEventListener('filterChanged', handleVisibleDataChanged);
		};
	}, [props.api]);

	let x = '';
	for (const [key, value] of Object.entries(platformUniqueUserCounts)) {
		x = x.concat(key);
		x = x.concat(':');
		x = x.concat(value);
	}

	return (
		<div className="ag-status-name-value">
			{Object.keys(platformUniqueUserCounts).map((key, i) => (
				<span key={i}>
					<span className="component">{key}:&nbsp;</span>
					<span className="ag-status-name-value-value">{platformUniqueUserCounts[key]}&nbsp;</span>
				</span>
			))}
		</div>
	);
};
