import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiSquareEditOutline } from '@mdi/js';
// import { mdiShieldRemove, mdiSquareEditOutline, mdiTrashCan } from '@mdi/js';
// import { ConfirmationRequiredButton } from '../../../../../widgets';
import { useActions } from '../../store';

function ProxyActionsRenderer(props) {
	let data = props.data
	if (!data) {
		if (props.node.allLeafChildren) {
			data = {
				proxy_id:props.node.allLeafChildren[0].proxy_id,
				host:props.node.allLeafChildren[0].host,
				lock_count:props.node.allLeafChildren[0].lock_count
			}
			data = props.node.allLeafChildren[0].data
			let good_data = props.node.allLeafChildren.every(el => {
				return el.data.proxy_id === data.proxy_id
			});
			if (!good_data) {
				data = {}
			}
		}
	}
	let {proxy_id, host, lock_count} = data

	const dispatch = useDispatch();
	const actions = useActions();

	function handleEdit(event) {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		dispatch(actions.proxies.displayEditFormProxy({ visible: true, proxy_id: proxy_id }));
	}

	// function handleDeleteProxy() {
	// 	dispatch(
	// 		actions.proxies.deleteProxy({
	// 			id: proxy_id,
	// 			proxy_id: proxy_id
	// 		})
	// 	);
	// }

	// function handleUnlockProxies() {
	// 	dispatch(
	// 		actions.proxies.unlockProxy({
	// 			id: proxy_id,
	// 			proxy_id: proxy_id
	// 		})
	// 	);
	// }

	if (proxy_id === undefined || host === undefined || lock_count === undefined) {
		return <div/>
	}

	return (
		<Grid container direction="row" alignItems="center" spacing={2}>
			<Grid xs={3} item>
				<Tooltip title={'Edit'}>
					<IconButton onClick={handleEdit}>
						<Icon path={mdiSquareEditOutline} size={0.75} />
					</IconButton>
				</Tooltip>
			</Grid>

			{/* <Grid xs={3} item>
				<ConfirmationRequiredButton
					onConfirm={handleDeleteProxy}
					text={"Delete Proxy '" + data.host + "'?"}
					tooltip="Delete"
					submitLabel="Delete"
					icon={mdiTrashCan}
				/>
			</Grid>
			<Grid xs={3} item>
				<ConfirmationRequiredButton
					onConfirm={handleUnlockProxies}
					text={'Assign new proxies for all ' + data.lock_count + ' profiles?'}
					tooltip="Reassign Profile Proxies"
					submitLabel="Reassign"
					icon={mdiShieldRemove}
					disabled={data.lock_count < 1}
				/>
			</Grid> */}
		</Grid>
	);
}

export default ProxyActionsRenderer;
