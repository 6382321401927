import React from 'react';
import clsx from 'clsx';
import Moment from 'react-moment';
import { makeStyles, Avatar, ListItem, ListItemText, ListItemAvatar, Typography } from '@material-ui/core';

import { grey, green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: '100%'
	},
	fromAccount: {
		backgroundColor: grey[100]
	},
	unread: {
		backgroundColor: green[100]
	}
}));

export default function PastEventDisplay({ event }) {
	const classes = useStyles();
	let event_ts = event.ts_ms / 1000;
	return (
		<ListItem
			alignItems="flex-start"
			className={clsx(classes.root, {
				[classes.fromAccount]: event.fromAccount,
				[classes.unread]: event.unread
			})}
		>
			<ListItemAvatar>
				<Avatar alt={event.displayName} src={event.profile.pictureUrl} />
			</ListItemAvatar>
			<ListItemText
				disableTypography={true}
				primary={event.displayName}
				secondary={
					<React.Fragment>
						{event.hasAttachments && (
							<Typography component="div" variant="caption" color="textPrimary">
								Attached file
							</Typography>
						)}
						{event.hasCustomContent && (
							<Typography component="div" variant="caption" color="textPrimary">
								Attached custom content
							</Typography>
						)}
						<Typography component="div" variant="body2" color="textPrimary">
							{event.text}
						</Typography>
						<Typography component="div" variant="caption" color="textPrimary">
							<Moment unix format="MM/DD/YY hh:mm:ss">
								{event_ts}
							</Moment>
							{'  -  '}
							<Moment unix fromNow>
								{event_ts}
							</Moment>
						</Typography>
					</React.Fragment>
				}
			/>
		</ListItem>
	);
}
