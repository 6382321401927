const COLUMN_DEFS = [
	// Displayed Columns
	{
		field: 'enabled',
		headerName: 'Enabled',
		cellRenderer: 'accountEnabledRenderer',
		pinned: 'left'
	},
	{
		field: 'status',
		headerName: 'Status',
		cellRenderer: 'accountStatusRenderer',
		filter: 'agSetColumnFilter',
		pinned: 'left'
	},
	{
		field: 'created',
		headerName: 'Created',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer'
	},
	{
		field: 'modified',
		headerName: 'Modified',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer'
	},
	{
		field: 'account_id',
		headerName: 'Account ID'
	},
	{
		field: 'li_username',
		headerName: 'Username',
		filter: 'agTextColumnFilter'
	},
	{
		field: 'proxy_host',
		headerName: 'Proxy Host'
	},
	{
		field: 'proxy_port',
		headerName: 'Proxy Port',
		hide: true
	},
	{
		field: 'owner_id',
		headerName: 'Customer ID',
		hide: true
	},
	{
		field: 'owner',
		headerName: 'Customer',
		filter: 'agTextColumnFilter'
	},
	{
		field: 'notes',
		headerName: 'Notes'
	},
	{
		field: 'account_id',
		headerName: 'Actions',
		filter: false,
		sort: false,
		cellRenderer: 'accountActionsRenderer',
		suppressSizeToFit: true,
		minWidth: 330,
		pinned: 'right'
	}
];

const DEFAULT_COLUMN_DEF = {
	// flex: 1,
	minWidth: 100,
	// allow every column to be aggregated
	enableValue: true,
	// allow every column to be grouped
	enableRowGroup: true,
	// allow every column to be pivoted
	sortable: true,
	filter: true,
	resizable: true,
	floatingFilter: true
};

export { COLUMN_DEFS, DEFAULT_COLUMN_DEF };
