import React, { Fragment } from "react";

import { Grid, Button } from '@material-ui/core';

export default function FormActions({ form, onClose, pristine, submitting }) {
    return (
        <Fragment>
            <Grid item style={{ marginTop: 16 }}>
                <Button
                    type="button"
                    variant="contained"
                    onClick={onClose}
                    disabled={submitting}
                >
                    Close
                </Button>
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
                <Button
                    type="button"
                    variant="contained"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                >
                    Reset
                </Button>
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting || pristine}
                >
                    Submit
                </Button>
            </Grid>
        </Fragment>
    );
}