import React from "react";
import { Field } from 'react-final-form';
import { Checkbox, TextField } from 'final-form-material-ui';
import { validators } from '../../../../widgets';

export function EnabledField(props) {
    return (
        <>
            <Field
                name="enabled"
                component={Checkbox}
                type="checkbox"
            />
            <label>Enabled</label>
        </>
    )
}

export function AccountIDField(props) {
    return (
        <Field
            name="account_id"
            component={TextField}
            type="number"
            label="LinkedIn User ID"
            validate={validators.all(validators.required, validators.isNumber)}
            fullWidth
            required
            {...props}
        />
    );
}

export function LinkedInUsernameField(props) {
    return (
        <Field
            name="li_username"
            component={TextField}
            type="text"
            label="LinkedIn Username"
            validate={validators.required}
            fullWidth
            required
            {...props}
        />
    );
}

export function LinkedInPasswordField(props) {
    return (
        <Field
            name="li_password"
            component={TextField}
            type="input"
            label="LinkedIn Password"
            validate={validators.required}
            fullWidth
            required
            {...props}
        />
    );
}

export function SavedSearchField(props) {
    return (
        <Field
            name="saved_search"
            component={TextField}
            type="text"
            label="Saved Search"
            fullWidth
            {...props}
        />
    );
}

export function CustomerIDField(props) {
    return (
        <Field
            name="owner_id"
            component={TextField}
            type="text"
            label="Customer ID"
            fullWidth
            {...props}
        />
    );
}

export function CustomerField(props) {
    return (
        <Field
            name="owner"
            component={TextField}
            type="text"
            label="Customer"
            fullWidth
            {...props}
        />
    );
}

export function ProxyHostField(props) {
    return (
        <Field
            name="proxy_host"
            component={TextField}
            type="text"
            label="Host"
            fullWidth
            {...props}
        />
    );
}

export function ProxyPortField(props) {
    return (
        <Field
            name="proxy_port"
            component={TextField}
            type="number"
            label="Port"
            validate={validators.portNumber}
            fullWidth
            {...props}
        />
    );
}

export function ProxyUsernameField(props) {
    return (
        <Field
            name="proxy_username"
            component={TextField}
            type="text"
            label="Username"
            fullWidth
            {...props}
        />
    );
}

export function ProxyPasswordField(props) {
    return (
        <Field
            name="proxy_password"
            component={TextField}
            type="input"
            label="Password"
            fullWidth
            {...props}
        />
    );
}

export function ConnectionMessageEnabledField(props) {
    return (
        <>
            <Field
                name="connection_message_enabled"
                component={Checkbox}
                type="checkbox"
            />
            <label>Enable Connection Message</label>
        </>
    )
}

export function ConnectionMessageField(props) {
    return (
        <Field
            fullWidth
            name="connection_message"
            component={TextField}
            label="Connection Message"
            multiline="true"
            {...props}
            />
    )
}
