import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { getFormattedDate, addDaysToDate } from './utils';
import REPORT_TYPES from './types';

//endregion Actions
const CAMPAIGN_API_HOST = 'https://wwcldhp8be.execute-api.us-east-1.amazonaws.com/dev';

const stage = process.env.REACT_APP_RUN_ENVIRONMENT;
const API_DEV = 'https://zfb7oytr6i.execute-api.us-east-2.amazonaws.com/dev';
const API_PROD = 'https://acfzedeub2.execute-api.us-east-1.amazonaws.com/dev';
const API_HOSTS = {
	'dev-original': API_DEV,
	dev: API_PROD, // normally dev
	qa: API_PROD, // normally dev
	prod: API_PROD
};
export const API_HOST = API_HOSTS[stage];

const FETCH_RECORD_LIMIT = 12;
const INITIAL_NUM_RECORDS = 28;

const INITIAL_STATE = {
	accountId: null,
	account: {},
	startDate: getFormattedDate(addDaysToDate(new Date(), -1 * INITIAL_NUM_RECORDS)),
	endDate: getFormattedDate(),
	fetchRecordLimit: FETCH_RECORD_LIMIT,
	fetchRequestTimestamp: 0,
	statusFlags: {
		rawJsonVisible: false,
		loadingData: false,
		fetchingAccountData: false,
		fetchingAccountActivity: false
	},
	reportType: REPORT_TYPES.statusCountByRequestDate.key,
	record_dict: {},
	records: []
};

//endregion Constants

//region Action Types

const ACTION_TYPE_PREFIX = 'LI:REPORTS:';

const SET_STATUS_FLAG = ACTION_TYPE_PREFIX + 'SET_STATUS_FLAG';
const SET_FETCH_RECORD_LIMIT = ACTION_TYPE_PREFIX + 'SET_FETCH_RECORD_LIMIT';
const SET_FETCH_REQUEST_TS = ACTION_TYPE_PREFIX + 'SET_FETCH_REQUEST_TS';
const SAVE_ACCOUNT_ID = ACTION_TYPE_PREFIX + 'SAVE_ACCOUNT_ID';
const SAVE_ACCOUNT_DATA = ACTION_TYPE_PREFIX + 'SAVE_ACCOUNT_DATA';
const SAVE_MESSAGE_CAMPAIGNS = ACTION_TYPE_PREFIX + 'SAVE_MESSAGE_CAMPAIGNS';
const SAVE_CONNECTION_CAMPAIGNS = ACTION_TYPE_PREFIX + 'SAVE_CONNECTION_CAMPAIGNS';
const SAVE_DATE_RANGE = ACTION_TYPE_PREFIX + 'SAVE_DATE_RANGE';
const SAVE_RECORDS = ACTION_TYPE_PREFIX + 'SAVE_RECORDS';
const CLEAR_RECORDS = ACTION_TYPE_PREFIX + 'CLEAR_RECORDS';
const SAVE_INTERACTIONS = ACTION_TYPE_PREFIX + 'SAVE_INTERACTIONS';
const SET_REPORT_TYPE = ACTION_TYPE_PREFIX + 'SET_REPORT_TYPE';

//endregion Action Types

//region Actions - Account Data Management

/** Sets the value of a status flag. */
function setStatusFlag(flag, value) {
	return {
		type: SET_STATUS_FLAG,
		flag: flag,
		value: value
	};
}

function setFetchRecordLimit(value) {
	return {
		type: SET_FETCH_RECORD_LIMIT,
		fetchRecordLimit: value
	};
}

function setFetchRequestTimestamp(value) {
	return {
		type: SET_FETCH_REQUEST_TS,
		fetchRequestTimestamp: value
	};
}

/** Sets the new account ID, resets dates and data, and triggers new load. */
function initializeWithAccountID(accountId) {
	return (dispatch, getState) => {
		// Set Account ID
		let cachedAccountId = getState().accountId;
		if (cachedAccountId === accountId) return;
		dispatch(setAccountId(accountId));

		// Fetch records
		let startDate = getState().startDate;
		let endDate = getState().endDate;
		dispatch(fetchRecordsForDateRange(startDate, endDate));
	};
}

function refreshRecordForDateRange(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(fetchAccountInfo());
		dispatch(fetchMessageCampaigns());
		dispatch(fetchConnectionCampaigns());
		dispatch(fetchRecordsForDateRange(startDate, endDate));
	};
}

/** Sets the new account ID and triggers data load */
function setAccountId(accountId) {
	return (dispatch, getState) => {
		let cachedAccountId = { ...getState().accountId };
		if (cachedAccountId === accountId) return;
		dispatch(saveAccountId(accountId));
		dispatch(fetchAccountInfo());
		dispatch(fetchMessageCampaigns());
		dispatch(fetchConnectionCampaigns());
	};
}

/** Triggers the account ID to be saved in the reducer. */
function saveAccountId(accountId) {
	return {
		type: SAVE_ACCOUNT_ID,
		accountId: accountId
	};
}

/** Fetches account data from the server. */
function fetchAccountInfo() {
	return (dispatch, getState) => {
		let { accountId } = { ...getState() };
		const url = API_HOST + `/accounts/${accountId}`;

		dispatch(setStatusFlag('fetchingAccountData', true));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				dispatch(saveAccountData(data));
				dispatch(setStatusFlag('fetchingAccountData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingAccountData', 'error'));
			});
	};
}

/** Triggers the account Data to be saved in the reducer. */
function saveAccountData(accountData) {
	return {
		type: SAVE_ACCOUNT_DATA,
		accountData: accountData
	};
}

function fetchMessageCampaigns() {
	return (dispatch, getState) => {
		let { accountId } = { ...getState() };
		const url = CAMPAIGN_API_HOST + `/message-campaigns?account_id=${accountId}`;

		dispatch(setStatusFlag('fetchingMessageCampaigns', true));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				dispatch(saveMessageCampaigns(data));
				dispatch(setStatusFlag('fetchingMessageCampaigns', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingMessageCampaigns', 'error'));
			});
	};
}

function saveMessageCampaigns(data) {
	return {
		type: SAVE_MESSAGE_CAMPAIGNS,
		data: data
	};
}

function fetchConnectionCampaigns() {
	return (dispatch, getState) => {
		let { accountId } = { ...getState() };
		const url = CAMPAIGN_API_HOST + `/connection-campaigns?account_id=${accountId}`;

		dispatch(setStatusFlag('fetchingConnectionCampaigns', true));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				dispatch(saveConnectionCampaigns(data));
				dispatch(setStatusFlag('fetchingConnectionCampaigns', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingConnectionCampaigns', 'error'));
			});
	};
}

function saveConnectionCampaigns(data) {
	return {
		type: SAVE_CONNECTION_CAMPAIGNS,
		data: data
	};
}

function setDateRange(start, end) {
	return (dispatch, getState) => {
		// TODO clear out old data
		let startDate = getFormattedDate(new Date(start));
		let endDate = getFormattedDate(new Date(end));

		dispatch(saveDateRange(startDate, endDate));
		dispatch(fetchRecordsForDateRange(startDate, endDate));
	};
}

function saveDateRange(start, end) {
	return {
		type: SAVE_DATE_RANGE,
		start: start,
		end: end
	};
}

function fetchRecordsForDateRange(start, end) {
	return (dispatch, getState) => {
		const ts = new Date().getTime();
		dispatch(setFetchRequestTimestamp(ts));
		dispatch(clearRecords());
		dispatch(setStatusFlag('loadingData', true));
		dispatch(fetchPageOfRecordsForDateRange(start, end, ts));
	};
}

function fetchPageOfRecordsForDateRange(start, end, ts) {
	return (dispatch, getState) => {
		let { accountId, fetchRecordLimit, fetchRequestTimestamp } = {
			...getState()
		};

		// Cancel request if newer request has been made
		if (ts !== fetchRequestTimestamp) {
			return;
		}

		const url =
			API_HOST +
			`/account/activity/${accountId}?view=profiles&start=${start}&end=${end}&limit=${fetchRecordLimit}`;
		dispatch(setStatusFlag('fetchingAccountActivity', true));
		fetch(url)
			.then(res => res.json())
			.then(raw_data => {
				// throw out returned records if newer request has been made
				let { fetchRequestTimestamp } = { ...getState() };
				if (ts !== fetchRequestTimestamp) {
					return;
				}

				// save records
				dispatch(saveRecords(raw_data.data));
				dispatch(setStatusFlag('fetchingAccountActivity', false));

				// If raw_data.more === true, then fetch more records
				if (raw_data.more === true) {
					let new_end_date = addDaysToDate(new Date(end), -fetchRecordLimit);
					let new_end = getFormattedDate(new_end_date);
					setTimeout(() => {
						dispatch(fetchPageOfRecordsForDateRange(start, new_end, ts));
					}, 250);
				} else {
					dispatch(setStatusFlag('loadingData', false));
				}
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('loadingData', false));
				dispatch(setStatusFlag('fetchingAccountActivity', 'error'));
			});
	};
}

function saveRecords(records) {
	return {
		type: SAVE_RECORDS,
		records: records
	};
}

function clearRecords() {
	return {
		type: CLEAR_RECORDS
	};
}

/** Fetches account data from the server. */
function fetchInteractions(profileId) {
	return (dispatch, getState) => {
		let { accountId } = { ...getState() };
		const url = API_HOST + `/account/interactions/${accountId}/${profileId}`;
		dispatch(setStatusFlag('fetchingProfileData', profileId));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				dispatch(saveProfileInteractions(profileId, data.data));
				dispatch(setStatusFlag('fetchingProfileData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingProfileData', 'error'));
			});
	};
}

function saveProfileInteractions(profileId, interactions) {
	return {
		type: SAVE_INTERACTIONS,
		profileId: profileId,
		interactions: interactions
	};
}

function setReportType(value) {
	return {
		type: SET_REPORT_TYPE,
		reportType: value
	};
}

// Action Dispatching...

function useLiDashboardActions() {
	return {
		setStatusFlag: setStatusFlag,
		initializeWithAccountID: initializeWithAccountID,
		refreshRecordForDateRange: refreshRecordForDateRange,
		setDateRange: setDateRange,
		setFetchRecordLimit: setFetchRecordLimit,
		fetchInteractions: fetchInteractions,
		setReportType: setReportType
	};
}

//endregion Actions

//region Reducer

function updateRecords(state, records) {
	// TODO: handle duplicate records?
	records.forEach(record => {
		// RECORD_SUM_TYPES.forEach(key => {
		// 	let count_key = 'count-' + key;
		// 	if (key in record) {
		// 		record[count_key] = Object.keys(record[key]).length;
		// 	} else {
		// 		record[count_key] = 0;
		// 	}
		// });
		state.record_dict[record.profile_id] = record;
	});
	state.records = Object.values(state.record_dict);
}

function updateInteractions(state, profileId, interactions) {
	state.record_dict[profileId]['interactions'] = interactions;
	state.records = Object.values(state.record_dict);
}

function reducer(state = INITIAL_STATE, action) {
	if (action.type.startsWith(ACTION_TYPE_PREFIX)) {
		let newState = { ...state };
		switch (action.type) {
			case SET_STATUS_FLAG:
				newState.statusFlags[action.flag] = action.value;
				break;
			case SET_FETCH_RECORD_LIMIT:
				newState.fetchRecordLimit = action.fetchRecordLimit;
				break;
			case SET_FETCH_REQUEST_TS:
				newState.fetchRequestTimestamp = action.fetchRequestTimestamp;
				break;
			case SAVE_ACCOUNT_ID:
				newState.accountId = action.accountId;
				break;
			case SAVE_ACCOUNT_DATA:
				newState.account = action.accountData;
				break;
			case SAVE_MESSAGE_CAMPAIGNS:
				newState.message_campaigns = action.data;
				break;
			case SAVE_CONNECTION_CAMPAIGNS:
				newState.connection_campaigns = action.data;
				break;
			case SAVE_DATE_RANGE:
				newState.startDate = action.start;
				newState.endDate = action.end;
				break;
			case SAVE_RECORDS:
				updateRecords(newState, action.records);
				break;
			case CLEAR_RECORDS:
				newState.records = [];
				newState.record_dict = {};
				break;
			case SAVE_INTERACTIONS:
				updateInteractions(newState, action.profileId, action.interactions);
				break;
			case SET_REPORT_TYPE:
				newState.reportType = action.reportType;
				break;
			default:
				console.log('LI DASH Event Type not handled!', action);
		}
		return newState;
	}

	return state;
}

//endregion Reducer

//region Export

const store = createStore(reducer, applyMiddleware(thunk));
export { reducer, store, useLiDashboardActions };

//endregion Export
