import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@mdi/react';
import { grey } from '@material-ui/core/colors';

// TODO: remove direct color assignment in favor of themes / classes
const GREY_LIGHT = grey[300];
const GREY = grey[700];

export function TooltipButton({
    classes, tooltip, icon, iconSize = 0.7, color, disabled, rotate = 0, onClick
}) {
    classes = classes || withStyles();
    color = (disabled ? GREY_LIGHT : (color || GREY));
    if (disabled) {
        return (
            <IconButton className={classes.button} disabled={true}>
                <Icon path={icon} size={iconSize} color={color} rotate={rotate} />
            </IconButton>
        )
    } else {
        return (
            <Tooltip title={tooltip}>
                <IconButton className={classes.button} onClick={onClick}>
                    <Icon path={icon} size={iconSize} color={color} rotate={rotate} />
                </IconButton>
            </Tooltip>
        )
    }
}

export default TooltipButton;