import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Divider, List } from '@material-ui/core';
import { default as ConversationSummary } from './conversationSummary';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		overflow: 'auto'
	}
}));

export default function ConversationList() {
	const classes = useStyles();
	const selectedMessagingID = useSelector(state => state.selectedMessagingID);
	const messagingList = useSelector(state => state.messagingList);

	return (
		<List className={classes.root}>
			{messagingList
				.sort((a, b) => {
					const unreadA = a.read ? 1 : 0;
					const unreadB = b.read ? 1 : 0;
					if (unreadA !== unreadB) {
						return unreadA - unreadB;
					}
					return b.last_activity_ts_ms - a.last_activity_ts_ms;
				})
				.map((conversation, index) => (
					<Fragment key={index}>
						{index !== 0 && <Divider key={'divider' + index} component="li" />}
						<ConversationSummary
							key={'conversation' + index}
							data={conversation}
							selected={conversation.account_profile_id === selectedMessagingID}
						/>
					</Fragment>
				))}
		</List>
	);
}
