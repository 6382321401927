import React from 'react';
import { Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiHelpCircle, mdiLock, mdiLockOutline, mdiLockOpenVariantOutline } from '@mdi/js';
import { grey, green, red } from '@material-ui/core/colors';

const DEFAULT_ICON = mdiHelpCircle;
const ICONS = {
	true: mdiLock,
	false: mdiLockOpenVariantOutline,
	unassigned: mdiLockOutline,
};

const DEFAULT_ICON_COLOR = red[900];
const ICON_COLORS = {
	true: green[500],
	false: red[500],
	unassigned: grey[500],
};

function ProxyLockStatusRenderer(props) {
	let data = props.data
	if (!data) {
		data = {}
	}
	let value = data.locked
	if (value === undefined) {
		return <div/>
	}

	const icon = ICONS[value] || DEFAULT_ICON;
	const iconColor = ICON_COLORS[value] || DEFAULT_ICON_COLOR;
	return (
		<Tooltip title={`${value}`}>
			<Icon path={icon} size={0.75} color={iconColor} />
		</Tooltip>
	);
}

export default ProxyLockStatusRenderer;
