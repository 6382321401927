import React from "react";
import {ForgotPassword, RequireNewPassword, SignIn, SignUp} from 'aws-amplify-react';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import PoundTheme from '../../theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Auth} from 'aws-amplify';


class SignInFormComp extends SignIn {
    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn"];
    }

    showComponent(theme) {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={PoundTheme}>
                <main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classNames(classes.paper)}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in to Pound Social
                        </Typography>
                        <Typography>
                            {this.state.loginFormMessage}
                        </Typography>
                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="username">Email Address</InputLabel>
                                <Input id="username" name="username" autoComplete="username"
                                       onChange={this.handleInputChange} autoFocus/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input name="password" type="password" id="password"
                                       onChange={this.handleInputChange} autoComplete="current-password"/>
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox value="remember" color="secondary"/>}
                                label="Remember me"
                            />
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                onClick={() => super.signIn()}
                            >
                                Sign in
                            </Button>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={() => super.changeState("forgotPassword")}
                            >
                                Forgot Password
                            </Button>
                        </form>
                        <a href="https://www.pound.social"
                           style={{textDecoration: 'none', width: '100%'}}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.button}

                            >
                                I need an account
                            </Button>
                        </a>
                    </Paper>
                </main>
            </MuiThemeProvider>
        )
    }
}

class SignUpFormComp extends SignUp {
    constructor(props) {
        super(props);
        this._validAuthStates = ["signUp"];
    }

    showComponent(theme) {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={PoundTheme}>
                <main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classNames(classes.paper)}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Add a Password to Complete your Pound Social account setup
                        </Typography>
                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="username">Email Address</InputLabel>
                                <Input id="username" name="username" autoComplete="username"
                                       onChange={this.handleInputChange} autoFocus/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input name="password" type="password" id="password"
                                       onChange={this.handleInputChange}/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password2">Verrify Password</InputLabel>
                                <Input name="password" type="password2" id="password2"
                                       onChange={this.handleInputChange}/>
                            </FormControl>

                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                onClick={() => super.signIn()}
                            >
                                Complete
                            </Button>
                        </form>
                    </Paper>
                </main>
            </MuiThemeProvider>
        )
    }
}


class NewPasswordFormComp extends RequireNewPassword {
    constructor(props) {
        super(props);
        this._validAuthStates = ["requireNewPassword"];
    }
    showComponent(theme) {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={PoundTheme}>
                <main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classNames(classes.paper)}>
                        <Typography component="h1" variant="h5">
                            You must enter a new password
                        </Typography>
                        <form className={classes.form} onSubmit={(event) => {
                            this.processLogin(event)
                        }}>

                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input name="password" type="password" id="password"
                                       onChange={this.handleInputChange} autoComplete="current-password"/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password2">Verrify Password</InputLabel>
                                <Input name="password2" type="password" id="password2"
                                       onChange={this.handleInputChange} autoComplete="current-password"/>
                            </FormControl>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                onClick={() => super.completeNewPassword()}
                            >
                                Submit New Password
                            </Button>

                        </form>
                    </Paper>
                </main>
            </MuiThemeProvider>
        )
    }
}

class ForgotPasswordFormComp extends ForgotPassword {
    constructor(props) {
        super(props);
        this._validAuthStates = ["forgotPassword"];
        this.state ={
            isSendingCode: false,
            isConfirming: false,
            codeSent: false,
            email: '',
            code: '',
            password: '',

        }
    }
    SendCodeClick () {
        this.setState({ isSendingCode: true });
        try {
            Auth.forgotPassword(this.state.email);
            this.setState({ codeSent: true });
        } catch (e) {
            alert(e.message);
            this.setState({ isSendingCode: false });
        }
    };
    ConfirmClick= async () => {
        this.setState({ isConfirming: true });
        try {
            await Auth.forgotPasswordSubmit(
                this.state.email,
                this.state.code,
                this.state.password
            );
            this.setState({ confirmed: true });
        } catch (e) {
            alert(e.message);
            this.setState({ isConfirming: false });
        }
    };
    renderWait(){
        const { classes } = this.props;
        return(
            <MuiThemeProvider theme={PoundTheme}>
                <main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classNames(classes.paper)}>
                        <Typography component="h1" variant="h5">
                            Waiting for server response
                        </Typography>
                    </Paper>
                </main>
            </MuiThemeProvider>
        )
    }
    renderForgotPassword(){
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={PoundTheme}>
                <main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classNames(classes.paper)}>
                        <Typography component="h1" variant="h5">
                            Enter the Email address of the account, and a password reset code will be sent
                        </Typography>
                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="username">Email Address</InputLabel>
                                <Input id="username" name="username" autoComplete="username"
                                       onChange={this.handleInputChange} autoFocus/>
                            </FormControl>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                onClick={() => {this.SendCodeClick()}}
                            >
                                Reset Password
                            </Button>
                        </form>
                    </Paper>
                </main>
            </MuiThemeProvider>
        )
    }

    renderCodeForm(){
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={PoundTheme}>
                <main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classNames(classes.paper)}>
                        <Typography component="h1" variant="h5">
                            Enter The code sent to the account Email, along with the new password
                        </Typography>
                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="code">Code</InputLabel>
                                <Input id="code" name="code" autoComplete="code"
                                       onChange={this.handleInputChange} autoFocus/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input name="password" type="password" id="password"
                                       onChange={this.handleInputChange} autoComplete="current-password"/>
                            </FormControl>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                                onClick={() => {Auth.forgotPassword()}}
                            >
                                Reset Password
                            </Button>

                        </form>
                    </Paper>
                </main>
            </MuiThemeProvider>
        )
    }
    showComponent(theme) {
        return this.renderForgotPassword();


    }
}

const styles = PoundTheme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: PoundTheme.spacing.unit * 3,
        marginRight: PoundTheme.spacing.unit * 3,
        [PoundTheme.breakpoints.up(400 + PoundTheme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: PoundTheme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${PoundTheme.spacing.unit * 2}px ${PoundTheme.spacing.unit * 3}px ${PoundTheme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: PoundTheme.spacing.unit,
        backgroundColor: "#FF2951",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: PoundTheme.spacing.unit,
    },
    submit: {
        marginTop: PoundTheme.spacing.unit * 3,
        marginBottom: PoundTheme.spacing.unit
    },
    button: {
        marginBottom: PoundTheme.spacing.unit
    }
});

const SignInForm = withStyles(styles)(SignInFormComp);
const NewPasswordForm = withStyles(styles)(NewPasswordFormComp);
const ForgotPasswordForm = withStyles(styles)(ForgotPasswordFormComp);
const SignUpForm = withStyles(styles)(SignUpFormComp);

export {SignInForm, NewPasswordForm, ForgotPasswordForm, SignUpForm };