import React, { Fragment } from 'react';
import TooltipButton from './TooltipButton';
import ConfirmationDialog from './ConfirmationDialog';
import { useConfirmation } from './useConfirmation';
import { IconButton } from '@material-ui/core';

/**
 * Adds a ConfirmationDialog to a TooltipButton
 */
export function ConfirmationRequiredButton({
	children,
	classes,
	onConfirm,
	onCancel,
	title,
	text,
	confirmLabel,
	cancelLabel,
	tooltip,
	icon,
	iconSize = 0.7,
	iconColor,
	iconRotate,
	disabled = false,
	childrenComponent,
	iconButton
}) {
	const [open, onClick, onCancelWrap, onConfirmWrap] = useConfirmation(
		onConfirm,
		onCancel
	);
	return (
		<Fragment>
			<ConfirmationDialog
				title={title}
				text={text}
				open={open && !disabled}
				onClose={onCancelWrap}
				onConfirm={onConfirmWrap}
				confirmLabel={confirmLabel}
				cancelLabel={cancelLabel}
			>
				{childrenComponent}
			</ConfirmationDialog>
			{iconButton ? (
				<IconButton
					color={iconColor}
					disabled={disabled}
					rotate={iconRotate}
					onClick={onClick}
				>
					{iconButton}
				</IconButton>
			) : (
				<TooltipButton
					classes={classes}
					tooltip={tooltip}
					icon={icon}
					iconSize={iconSize}
					color={iconColor}
					disabled={disabled}
					rotate={iconRotate}
					onClick={onClick}
				/>
			)}
		</Fragment>
	);
}

export default ConfirmationRequiredButton;
