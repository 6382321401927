const COLUMN_DEFS = [
	// Proxy Properties
	{
		field: 'type',
		headerName: 'Proxy Type',
		cellRenderer: 'proxyTypeRenderer',
		filter: 'agSetColumnFilter',
		pinned: 'left'
	},
	{
		field: 'status',
		headerName: 'Proxy Status',
		cellRenderer: 'proxyStatusRenderer',
		filter: 'agSetColumnFilter',
		pinned: 'left'
	},
	{
		field: 'created',
		headerName: 'Proxy Created',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer'
	},
	{
		field: 'modified',
		headerName: 'Proxy Modified',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer',
		sort: 'desc'
	},
	{
		field: 'last_use',
		headerName: 'Proxy Last Use',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer',
		hide: true
	},
	{
		field: 'proxy_id',
		headerName: 'Proxy ID',
		hide: true
	},
	{
		field: 'host',
		headerName: 'Proxy Host',
		filter: 'agTextColumnFilter'
	},
	{
		field: 'port',
		headerName: 'Proxy Port',
		filter: 'agSetColumnFilter',
		hide: true
	},
	{
		field: 'username',
		headerName: 'Proxy Username',
		filter: 'agTextColumnFilter',
		hide: true
	},
	{
		field: 'password',
		headerName: 'Proxy Password',
		filter: 'agTextColumnFilter',
		hide: true
	},
	{
		field: 'source',
		headerName: 'Proxy Source',
		filter: 'agSetColumnFilter',
		hide: true
	},
	{
		field: 'type_status',
		headerName: 'Proxy Host',
		filter: 'agSetColumnFilter',
		hide: true
	},
	{
		field: 'lock_count',
		headerName: 'Active Lock Count',
		filter: 'agNumberColumnFilter'
	},
	{
		field: 'locked_count',
		headerName: 'Total Lock Count',
		filter: 'agNumberColumnFilter'
	},
	{
		field: 'lock_summary',
		headerName: 'Proxy Lock Summary',
		hide: true
	},
	{
		field: 'proxy_id',
		headerName: 'Proxy Actions',
		filter: false,
		sort: false,
		cellRenderer: 'proxyActionsRenderer',
		suppressSizeToFit: true,
		minWidth: 60,
		pinned: 'right'
	},

	// Lock Properties
	{
		field: 'locked',
		headerName: 'Locked',
		cellRenderer: 'proxyLockStatusRenderer',
		filter: 'agSetColumnFilter',
	},
	{
		field: 'account_type',
		headerName: 'Lock Type',
		cellRenderer: 'proxyLockTypeRenderer',
		filter: 'agSetColumnFilter',
	},
	{
		field: 'account_id',
		headerName: 'Lock Account ID',
		// cellRenderer: 'proxyTypeRenderer',
		filter: 'agTextColumnFilter'
	},
	{
		field: 'lock_username',
		headerName: 'Lock Username',
		// cellRenderer: 'proxyTypeRenderer',
		filter: 'agTextColumnFilter'
	},
	{
		field: 'locked_ts',
		headerName: 'Lock Time',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer'
	},
	{
		field: 'unlocked_ts',
		headerName: 'Unlock Time',
		filter: 'agDateColumnFilter',
		filterParams: {
			comparator: function(filterLocalDateAtMidnight, cellValue) {
				var dateAsTimestamp = cellValue;
				if (dateAsTimestamp === null) return -1;
				var cellDate = new Date(dateAsTimestamp * 1000);
				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0;
				}
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}
				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				console.log(cellValue);
			},
			browserDatePicker: true
		},
		cellRenderer: 'timestampRenderer'
	},

];

const DEFAULT_COLUMN_DEF = {
	// flex: 1,
	minWidth: 100,
	// allow every column to be aggregated
	enableValue: true,
	// allow every column to be grouped
	enableRowGroup: true,
	// allow every column to be pivoted
	sortable: true,
	filter: true,
	resizable: true,
	floatingFilter: true
};

export { COLUMN_DEFS, DEFAULT_COLUMN_DEF };
