import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		backgroundColor: '#33691e'
	}
}));

export default function TestPage() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<span>Hello Dashboard</span>
		</div>
	);
}
