import React from 'react';

/**
 * Displays data as formatted JSON.
 */
export function JsonDisplay({ data=null, replacer=null, space=2, visible=true }) {
    if (visible && data) {
        return (
            <div><pre>{JSON.stringify(data, replacer, space) }</pre></div>
        );
    } else {
        return(null);
    }
}

 export default JsonDisplay;