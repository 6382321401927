import _ from 'lodash';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { actions as reportActions, reportReducer } from './reports';

//endregion Actions
const stage = process.env.REACT_APP_RUN_ENVIRONMENT;
const API_DEV = 'https://jdawlvb411.execute-api.us-east-1.amazonaws.com/dev';
const API_PROD = 'https://jdawlvb411.execute-api.us-east-1.amazonaws.com/dev';
const API_HOSTS = {
	local: API_DEV,
	dev: API_PROD, // normally dev
	qa: API_PROD, // normally dev
	prod: API_PROD
};
export const API_HOST = API_HOSTS[stage];

const FETCH_RECORD_LIMIT = 5000;

const REPORT_TEMPLATE = {
	gridState: null,
	id: null,
	title: null,
	type: 'app-events',
	update_ts: 0
};

const INITIAL_STATE = {
	startTime: 0,
	endTime: 0,
	timeframe: null,
	fetchRecordLimit: FETCH_RECORD_LIMIT,
	fetchRequestTimestamp: 0,
	statusFlags: {
		loadingData: false,
		fetchingEvents: false,
		applyingReportDef: false
	},
	reportToLoad: null,
	reportDef: { ...REPORT_TEMPLATE },
	reportModified: false,
	record_dict: {},
	records: []
};

const TIMEFRAME_DEFS = {
	today: {
		label: 'Today',
		fnc: () => {
			let now = new Date();
			let now_ts = now.getTime();
			let tz_offset_s = now.getTimezoneOffset() * 60;
			let tz_offset_h = now.getTimezoneOffset() / 60;
			let end = Math.floor((now_ts / 1000 / 60 / 60 - tz_offset_h) / 24 + 1) * 60 * 60 * 24 + tz_offset_s;
			let start = end - 60 * 60 * 24;
			return [start, end];
		}
	},
	yesterday: {
		label: 'Yesterday',
		fnc: () => {
			let now = new Date();
			let now_ts = now.getTime();
			let tz_offset_s = now.getTimezoneOffset() * 60;
			let tz_offset_h = now.getTimezoneOffset() / 60;
			let end = Math.floor((now_ts / 1000 / 60 / 60 - tz_offset_h) / 24) * 60 * 60 * 24 + tz_offset_s;
			let start = end - 60 * 60 * 24;
			return [start, end];
		}
	},
	'7days': {
		label: 'Last 7 Days',
		fnc: () => {
			let now = new Date();
			let now_ts = now.getTime();
			let tz_offset_s = now.getTimezoneOffset() * 60;
			let tz_offset_h = now.getTimezoneOffset() / 60;
			let end = Math.floor((now_ts / 1000 / 60 / 60 - tz_offset_h) / 24 + 1) * 60 * 60 * 24 + tz_offset_s;
			let start = end - 60 * 60 * 24 * 7;
			return [start, end];
		}
	},
	'14days': {
		label: 'Last 14 Days',
		fnc: () => {
			let now = new Date();
			let now_ts = now.getTime();
			let tz_offset_s = now.getTimezoneOffset() * 60;
			let tz_offset_h = now.getTimezoneOffset() / 60;
			let end = Math.floor((now_ts / 1000 / 60 / 60 - tz_offset_h) / 24 + 1) * 60 * 60 * 24 + tz_offset_s;
			let start = end - 60 * 60 * 24 * 14;
			return [start, end];
		}
	},
	'30days': {
		label: 'Last 30 Days',
		fnc: () => {
			let now = new Date();
			let now_ts = now.getTime();
			let tz_offset_s = now.getTimezoneOffset() * 60;
			let tz_offset_h = now.getTimezoneOffset() / 60;
			let end = Math.floor((now_ts / 1000 / 60 / 60 - tz_offset_h) / 24 + 1) * 60 * 60 * 24 + tz_offset_s;
			let start = end - 60 * 60 * 24 * 30;
			return [start, end];
		}
	},
	hour: {
		label: 'Last Hour',
		fnc: () => {
			let now = new Date();
			let now_ts = now.getTime();
			let end = Math.floor(now_ts / 1000);
			let start = end - 60 * 60;
			return [start, end];
		}
	},
	'12hours': {
		label: 'Last 12 Hours',
		fnc: () => {
			let now = new Date();
			let now_ts = now.getTime();
			let end = Math.floor(now_ts / 1000);
			let start = end - 60 * 60 * 12;
			return [start, end];
		}
	}
};
const TIMEFRAMES = Object.keys(TIMEFRAME_DEFS);

//endregion Constants

//region Action Types

const ACTION_TYPE_PREFIX = 'HASHTAGR:APP-EVENTS:';

const SET_STATUS_FLAG = ACTION_TYPE_PREFIX + 'SET_STATUS_FLAG';
const SET_FETCH_REQUEST_TS = ACTION_TYPE_PREFIX + 'SET_FETCH_REQUEST_TS';
const SET_FETCH_RECORD_LIMIT = ACTION_TYPE_PREFIX + 'SET_FETCH_RECORD_LIMIT';
const SAVE_RECORDS = ACTION_TYPE_PREFIX + 'SAVE_RECORDS';
const CLEAR_RECORDS = ACTION_TYPE_PREFIX + 'CLEAR_RECORDS';
const SET_DATETIME_RANGE = ACTION_TYPE_PREFIX + 'SET_DATETIME_RANGE';
const SET_REPORT_BY_ID = ACTION_TYPE_PREFIX + 'SET_REPORT_BY_ID';
const SET_REPORT_GRID_STATE = ACTION_TYPE_PREFIX + 'SET_REPORT_GRID_STATE';
const CHANGE_REPORT_TITLE = ACTION_TYPE_PREFIX + 'CHANGE_REPORT_TITLE';
const CHANGE_REPORT_ID = ACTION_TYPE_PREFIX + 'CHANGE_REPORT_ID';
const SET_TIMEFRAME = ACTION_TYPE_PREFIX + 'SET_TIMEFRAME';
const RECALCULATE_START_END_TIMES = ACTION_TYPE_PREFIX + 'RECALCULATE_START_END_TIMES';

//endregion Action Types

/** Sets the value of a status flag. */
function setStatusFlag(flag, value) {
	return {
		type: SET_STATUS_FLAG,
		flag: flag,
		value: value
	};
}

function setFetchRecordLimit(value) {
	return {
		type: SET_FETCH_RECORD_LIMIT,
		fetchRecordLimit: value
	};
}

function setFetchRequestTimestamp(value) {
	return {
		type: SET_FETCH_REQUEST_TS,
		fetchRequestTimestamp: value
	};
}

function setDatetimeRange(start, end) {
	return {
		type: SET_DATETIME_RANGE,
		start: start,
		end: end
	};
}

function changeDateTimeRange(start, end) {
	return (dispatch, getState) => {
		dispatch(setDatetimeRange(start, end));
		dispatch(fetchRecordsForCurrentDatetimeRange());
	};
}

function fetchRecordsForCurrentTimeframe() {
	return (dispatch, getState) => {
		dispatch(recalculateStartEndTimes());
		dispatch(fetchRecordsForCurrentDatetimeRange());
	};
}

function fetchRecordsForCurrentDatetimeRange() {
	return (dispatch, getState) => {
		const ts = new Date().getTime();
		dispatch(setFetchRequestTimestamp(ts));
		dispatch(clearRecords());
		dispatch(setStatusFlag('loadingData', true));

		let start = getState().events.startTime;
		let end = getState().events.endTime;

		dispatch(fetchRecordsForTimePeriod(start, end, ts));
	};
}

function fetchRecordsForTimePeriod(after, before, ts) {
	return (dispatch, getState) => {
		let { fetchRecordLimit, fetchRequestTimestamp } = {
			...getState().events
		};

		// Cancel request if newer request has been made
		if (ts !== fetchRequestTimestamp) {
			return;
		}

		const url = API_HOST + `/events?after=${after}&before=${before}&limit=${fetchRecordLimit}`;
		dispatch(setStatusFlag('fetchingEvents', true));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				// throw out returned records if newer request has been made
				let { fetchRequestTimestamp } = { ...getState().events };
				if (ts !== fetchRequestTimestamp) {
					return;
				}

				// build user data map
				let users = {};
				data.users.forEach(user => {
					users[user.uuid] = user;
				});

				// save records
				let records = data.records;
				records.forEach(record => {
					let user = users[record['userDetail.userID']];
					if (user) {
						record['user.exists'] = true;
						record['user.email'] = user.email;
						record['user.code'] = user.code;
						record['user.expire'] = user.expire;
						record['user.firstname'] = user.firstname;
						record['user.lastname'] = user.lastname;
						record['user.instagramuser'] = user.instagramuser;
						record['user.language'] = user.language;
						record['user.promocode'] = user.promocode;
					}
				});

				dispatch(saveRecords(records));
				dispatch(setStatusFlag('fetchingEvents', false));

				// If data.more === true, then fetch more records
				if (data.more === true && records.length > 0) {
					let new_before = records[records.length - 1].time - 0.00000001;
					setTimeout(() => {
						dispatch(fetchRecordsForTimePeriod(after, new_before, ts));
					}, 250);
				} else {
					dispatch(setStatusFlag('loadingData', false));
				}
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('loadingData', false));
				dispatch(setStatusFlag('fetchingEvents', 'error'));
			});
	};
}

function saveRecords(records) {
	return {
		type: SAVE_RECORDS,
		records: records
	};
}

function clearRecords() {
	return {
		type: CLEAR_RECORDS
	};
}

function setReportById(value) {
	return {
		type: SET_REPORT_BY_ID,
		reportId: value
	};
}

function setReportGridState(value) {
	return {
		type: SET_REPORT_GRID_STATE,
		gridState: value
	};
}

function changeReportTitle(title) {
	return {
		type: CHANGE_REPORT_TITLE,
		title: title
	};
}

function changeReportId(id) {
	return {
		type: CHANGE_REPORT_ID,
		id: id
	};
}

function createNewReport(title, id) {
	return (dispatch, getState) => {
		dispatch(changeReportId(id));
		dispatch(changeReportTitle(title));
		dispatch(reportActions.createReport(getState().events.reportDef));
	};
}

function saveReport() {
	return (dispatch, getState) => {
		let reportDef = getState().events.reportDef;
		dispatch(reportActions.updateReport(reportDef, { id: reportDef.id }));
	};
}

function deleteCurrentReport() {
	return (dispatch, getState) => {
		let reportId = getState().events.reportDef.id;
		if (reportId) {
			dispatch(reportActions.deleteReport(reportId));
			dispatch(setReportById(null));
		}
	};
}

function setTimeframe(timeframe) {
	return {
		type: SET_TIMEFRAME,
		timeframe: timeframe
	};
}

function recalculateStartEndTimes() {
	return {
		type: RECALCULATE_START_END_TIMES
	};
}

function changeTimeframe(timeframe) {
	return (dispatch, getState) => {
		dispatch(setTimeframe(timeframe));
		dispatch(recalculateStartEndTimes());
		dispatch(fetchRecordsForCurrentDatetimeRange());
	};
}

// Action Dispatching...

function useAppEventActions() {
	return {
		setStatusFlag: setStatusFlag,
		fetchRecordsForCurrentTimeframe: fetchRecordsForCurrentTimeframe,
		changeDateTimeRange: changeDateTimeRange,
		setFetchRecordLimit: setFetchRecordLimit,
		setReportById: setReportById,
		setReportGridState: setReportGridState,
		changeReportTitle: changeReportTitle,
		createNewReport: createNewReport,
		saveReport: saveReport,
		deleteCurrentReport: deleteCurrentReport,
		changeTimeframe: changeTimeframe
	};
}

//endregion Actions

//region Reducer

function updateRecords(state, records) {
	records.forEach(record => {
		let key = record.eventID;
		state.record_dict[key] = record;
	});
	state.records = Object.values(state.record_dict);
}

function setReportDefById(state, reports, reportToLoad) {
	let reportDef = reports.items.find(r => r.id === reportToLoad);
	if (reportDef) {
		state.reportDef = _.cloneDeep(reportDef);
		state.reportToLoad = null;
		state.reportModified = false;
	} else {
		state.reportDef = _.cloneDeep(REPORT_TEMPLATE);
		state.reportToLoad = reportToLoad;
		state.reportModified = false;
	}
}

function eventReducer(state = INITIAL_STATE, action, reports) {
	if (action.type.startsWith(ACTION_TYPE_PREFIX)) {
		let newState = { ...state };
		switch (action.type) {
			case SET_STATUS_FLAG:
				newState.statusFlags[action.flag] = action.value;
				break;
			case SET_REPORT_BY_ID:
				setReportDefById(newState, reports, action.reportId);
				break;
			case SET_FETCH_RECORD_LIMIT:
				newState.fetchRecordLimit = action.fetchRecordLimit;
				break;
			case SET_DATETIME_RANGE:
				newState.startTime = action.start;
				newState.endTime = action.end;
				break;
			case SET_FETCH_REQUEST_TS:
				newState.fetchRequestTimestamp = action.fetchRequestTimestamp;
				break;
			case SAVE_RECORDS:
				updateRecords(newState, action.records);
				break;
			case CLEAR_RECORDS:
				newState.records.splice(0, newState.records.length);
				newState.record_dict = {};
				break;
			case SET_REPORT_GRID_STATE:
				if (!state.statusFlags.applyingReportDef && !newState.statusFlags.loadingData) {
					newState.reportDef.gridState = action.gridState;
					newState.reportModified = true;
				}
				break;
			case CHANGE_REPORT_TITLE:
				newState.reportDef.title = action.title;
				newState.reportModified = true;
				break;
			case CHANGE_REPORT_ID:
				newState.reportDef.id = action.id;
				newState.reportModified = true;
				break;
			case SET_TIMEFRAME:
				newState.timeframe = action.timeframe;
				break;
			case RECALCULATE_START_END_TIMES:
				let [start, end] = TIMEFRAME_DEFS[state.timeframe].fnc();
				newState.startTime = start;
				newState.endTime = end;
				break;
			default:
				console.log('HT App-Events Redux Event Type not handled!', action);
		}
		return newState;
	} else if (action.type === '@@resource/REPORT/FETCH') {
		if (action.status === 'resolved' && action.body.length > 0 && state.reportToLoad) {
			let newState = { ...state };
			setReportDefById(newState, reports, newState.reportToLoad);
			return newState;
		} else {
			return state;
		}
	} else if (action.type === '@@resource/REPORT/CREATE') {
		if (action.status === 'resolved') {
			let newState = { ...state };
			newState.reportModified = false;
			return newState;
		}
	} else if (action.type === '@@resource/REPORT/UPDATE') {
		if (action.status === 'resolved' && action.body.id === state.reportDef.id) {
			console.log('successfully saved report');
			let newState = { ...state };
			newState.reportModified = false;
			return newState;
		}
	}

	return state;
}

//endregion Reducer

//region Export

const reducer = (state, action) => {
	state = state || {}; // initially, state is undefined
	let new_report_state = reportReducer(state.reports, action);
	let new_events_state = eventReducer(state.events, action, new_report_state);

	// console.log(action.type, new_report_state, new_events_state);

	return {
		reports: new_report_state,
		events: new_events_state
	};
};

const store = createStore(reducer, applyMiddleware(thunk));
export { reducer, store, useAppEventActions, reportActions, TIMEFRAMES, TIMEFRAME_DEFS };

//endregion Export
