import 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, InputLabel, MenuItem, Select, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useLiDashboardActions } from './store';
import { getFormattedDate, dateIsValid } from './utils';
import REPORT_TYPES from './types';

const useStyles = makeStyles(theme => ({
	root: {},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	pickerGroup: {},
	selectContainer: {
		marginRight: theme.spacing(4)
	},
	buttonContainer: {},
	picker: {
		marginTop: 0,
		marginRight: theme.spacing(4)
	}
}));

export default function Toolbar() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const dashboardActions = useLiDashboardActions();

	const startDateStr = useSelector(state => state.startDate);
	const [start, setStartDate] = useState(new Date(startDateStr));
	function handleStartChange(date) {
		setStartDate(date);
	}

	const endDateStr = useSelector(state => state.endDate);
	const [end, setEndDate] = useState(new Date(endDateStr));
	function handleEndChange(date) {
		setEndDate(date);
	}

	// Submit button
	function handleSubmit(event) {
		// dispatch(dashboardActions.setFetchRecordLimit(fetchRecordLimit));
		dispatch(dashboardActions.setDateRange(start, end));
	}
	let disableSubmit = !dateIsValid(start) || !dateIsValid(end) || getFormattedDate(start) > getFormattedDate(end);

	// Report Type
	const reportType = useSelector(state => state.reportType);
	function handleChangeReportType(event) {
		dispatch(dashboardActions.setReportType(event.target.value));
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Grid container justify="space-between" className={classes.root}>
				<div className={classes.pickerGroup}>
					<FormControl className={classes.selectContainer}>
						<InputLabel id="report-type-select-label">Report Type</InputLabel>
						<Select
							labelId="report-type-select-label"
							id="report-type-select"
							value={reportType}
							onChange={handleChangeReportType}
						>
							{Object.keys(REPORT_TYPES).map((keyName, i) => (
								<MenuItem key={i} value={keyName}>
									{REPORT_TYPES[keyName].label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="MM/dd/yyyy"
						margin="normal"
						id="start-date-picker"
						label="Start Date"
						value={start}
						onChange={handleStartChange}
						KeyboardButtonProps={{
							'aria-label': 'change date'
						}}
						disableFuture={true}
						maxDate={end}
						className={classes.picker}
					/>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="MM/dd/yyyy"
						margin="normal"
						id="end-date-picker"
						label="End Date"
						value={end}
						onChange={handleEndChange}
						KeyboardButtonProps={{
							'aria-label': 'change date'
						}}
						disableFuture={true}
						minDate={start}
						className={classes.picker}
					/>
				</div>
				<div className={classes.buttonContainer}>
					<Button
						onClick={handleSubmit}
						className={classes.submitButton}
						variant="contained"
						color="primary"
						disabled={disableSubmit}
					>
						Submit
					</Button>
				</div>
			</Grid>
		</MuiPickersUtilsProvider>
	);
}
