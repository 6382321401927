import { createResource } from 'redux-rest-resource';
import { PROXY_API_HOST } from '../../../../js/constants/env-settings';

const OPTIONAL_PROPERTIES = ['source', 'location', 'status', 'notes'];

function validate_response_data(data) {
	data.id = data.proxy_id;
	OPTIONAL_PROPERTIES.forEach(prop => {
		if (data[prop] === undefined) {
			data[prop] = '';
		}
	});
	return data;
}

function validate_request_data(data) {
	delete data.id;
	OPTIONAL_PROPERTIES.forEach(prop => {
		if (!data[prop]) {
			delete data[prop];
		}
	});
	return data;
}

const { types, actions, rootReducer } = createResource({
	name: 'proxy',
	pluralName: 'proxies',
	url: `${PROXY_API_HOST}/proxies/:proxy_id`,
	actions: {
		fetch: {
			transformResponse: res => {
				for (var i in res.body) {
					res.body[i] = validate_response_data(res.body[i]);
				}
				return res;
			}
		},
		create: {
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		update: {
			method: 'PUT',
			assignResponse: true,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		unlock: {
			method: 'POST',
			url: `${PROXY_API_HOST}/unlock/:proxy_id`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			reduce: (state, action) => {
				switch (action.status) {
					case 'pending':
						return {
							...state,
							isUnlockingProxy: true
						};
					case 'resolved': {
						const id = action.context.id;
						const update = action.body;
						const listItemIndex = state.items.findIndex(el => el.id === id);
						const updatedItems = state.items.slice();
						if (listItemIndex !== -1) {
							updatedItems[listItemIndex] = {
								...updatedItems[listItemIndex],
								...update
							};
						}
						return {
							...state,
							isUnlockingProxy: false,
							items: updatedItems
						};
					}
					case 'rejected':
						return {
							...state,
							isUnlockingProxy: false
						};
					default:
						return state;
				}
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		displayCreationForm: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				creationFormVisible: action.context
			})
		},
		displayEditForm: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				editFormVisible: action.context.visible,
				editFormData: state.items.find(proxy => proxy.proxy_id === action.context.proxy_id)
			})
		},
		displayRawJson: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				rawJsonVisible: action.context
			})
		},
		persistAccount: {
			method: 'POST',
			url: `${PROXY_API_HOST}/persist`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			}
		}
	}
});

export { types, actions, rootReducer as proxyReducer };
