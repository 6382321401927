import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { COLUMN_DEFS, DEFAULT_COLUMN_DEF } from './columns';

const SIDE_BAR_DEF = {
	toolPanels: [
		{
			id: 'columns',
			labelDefault: 'Columns',
			labelKey: 'columns',
			iconKey: 'columns',
			toolPanel: 'agColumnsToolPanel',
			width: 400
		},
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel',
			width: 400
		}
	]
};

const STATUS_BAR_DEF = {
	statusPanels: [
		{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
		{ statusPanel: 'agTotalRowCountComponent', align: 'center' },
		{ statusPanel: 'agFilteredRowCountComponent' },
		{ statusPanel: 'agSelectedRowCountComponent' },
		{ statusPanel: 'agAggregationComponent' }
	]
};

function ReportGrid({ onGridReady }) {
	const [gridApi, setGridApi] = useState();
	const data = useSelector(state => state.events.records);

	function handleGridReady(params) {
		setGridApi(params.api);
		if (onGridReady) {
			onGridReady(params);
		}
	}

	// Grid Loading
	const loadingData = useSelector(state => state.events.statusFlags.loadingData);
	useEffect(() => {
		if (gridApi) {
			if (loadingData) {
				gridApi.showLoadingOverlay();
			} else if (data && data.length > 0) {
				gridApi.hideOverlay();
			} else {
				gridApi.showNoRowsOverlay();
			}
		}
	}, [gridApi, loadingData, data]);

	return (
		<div
			className="ag-theme-material"
			style={{
				height: '100%'
			}}
		>
			<AgGridReact
				columnDefs={COLUMN_DEFS}
				rowData={data}
				enableRangeSelection={true}
				rowSelection="multiple"
				rowDeselection={true}
				onGridReady={handleGridReady}
				groupSelectsChildren={true}
				immutableData={true}
				getRowNodeId={n => n.ip_address + '|' + String(n.ts)}
				enableCharts={true}
				animateRows
				defaultColDef={DEFAULT_COLUMN_DEF}
				sideBar={SIDE_BAR_DEF}
				statusBar={STATUS_BAR_DEF}
			/>
		</div>
	);
}

export default ReportGrid;
