import React from 'react';
import { Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';
import {
	mdiCheckboxMarkedCircle,
	mdiAlert,
	mdiAlertCircle,
	mdiFormTextboxPassword,
	mdiEmailLock,
	mdiPhoneLock,
	mdiAccountRemove,
	mdiAccountQuestion,
	mdiPhoneOff,
	mdiShieldOff
} from '@mdi/js';
import { grey, green, orange, red } from '@material-ui/core/colors';

const DEFAULT_ICON = mdiAlertCircle;
const ICONS = {
	'': mdiCheckboxMarkedCircle,
	ok: mdiCheckboxMarkedCircle,
	challenge_required: mdiAlert,
	'challenge-required': mdiAlert,
	checkpoint_required: mdiAlert,
	checkpoint_challenge_required: mdiAlert,
	'bad-password': mdiFormTextboxPassword,
	'verify-email': mdiEmailLock,
	'verify-phone': mdiPhoneLock,
	'submit-phone': mdiPhoneOff,
	'terms-violation': mdiAccountRemove,
	'sentry-block': mdiAccountQuestion,
	'proxy-not-working': mdiShieldOff
};

const DEFAULT_ICON_COLOR = red[700];
const ICON_COLORS = {
	'': grey[500],
	ok: green[500],
	challenge_required: orange[500],
	'challenge-required': orange[500],
	checkpoint_required: orange[500],
	checkpoint_challenge_required: orange[500],
	'bad-password': orange[500],
	'verify-email': orange[500],
	'verify-phone': orange[500],
	'submit-phone': orange[500],
	'terms-violation': red[700],
	'sentry-block': red[500],
	'proxy-not-working': red[500]
};

function StatusRenderer({ value }) {
	const icon = ICONS[value] || DEFAULT_ICON;
	const iconColor = ICON_COLORS[value] || DEFAULT_ICON_COLOR;
	return (
		<Tooltip title={value}>
			<Icon path={icon} size={0.75} color={iconColor} />
		</Tooltip>
	);
}

const AccountStatusRenderer = ({ value }) => {
	return <StatusRenderer value={value} />;
};

export default AccountStatusRenderer;
