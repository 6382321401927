import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Avatar, ListItem, ListItemText, ListItemAvatar, Typography, Badge } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { truncateWithEllipses } from '../../../../widgets';
import { useLiMessagingActions } from '../store';

const useStyles = makeStyles(theme => ({
	conversationSummary: {
		'& .MuiBadge-badge': {
			zIndex: 100
		}
	},
	inline: {
		display: 'inline'
	},
	selected: {
		backgroundColor: theme.palette.secondary.lightest
	},
	unread: {
		'& p, & span': {
			fontWeight: 'bold'
		}
	}
}));

export default function ConversationSummary({ data, selected }) {
	// Styles
	const classes = useStyles();
	let rootClassNames = [];
	if (selected) {
		rootClassNames.push(classes.selected);
	}

	// Redux
	const dispatch = useDispatch();
	const actions = useLiMessagingActions();
	const accountId = useSelector(state => state.accountId);

	// Error Checking
	if (!data.profiles || data.profiles.length === 0 || !data.profiles[0]) {
		return (
			<ListItem alignItems="flex-start">
				<ListItemText
					primary="Error"
					secondary={
						'account_profile_id=' + data.account_profile_id + ' conversation_id=' + data.conversation_id
					}
				/>
			</ListItem>
		);
	}

	// Title
	let title = 'Error';
	if (data.name) {
		title = data.name;
	} else if (data.profiles && data.profiles.length > 0) {
		if (data.profiles.length === 1) {
			title = data.profiles[0].fullName;
		} else {
			title = data.profiles.reduce((a, p) => a.concat([p.firstName]), []).join(', ');
		}
	}

	// Text Display
	let previewText = '';
	let lastEventFrom = 'You';
	if (data.most_recent_event !== undefined) {
		lastEventFrom = 'You';
		previewText = data.most_recent_event.text || '';
		let lastEventFromId = data.most_recent_event.from;
		if (lastEventFromId === undefined) {
			lastEventFrom = '';
		} else if (lastEventFromId.toString() !== accountId) {
			let lastEventProfile = data.profiles.find(p => p.profile_id === lastEventFromId);
			if (lastEventProfile) {
				lastEventFrom = lastEventProfile.firstName;
			} else {
				lastEventFrom = 'Unknown';
			}
		}
	} else {
		lastEventFrom = 'Scheduled';
		previewText = (data.pending && data.pending.length > 0 && data.pending[0].message) || '';
	}

	// OnClick
	function handleClick(event) {
		if (!selected) {
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();
			dispatch(actions.setSelectedMessagingId(data.account_profile_id));
		}
	}

	return (
		<ListItem
			alignItems="flex-start"
			onClick={handleClick}
			className={clsx(
				classes.conversationSummary,
				{ [classes.selected]: selected },
				{ [classes.unread]: !data.read }
			)}
		>
			<Badge
				badgeContent={data.unreadCount}
				color="primary"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<ListItemAvatar>
					<AvatarGroup max={2} spacing="small">
						{data.profiles.map((profile, index) => (
							<Avatar key={'profile' + index} alt={profile.fullName} src={profile.pictureUrl} />
						))}
					</AvatarGroup>
				</ListItemAvatar>
			</Badge>

			<ListItemText
				primary={title}
				secondary={
					<React.Fragment>
						<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
							{lastEventFrom}
						</Typography>
						{': '}
						{truncateWithEllipses(previewText, 45)}
					</React.Fragment>
				}
			/>
		</ListItem>
	);
}
