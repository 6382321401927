import React from 'react';
import { Field } from 'react-final-form';
import { Checkbox, Select, TextField } from 'final-form-material-ui';
import { MenuItem } from '@material-ui/core';
import { validators } from '../../../../../widgets';

export function EnabledField(props) {
	return (
		<>
			<Field name="enabled" component={Checkbox} type="checkbox" />
			<label>Enabled</label>
		</>
	);
}

export function CampaignIDField(props) {
	return (
		<Field
			name="connection_campaign_id"
			component={TextField}
			type="string"
			label="Campaign ID"
			validate={validators.required}
			fullWidth
			disabled
			{...props}
		/>
	);
}

export function TitleField(props) {
	return (
		<Field
			name="title"
			component={TextField}
			type="text"
			label="Title"
			validate={validators.required}
			fullWidth
			required
			{...props}
		/>
	);
}

export function SavedSearchField(props) {
	return <Field name="saved_search" component={TextField} type="text" label="Saved Search" fullWidth {...props} />;
}

export function ConnectionMessageEnabledField(props) {
	return (
		<>
			<Field name="connection_message_enabled" component={Checkbox} type="checkbox" />
			<label>Enable Connection Message</label>
		</>
	);
}

export function ConnectionMessageField(props) {
	return (
		<Field
			fullWidth
			name="connection_message"
			component={TextField}
			label="Connection Message"
			multiline="true"
			{...props}
		/>
	);
}

export function DailyConnectionCountField(props) {
	return (
		<Field
			name="daily_connection_count"
			component={TextField}
			type="number"
			label="Connection Count"
			validate={validators.all(validators.required, validators.isNumber)}
			required
			parse={value => (isNaN(parseInt(value)) ? null : parseInt(value))}
			defaultValue={100}
		/>
	);
}

export function MessageCampaignSelectField({ options, ...props }) {
	return (
		<Field
			fullWidth
			name="message_campaign_id"
			component={Select}
			label="Message Campaign"
			formControlProps={{ fullWidth: true }}
			{...props}
		>
			<MenuItem value="">None</MenuItem>
			{options &&
				options.map((option, index) => <MenuItem value={option.message_campaign_id}>{option.title}</MenuItem>)}
		</Field>
	);
}
