import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

const styles = theme => ({
    Badge: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.black
    }
});

function BadgeIcon({ classes, icon, badgeContent, max=9 }) {
    return (
        <div>
            < Badge
                classes={{ badge: classes.Badge }}
                badgeContent={isNaN(badgeContent) ? 0 : badgeContent}
                max={max}
            >
                {icon}
            </Badge>
        </div >
    );
}

BadgeIcon.propTypes = {
    /** MUI theme classes */
    classes: PropTypes.object.isRequired,
    /** Icon node to display with badge overlay */
    icon: PropTypes.node,
    /** Number to display on the badge. */
    badgeContent: PropTypes.number,
    /** Maximum number the badge will display. Higher numbers will display '{max}+' */
    max: PropTypes.number
};

export default withStyles(styles)(BadgeIcon);