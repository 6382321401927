import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import moment from 'moment';

import { JsonDisplay } from '../../../widgets';
import { useLiDashboardActions, store } from './store';

//components
import ReportGrid from './grid';
import Toolbar from './Toolbar';
import ActionsRenderer from './MoreAction';
import REPORT_TYPES from './types';
import TitleBar from '../../../widgets/TitleBar';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column'
	},
	toolbar: {},
	contentContainer: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		height: 0,
		flexGrow: 1
	},
	chart: {
		height: 0,
		flexGrow: 3
	},
	grid: {
		height: 0,
		flexGrow: 1
	},
	spacer: {
		height: theme.spacing(2)
	}
}));

export function WrappedReportsPage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const dashboardActions = useLiDashboardActions();
	const [gridApi, setGridApi] = useState();
	const [columnApi, setColumnApi] = useState();
	const [chartRef, setChartRef] = useState();
	const [cachedChartType, setCachedChartType] = useState();

	// Handle changes to the provided account ID
	const { accountId } = useParams();
	const cachedAccountId = useSelector(state => state.accountId);

	useEffect(() => {
		if (accountId !== cachedAccountId) {
			dispatch(dashboardActions.initializeWithAccountID(accountId));
		}
	}, [accountId, cachedAccountId]);

	// Handle changes to Grid State
	const { reportType, startDate, endDate, records } = useSelector(state => state);

	useEffect(() => {
		if (gridApi && columnApi) {
			let chartContainerSelector = document.querySelector('#report-chart-container');
			let spacerSelector = document.querySelector('#report-chart-spacer');

			const gridState = REPORT_TYPES[reportType].getGridState(startDate, endDate);
			if (gridState) {
				columnApi.setColumnState(gridState.colState);
				columnApi.setColumnGroupState(gridState.groupState);
				columnApi.setPivotMode(gridState.pivotMode);
				gridApi.filterManager.setFilterModel(gridState.filterState);

				let chartDestroyed = false;
				if (chartRef && reportType !== cachedChartType) {
					chartRef.destroyChart();
					setChartRef(null);
					chartDestroyed = true;
				}

				if (gridState['chartParams'] && records.length > 0 && (!chartRef || chartDestroyed)) {
					chartContainerSelector.style.flexGrow = 1;
					spacerSelector.style.display = 'block';
					gridState.chartParams.chartContainer = document.querySelector('#report-chart-container');

					if (gridState.pivotMode) {
						setChartRef(gridApi.createPivotChart(gridState.chartParams));
					} else {
						setChartRef(gridApi.createRangeChart(gridState.chartParams));
					}

					setCachedChartType(reportType);
				}
				if (!gridState['chartParams']) {
					chartContainerSelector.style.flexGrow = 0;
					spacerSelector.style.display = 'none';
				}
			} else {
				columnApi.resetColumnState();
				columnApi.resetColumnGroupState();
				columnApi.setPivotMode(false);
				gridApi.filterManager.setFilterModel(null);
			}
		}
	}, [gridApi, columnApi, reportType, startDate, endDate, records]);

	// Grid State Functions
	function handleGridReady(params) {
		setGridApi(params.api);
		setColumnApi(params.columnApi);
		window.grid = params;
	}
	function handleLogGridState(event) {
		event.stopPropagation();
		var colState = columnApi.getColumnState();
		var groupState = columnApi.getColumnGroupState();
		var filterState = gridApi.filterManager.getFilterModel();
		var pivotMode = columnApi.isPivotMode();

		console.log('**********************************************');
		console.log(
			JSON.stringify(
				{
					colState: colState,
					groupState: groupState,
					filterState: filterState,
					pivotMode: pivotMode
				},
				null,
				2
			)
		);
		console.log('**********************************************');
	}
	function handleSaveGridState(event) {
		event.stopPropagation();
		window.colState = columnApi.getColumnState();
		window.groupState = columnApi.getColumnGroupState();
		window.filterState = gridApi.filterManager.getFilterModel();
		console.log('column state saved');
	}
	function handleRestoreGridState(event) {
		event.stopPropagation();
		if (!window.colState) {
			console.log('no columns state to restore by, you must save state first');
			return;
		}
		columnApi.setColumnState(window.colState);
		columnApi.setColumnGroupState(window.groupState);
		gridApi.filterManager.setFilterModel(window.filterState);

		console.log('column state restored');
	}
	function handleResetGridState(event) {
		event.stopPropagation();
		// columnApi.resetColumnState();
		// columnApi.resetColumnGroupState();
		// gridApi.filterManager.setFilterModel(null);
		dispatch(dashboardActions.refreshRecordForDateRange(startDate, endDate));
		console.log('column state reset');
	}

	// Debug
	const state = useSelector(state => state);

	return (
		<div className={classes.root}>
			<TitleBar
				title={`${REPORT_TYPES[state.reportType].label} - ${state.account.li_username} - 
				${moment(startDate).format('L')} to ${moment(endDate).format('L')}`}
				onClickRefreshButton={handleResetGridState}
				customButton={
					<ActionsRenderer
						onLogGridState={handleLogGridState}
						onSaveGridState={handleSaveGridState}
						onRestoreGridState={handleRestoreGridState}
						onResetGridState={handleResetGridState}
					/>
				}
			>
				<Toolbar />
			</TitleBar>
			<div className={classes.spacer} />
			<div className={classes.contentContainer}>
				<Paper className={classes.content} elevation={2} id="report-chart-container" />
				<div className={classes.spacer} id="report-chart-spacer" />
				<Paper elevation={2} className={classes.grid}>
					<ReportGrid onGridReady={handleGridReady} />
				</Paper>
			</div>
			{state.statusFlags.rawJsonVisible && <JsonDisplay data={state} />}
		</div>
	);
}

export default function ReportsPage(props) {
	return (
		<Provider store={store}>
			<WrappedReportsPage />
		</Provider>
	);
}
