import React, { useEffect } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
//components
import DatePicker from './DatePicker';
import TitleBar from '../../../widgets/TitleBar';
import Grid from './agChart';

import { useLiDashboardActions, store } from '../dashboard/store';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%'
	},
	display: {
		flexGrow: 1,
		width: '100%'
	},
	spacer: {
		height: theme.spacing(2)
	},
	header: {
		padding: theme.spacing(2)
	},
	datePicker: {
		width: '100%'
	}
}));

function WrapperConnectionPage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const dashboardActions = useLiDashboardActions();

	const { startDate, endDate } = useSelector(state => state);
	// Handle changes to the provided account ID
	const { accountId } = useParams();
	const cachedAccountId = useSelector(state => state.accountId);
	useEffect(() => {
		if (accountId !== cachedAccountId) {
			dispatch(dashboardActions.initializeWithAccountID(accountId));
		}
	}, [accountId, cachedAccountId]);
	const state = useSelector(state => state);

	function handleClickRefreshButton(event) {
		event.stopPropagation();
		dispatch(dashboardActions.refreshRecordForDateRange(startDate, endDate));
	}

	return (
		<div className={classes.root}>
			<TitleBar
				title={`Dashboard: ${state.account.li_username} -
				${moment(startDate).format('L')} to ${moment(endDate).format('L')}`}
				onClickRefreshButton={handleClickRefreshButton}
			>
				<div className={classes.datePicker} elevation={2}>
					<DatePicker account={state.account.li_username} />
				</div>
			</TitleBar>
			<div className={classes.spacer} />
			<Paper className={classes.display} elevation={2}>
				<Grid />
			</Paper>
		</div>
	);
}

export default function DashboardPage(props) {
	return (
		<Provider store={store}>
			<WrapperConnectionPage />
		</Provider>
	);
}
