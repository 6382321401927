const initialState = {
        settings: {
            delimitLiking: {
                min: 2,
                max: 500,
                enabled: true
            },
            skipUsers: {
                skipPrivate: true,
                businessPercent: 100,
                skipBusiness: 0,
                skipNoPic: true
            },
            doLike: {
                percentage: 100,
                enabled: true
            },
            doFollow: {
                percentage: 100,
                enabled: true
            },
            likeByTags: {
                tagSampleSize: 10,
                postMax: 25,
                postMin: 20,
                enabled: true
            },
            userInteract: {
                amount: 3,
                percentage: 20,
                randomize: true,
                enabled: true
            },
            mandatoryLanguage: {
                charset: "LATIN",
                enabled: true
            },
            relationshipBounds: {
                maxFollowers: 99999999,
                minFollowing: 20,
                potencyRatio: 0.2,
                maxFollowing: 5000,
                enabled: true
            },
            unfollow: {
                unfollowMax: 250,
                unfollowMin: 245,
                unfollowDays: 5,
                enabled: true,
                nonFollowers: true
            }
        },
        userInfo: {
            proxyPort: "",
            proxyPW: "",
            instaUser: "",
            proxyAddr: "",
            proxyUser: "",
            instaPW: "",
            emailAccount: "",
            emailPW:""
        },
        badwords: "",
        hashtags: "",
        blacklist: "",
        title: "",
        enabled: false


};
function initReducer(state = initialState) {
    return state;
};

export default initReducer;