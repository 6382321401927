import React, { useEffect } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { JsonDisplay } from '../../../widgets';
import { makeStyles, Paper } from '@material-ui/core';
import { actions as accountActions, store } from './store';

//components
import AccountTable from './grid';
import { CreateFormDialog, EditFormDialog } from './forms';
import TitleBar from '../../../widgets/TitleBar';

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	spacer: {
		height: theme.spacing(2)
	},
	display: {
		flexGrow: 1
	},
	json: {
		padding: theme.spacing(2)
	}
}));

function WrappedAccountPage() {
	const classes = useStyles();
	const accounts = useSelector(state => state.items);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(accountActions.fetchAccounts());
	}, []);

	const creationFormVisible = useSelector(state => state.creationFormVisible);
	function handleClickCreateButton(event) {
		dispatch(accountActions.displayCreationFormAccount(true));
	}
	function handleCreateFormSubmit(data) {
		dispatch(accountActions.createAccount(data));
		dispatch(accountActions.displayCreationFormAccount(false));
	}
	function handleCreateFormClose() {
		dispatch(accountActions.displayCreationFormAccount(false));
	}

	const editFormVisible = useSelector(state => state.editFormVisible);
	const editFormData = useSelector(state => state.editFormData);
	function handleEditFormSubmit(data) {
		dispatch(accountActions.updateAccount(data));
		dispatch(accountActions.displayEditFormAccount({ visible: false, id: null }));
	}
	function handleEditFormClose() {
		dispatch(accountActions.displayEditFormAccount({ visible: false, id: null }));
	}

	function handleClickRefreshButton(event) {
		dispatch(accountActions.fetchAccounts());
	}

	let rawJsonVisible = useSelector(state => state.rawJsonVisible);
	rawJsonVisible = rawJsonVisible || false; // set to false if null
	function handleClickShowDataButton(event) {
		rawJsonVisible = !rawJsonVisible;
		dispatch(accountActions.displayRawJsonAccount(rawJsonVisible));
	}

	return (
		<div className={classes.root}>
			<TitleBar
				title={'Linked-In Account List'}
				onClickRefreshButton={handleClickRefreshButton}
				onClickAddButton={handleClickCreateButton}
				onClickDataButton={handleClickShowDataButton}
			/>
			<div className={classes.spacer} />
			<CreateFormDialog
				open={creationFormVisible || false}
				onSubmit={handleCreateFormSubmit}
				onClose={handleCreateFormClose}
			/>
			<EditFormDialog
				open={editFormVisible || false}
				data={editFormData}
				onSubmit={handleEditFormSubmit}
				onClose={handleEditFormClose}
			/>
			<Paper className={classes.display} elevation={2}>
				{rawJsonVisible ? (
					<div className={classes.json}>
						<JsonDisplay data={accounts} />
					</div>
				) : (
					<AccountTable
						data={accounts}
						onClickCreateButton={handleClickCreateButton}
						onClickShowDataButton={handleClickShowDataButton}
					/>
				)}
			</Paper>
		</div>
	);
}
const WrappedAccountPageMemo = React.memo(WrappedAccountPage);

export default function AccountPage(props) {
	return (
		<Provider store={store}>
			<WrappedAccountPageMemo {...props} />
		</Provider>
	);
}
