import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { ConfirmationDialog, useConfirmation } from '../../../../widgets';
import { actions as accountActions } from '../store';

const EnabledSwitch = withStyles({
	switchBase: {
		color: red[200],
		'&$checked': {
			color: green[500]
		}
	},
	checked: {}
})(Switch);

function AccountEnabledRenderer({ value, data }) {
	const enabled = value;
	const account_id = data.account_id;
	const username = data.li_username;
	const dispatch = useDispatch();

	function onConfirm(event) {
		dispatch(accountActions.enableAccount({ id: account_id, account_id: account_id, enable: !enabled }));
	}
	const [open, onClick, onClose, onConfirmWrap] = useConfirmation(onConfirm);

	const confirmLabel = enabled ? 'Disable' : 'Enable';
	const text = confirmLabel + " Account '" + username + "'?";

	return (
		<Fragment>
			<ConfirmationDialog
				text={text}
				open={open}
				onClose={onClose}
				onConfirm={onConfirmWrap}
				confirmLabel={confirmLabel}
			/>
			<EnabledSwitch checked={enabled} onClick={onClick} color="default" />
		</Fragment>
	);
}

export default AccountEnabledRenderer;
