import { createResource } from 'redux-rest-resource';

const stage = process.env.REACT_APP_RUN_ENVIRONMENT || 'dev';
const REPORTS_API_HOSTS = {
	local: 'http://localhost:3007',
	dev: 'https://jdawlvb411.execute-api.us-east-1.amazonaws.com/dev',
	qa: 'https://jdawlvb411.execute-api.us-east-1.amazonaws.com/dev',
	prod: 'https://jdawlvb411.execute-api.us-east-1.amazonaws.com/dev'
};
const REPORTS_API_HOST = REPORTS_API_HOSTS[stage];

const { types, actions, rootReducer } = createResource({
	name: 'report',
	pluralName: 'reports',
	url: `${REPORTS_API_HOST}/reports/:id`,
	actions: {
		fetch: {
			query: {
				type: 'proxies'
			},
			assignResponse: true
			// transformResponse: res => {
			// 	for (var i in res.body) {
			// 		res.body[i] = validate_response_data(res.body[i]);
			// 	}
			// 	return res;
			// }
		},
		create: {
			method: 'POST',
			url: `${REPORTS_API_HOST}/reports`,
			assignResponse: true
		},
		update: {
			method: 'PUT',
			url: `${REPORTS_API_HOST}/reports/:id`,
			assignResponse: true
		}
		// 	delete: {
		// 		transformResponse: res => {
		// 			eventTrack({ eventType: 'collectionDelete', status: 'success' });
		// 			return res;
		// 		}
		// 	}
	}
});

export { types, actions, rootReducer as reportReducer };
