import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import poundLogoWhite from './assets/images/poundLogoWhite.png';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	logo: {
		height: '50%',
		width: '50%'
	},
	loadingText: {
		color: 'white',
		marginTop: theme.spacing(1)
	}
}));

export default function AppLoadingOverlay() {
	const classes = useStyles();
	return (
		<main className={classes.root}>
			<div className={classes.container}>
				<img className={classes.logo} src={poundLogoWhite} alt="Pound Social Logo" />
				<Typography className={classes.loadingText} variant="h5">
					loading
				</Typography>
			</div>
		</main>
	);
}
