import {
	getDateFromFormattedString,
	timestampToFormattedDate,
	// secondsToStr,
	getWeekIndex,
	getDayOfWeek,
	getMonth,
	DAYS_OF_WEEK,
	MONTHS
} from '../utils';

const DEFAULT_COLUMN_DEF = {
	minWidth: 100,
	// allow every column to be aggregated
	enableValue: true,
	// allow every column to be grouped
	enableRowGroup: true,
	// allow every column to be pivoted
	enablePivot: true,
	sortable: true,
	filter: true,
	resizable: true,
	floatingFilter: true
};

const TIMESTAMP_FIELDS = [
	{ field: 'time', headerPrefix: 'Event', colIdPrefix: 'event' },
	{ field: 'user.expire', headerPrefix: 'User Expiration', colIdPrefix: 'userExpiration' }
];

const COLUMN_DEFS = [
	{
		headerName: 'Event ID',
		field: 'eventID',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Event Type',
		field: 'event.eventType',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Event Status',
		field: 'event.status',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Query',
		field: 'event.query',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'User ID',
		field: 'userDetail.userID',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'User in DB',
		field: 'user.exists',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'User Email',
		field: 'user.email',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'User Code',
		field: 'user.code',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'User First Name',
		field: 'user.firstname',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'User Last Name',
		field: 'user.lastname',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'User Instagram',
		field: 'user.instagramuser',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'User Language',
		field: 'user.language',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'User Promo Code',
		field: 'user.promocode',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Influencer',
		field: 'userDetail.influencer',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'User Group',
		field: 'userDetail.group',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Trial Status',
		field: 'userDetail.trialStatus',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'App Version',
		field: 'userDetail.appVersion',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Platform',
		field: 'userDetail.platform',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Device Name',
		field: 'userDetail.device.name',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Device UUID',
		field: 'userDetail.device.uuid',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Device Available',
		field: 'userDetail.device.available',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Device Serial',
		field: 'userDetail.device.serial',
		filter: 'agTextColumnFilter'
	},
	{
		headerName: 'Virtual Device',
		field: 'userDetail.device.isVirtual',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Device Model',
		field: 'userDetail.device.model',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Device Version',
		field: 'userDetail.device.version',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Device Manufacturer',
		field: 'userDetail.device.manufacturer',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Device Platform',
		field: 'userDetail.device.platform',
		filter: 'agSetColumnFilter'
	},
	{
		headerName: 'Cordova',
		field: 'userDetail.device.cordova',
		filter: 'agSetColumnFilter'
	}
];

TIMESTAMP_FIELDS.forEach(element => {
	COLUMN_DEFS.push(
		{
			headerName: element.headerPrefix + ' Timestamp',
			field: element.field,
			filter: 'agNumberColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Date',
			colId: element.colIdPrefix + 'Date',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return timestampToFormattedDate(params.data[element.field]);
				}
			},
			filter: 'agDateColumnFilter',
			filterParams: {
				comparator: function(filterLocalDateAtMidnight, cellValue) {
					if (cellValue === null) return 0;

					var cellDate = getDateFromFormattedString(cellValue);

					if (cellDate < filterLocalDateAtMidnight) {
						return -1;
					} else if (cellDate > filterLocalDateAtMidnight) {
						return 1;
					} else {
						return 0;
					}
				},
				browserDatePicker: true
			}
		},
		{
			headerName: element.headerPrefix + ' Datetime',
			colId: element.colIdPrefix + 'Datetime',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					let jsts = params.data[element.field] * 1000;
					let jsd = new Date(jsts).toLocaleString();
					return jsd.toLocaleString();
				}
			},
			filter: 'agTextColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Hour',
			colId: element.colIdPrefix + 'DateHour',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return Math.floor(params.data[element.field] / 60 / 60) % 24;
				}
			},
			filter: 'agNumberColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Day',
			colId: element.colIdPrefix + 'DateDay',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getDayOfWeek(params.data[element.field]);
				}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				values: DAYS_OF_WEEK,
				suppressSorting: true
			},
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Week',
			colId: element.colIdPrefix + 'DateWeek',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getWeekIndex(params.data[element.field]);
				}
			},
			filter: 'agNumberColumnFilter',
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Month',
			colId: element.colIdPrefix + 'DateMonth',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return getMonth(params.data[element.field]);
				}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				values: MONTHS,
				suppressSorting: true
			},
			hide: true
		},
		{
			headerName: element.headerPrefix + ' Year',
			colId: element.colIdPrefix + 'DateYear',
			valueGetter: params => {
				if (params.data && params.data[element.field]) {
					return new Date(params.data[element.field] * 1000).getUTCFullYear();
				}
			},
			filter: 'agNumberColumnFilter',
			hide: true
		}
	);
});

export { COLUMN_DEFS, DEFAULT_COLUMN_DEF };
