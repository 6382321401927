import React, { useState } from 'react';
import Moment from 'react-moment';
import { makeStyles, Typography, Link } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { JsonDisplay, truncateWithEllipses } from '../../../widgets';
import { timestampToFormattedDate, isEmpty } from './utils';

const SUMMARY_PREVIEW_LENGTH = 40;
const LINK_COLOR = blue[700];

const useStyles = makeStyles(theme => ({
	profileDetailRenderer: {
		padding: theme.spacing(1),
		margin: theme.spacing(1)
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	profilePicture: {
		height: '50px',
		width: '50px',
		borderRadius: '25px'
	},
	headerName: {
		flex: 1,
		paddingLeft: theme.spacing(1)
	},
	headerLink: {
		color: LINK_COLOR,
		fontWeight: 'bold'
	},
	contactLink: {
		color: LINK_COLOR
	},
	fieldTitle: {
		fontWeight: 'bold'
	},
	textLinkButton: {
		fontWeight: 'bold',
		color: LINK_COLOR
	},
	interactionDate: {
		fontWeight: 'bold'
	}
	// textField: {
	// 	gridColumn: 'span 5',
	// 	marginLeft: theme.spacing(1),
	// 	marginRight: theme.spacing(1),
	// 	width: '25ch',
	// 	marginTop: theme.spacing(2),
	// 	marginBottom: theme.spacing(1)
	// },
	// buttonContainer: {
	// 	gridColumn: 'span 2',
	// 	marginTop: theme.spacing(3),
	// 	marginBottom: theme.spacing(2)
	// }
}));

function FieldTitle({ title, className, variant = 'subtitle2', component = 'div', onClick }) {
	const classes = useStyles();
	return (
		<Typography variant={variant} component={component} className={classes.fieldTitle} onClick={onClick}>
			{title}
		</Typography>
	);
}

function FieldDisplay({ title, value, expandable = false, children, defaultExpanded = false }) {
	const classes = useStyles();

	let undefinedValue = typeof value == 'undefined';
	let undefinedChildren = typeof children == 'undefined';

	if (undefinedValue && undefinedChildren) {
		return '';
	}

	const [expanded, setExpanded] = useState(defaultExpanded);
	function toggleExpand() {
		setExpanded(!expanded);
	}

	let expand = !expandable || (expandable && expanded);

	return (
		<div onClick={toggleExpand}>
			<Typography variant="subtitle2" component="span" className={classes.fieldTitle}>
				{title}
				{!expand ? '...' : ': '}
			</Typography>
			{!undefinedValue && (
				<Typography variant="body2" component="span">
					{String(value)}
				</Typography>
			)}
			{!undefinedChildren && expand && children}
		</div>
	);
}

function Header({ data }) {
	const classes = useStyles();
	const li_url = `https://www.linkedin.com/in/${data.public_id}`;
	const sn_url = `https://www.linkedin.com/sales/people/${data.sn_profile_id},,`;
	return (
		<div className={classes.header}>
			{data.pictureUrl && <img src={data.pictureUrl} className={classes.profilePicture} alt="Profile" />}
			<div className={classes.headerName}>
				<div>
					<Typography component="span">{data.fullName} </Typography>
					<Typography component="span">
						<Link rel="noreferrer" target="_blank" href={li_url} className={classes.headerLink}>
							[LI]
						</Link>
					</Typography>
					<Typography component="span">
						<Link rel="noreferrer" target="_blank" href={sn_url} className={classes.headerLink}>
							[SN]
						</Link>
					</Typography>
				</div>
				<Typography variant="caption">{data.headline}</Typography>
			</div>
		</div>
	);
}

function Summary({ summary }) {
	const [expanded, setExpanded] = useState(false);
	function handleClickMoreLess() {
		setExpanded(!expanded);
	}

	if (!summary) return '';

	const expandable = summary.length > SUMMARY_PREVIEW_LENGTH;
	if (!expandable) {
		return (
			<div>
				<FieldTitle title="Summary: " component="span" />
				<Typography variant="body2" component="span">
					{summary}
				</Typography>
			</div>
		);
	}

	if (expanded) {
		return (
			<div onClick={handleClickMoreLess}>
				<FieldTitle title="Summary: " />
				<Typography variant="body2" component="span">
					{summary}
				</Typography>
			</div>
		);
	}
	return (
		<div onClick={handleClickMoreLess}>
			<FieldTitle title="Summary: " component="span" />
			<Typography variant="body2" component="span">
				{truncateWithEllipses(summary, SUMMARY_PREVIEW_LENGTH)}
			</Typography>
		</div>
	);
}

function Interactions({ data }) {
	const classes = useStyles();
	// const dispatch = useDispatch();
	// const dashboardActions = useLiDashboardActions();

	// useEffect(() => {
	// 	dispatch(dashboardActions.fetchInteractions(data.profile_id));
	// }, [data.profile_id]);

	const [expanded, setExpanded] = useState(false);
	function handleToggleDisplay() {
		setExpanded(!expanded);
	}

	let interactions = data.interactions || [];
	let headerText = expanded ? 'Interactions:' : 'Interactions...';

	return (
		<div onClick={handleToggleDisplay}>
			<FieldTitle title={headerText} component="span" />
			{expanded &&
				interactions.map(interaction => (
					<div key={interaction.account_profile_id + ':' + interaction.created}>
						<Typography className={classes.interactionDate} variant="subtitle2" component="span">
							{' • '}
							{timestampToFormattedDate(interaction.created)} (
							<Moment unix fromNow>
								{interaction.created}
							</Moment>
							)
						</Typography>
						{'  '}
						<Typography variant="subtitle2" component="span">
							{interaction.descriptor}
						</Typography>
					</div>
				))}
		</div>
	);
}

function ContactInfoPoint({ data }) {
	const classes = useStyles();
	switch (data.type) {
		case 'website':
			return (
				<Link
					variant="body2"
					rel="noreferrer"
					target="_blank"
					href={`http://${data.value}`}
					className={classes.contactLink}
				>
					{data.value}
				</Link>
			);
		case 'TWITTER':
			return (
				<Link
					variant="body2"
					rel="noreferrer"
					target="_blank"
					href={`https://twitter.com/${data.value}`}
					className={classes.contactLink}
				>
					www.twitter.com/{data.value}
				</Link>
			);
		case 'email':
			return (
				<Link
					variant="body2"
					rel="noreferrer"
					target="_blank"
					href={`mailto:${data.value}`}
					className={classes.contactLink}
				>
					{data.value}
				</Link>
			);
		case 'phoneNumber':
			return (
				<Link
					variant="body2"
					rel="noreferrer"
					target="_blank"
					href={`tel:${data.value}`}
					className={classes.contactLink}
				>
					{data.value}
				</Link>
			);
		default:
			return (
				<Typography component="span" variant="body2">
					{data.value} ({data.type})
				</Typography>
			);
	}
}

function ContactInfo({ data }) {
	if (isEmpty(data)) {
		return '';
	}

	let info = {};

	if ('primaryEmail' in data) {
		info[data.primaryEmail] = {
			type: 'email',
			value: data.primaryEmail
		};
	}

	if ('emails' in data) {
		for (var email of data.emails) {
			info[email.emailAddress] = {
				type: 'email',
				value: email.emailAddress
			};
		}
	}

	if ('socialHandles' in data) {
		for (var handle of data.socialHandles) {
			info[`${handle.type}-${handle.name}`] = {
				type: handle.type,
				value: handle.name
			};
		}
	}

	if ('websites' in data) {
		for (var website of data.websites) {
			info[website.url] = {
				type: 'website',
				subtype: website.category,
				value: website.url
			};
		}
	}

	if ('phoneNumbers' in data) {
		for (var phoneNumber of data.phoneNumbers) {
			info[phoneNumber.number] = {
				type: 'phoneNumber',
				subtype: phoneNumber.type,
				value: phoneNumber.number
			};
		}
	}

	return (
		<FieldDisplay title="Contact Info" expandable={true}>
			<div>
				{Object.keys(info).map(key => (
					<div key={key}>
						<Typography variant="subtitle2" component="span">
							{' • '}
							<ContactInfoPoint data={info[key]} />
						</Typography>
					</div>
				))}
			</div>
		</FieldDisplay>
	);
}

export default function ProfileDetailRenderer({ profile, expand = false, onClick }) {
	const classes = useStyles();

	if (!expand) {
		return (
			<div onClick={onClick} className={classes.profileDetailRenderer}>
				<Header data={profile} />
			</div>
		);
	}

	return (
		<div onClick={onClick} className={classes.profileDetailRenderer}>
			<Header data={profile} />
			<FieldDisplay title="Status">
				{profile.status ? (
					<Typography variant="body2" component="span">
						{profile.status} - {timestampToFormattedDate(profile.statusTime)} (
						<Moment unix fromNow>
							{profile.statusTime}
						</Moment>
						)
					</Typography>
				) : (
					<Typography variant="body2" component="span">
						No Relationship Data
					</Typography>
				)}
			</FieldDisplay>
			<FieldDisplay title="Connections" value={profile.numOfConnections} />
			<FieldDisplay title="Location" value={profile.location} />
			<FieldDisplay title="Industry" value={profile.industry} />
			<Summary summary={profile.summary} />
			{profile.tags && (
				<FieldDisplay title="Tags" value={' (' + profile.tags.length + ')'} expandable={true}>
					{profile.tags.map(tag => (
						<div key={tag}>
							<Typography variant="subtitle2" component="span">
								{' • '}
								{tag}
							</Typography>
						</div>
					))}
				</FieldDisplay>
			)}
			<Interactions data={profile} />
			{profile.defaultPosition && (
				<FieldDisplay title="Position" expandable={true}>
					<div>
						<FieldDisplay title=" • Company" value={profile.defaultPosition.companyName} />
						<FieldDisplay title=" • Title" value={profile.defaultPosition.title} />
						<FieldDisplay title=" • Location" value={profile.defaultPosition.location} />
					</div>
				</FieldDisplay>
			)}
			<ContactInfo data={profile.contactInfo} />
			<FieldDisplay title="Raw Data" expandable={true}>
				<JsonDisplay data={profile} />
			</FieldDisplay>
		</div>
	);
}
