import React from 'react';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { PlatformUsersStatusBar, RowDetailToolPanel } from './components';
import { COLUMN_DEFS, DEFAULT_COLUMN_DEF } from './columns';

const SIDE_BAR_DEF = {
	toolPanels: [
		{
			id: 'columns',
			labelDefault: 'Columns',
			labelKey: 'columns',
			iconKey: 'columns',
			toolPanel: 'agColumnsToolPanel',
			width: 400
		},
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel',
			width: 400
		},
		{
			id: 'details',
			labelKey: 'details',
			labelDefault: 'Details',
			iconKey: 'columns',
			toolPanel: 'rowDetailToolPanel',
			width: 400
		}
	]
};

const STATUS_BAR_DEF = {
	statusPanels: [
		{ statusPanel: 'platformUsersStatusBar', align: 'left' },
		{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
		{ statusPanel: 'agTotalRowCountComponent', align: 'center' },
		{ statusPanel: 'agFilteredRowCountComponent' },
		{ statusPanel: 'agSelectedRowCountComponent' },
		{ statusPanel: 'agAggregationComponent' }
	]
};

// function messageCampaignRenderer({ getValue, ...props }) {
// 	let campaigns = useSelector(state => state.message_campaigns);
// 	let campaign_id = getValue();
// 	let displayText = null;
// 	if (campaign_id) {
// 		displayText = campaign_id;

// 		if (campaigns) {
// 			const campaign = campaigns.find(el => {
// 				if (el) {
// 					return el.message_campaign_id === campaign_id;
// 				} else {
// 					return false;
// 				}
// 			});
// 			if (campaign && campaign.title) {
// 				displayText = campaign.title;
// 			}
// 		}
// 	}
// 	return displayText || null;
// }

// function connectionCampaignRenderer({ getValue, ...props }) {
// 	let campaigns = useSelector(state => state.connection_campaigns);
// 	let campaign_id = getValue();
// 	let displayText = null;
// 	if (campaign_id) {
// 		displayText = campaign_id;

// 		if (campaigns) {
// 			const campaign = campaigns.find(el => {
// 				if (el) {
// 					return el.connection_campaign_id === campaign_id;
// 				} else {
// 					return false;
// 				}
// 			});
// 			if (campaign && campaign.title) {
// 				displayText = campaign.title;
// 			}
// 		}
// 	}
// 	return displayText || null;
// }

function ReportGrid({ onGridReady }) {
	// const [gridApi, setGridApi] = useState();
	const data = useSelector(state => state.events.records);

	function handleGridReady(params) {
		// setGridApi(params.api);
		if (onGridReady) {
			onGridReady(params);
		}
	}

	// // Grid Loading
	// const loadingData = useSelector(state => state.events.statusFlags.loadingData);
	// const [prevLoadingData, setPrevLoadingData] = useState(loadingData);

	// console.log('loadingData', loadingData);
	// useEffect(() => {
	// 	if (loadingData !== prevLoadingData) {
	// 		console.log('loading data state changed!', prevLoadingData, '-->', loadingData);
	// 		setPrevLoadingData(loadingData);
	// 	}
	// 	if (gridApi) {
	// 		if (loadingData) {
	// 			console.log('loading overlay');
	// 			gridApi.showLoadingOverlay();
	// 		} else if (data && data.length > 0) {
	// 			console.log('hide overlay');
	// 			gridApi.hideOverlay();
	// 		} else {
	// 			console.log('no row overlay');
	// 			gridApi.showNoRowsOverlay();
	// 		}
	// 	}
	// }, [gridApi, loadingData, data]);

	// Manage state while loading new data

	return (
		<div
			className="ag-theme-material"
			style={{
				height: '100%'
			}}
		>
			<AgGridReact
				columnDefs={COLUMN_DEFS}
				rowData={data}
				enableRangeSelection={true}
				rowSelection="multiple"
				rowDeselection={true}
				onGridReady={handleGridReady}
				groupSelectsChildren={true}
				immutableData={true}
				getRowNodeId={n => n.eventID}
				enableCharts={true}
				animateRows
				defaultColDef={DEFAULT_COLUMN_DEF}
				sideBar={SIDE_BAR_DEF}
				statusBar={STATUS_BAR_DEF}
				suppressFieldDotNotation={true}
				frameworkComponents={{
					platformUsersStatusBar: PlatformUsersStatusBar,
					rowDetailToolPanel: RowDetailToolPanel
					// messageCampaignRenderer: messageCampaignRenderer,
					// connectionCampaignRenderer: connectionCampaignRenderer
				}}
			/>
		</div>
	);
}

export default ReportGrid;
