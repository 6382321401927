import 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	pickerGroup: {},
	selectContainer: {
		marginRight: theme.spacing(4)
	},
	buttonContainer: {},
	picker: {
		marginTop: 0,
		marginRight: theme.spacing(4)
	}
}));

export default function Toolbar({ onChangeReportId }) {
	const classes = useStyles();
	const history = useHistory();

	// Report Type
	const reports = useSelector(state => state.reports.items);
	const reportId = useSelector(state => state.ui.reportDef.id);
	function handleChangeReportId(event) {
		// determine new report ID
		let reportId = event.target.value;
		if (reportId === 'no-report') {
			reportId = null;
		}
		// Modify history
		let params = new URLSearchParams(history.location.search);
		if (reportId) {
			params.set('reportId', reportId);
		} else {
			params.delete('reportId');
		}
		history.push({
			pathname: history.location.pathname,
			search: '?' + params.toString()
		});
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Grid container justify="space-between" className={classes.root}>
				<div className={classes.pickerGroup}>
					<FormControl className={classes.selectContainer}>
						<InputLabel id="report-select-label">Report</InputLabel>
						<Select
							labelId="report-select-label"
							id="report-select"
							value={reportId || 'no-report'}
							onChange={handleChangeReportId}
						>
							<MenuItem key={-1} value={'no-report'}>
								(No Report)
							</MenuItem>
							{reports.map((report, i) => (
								<MenuItem key={i} value={report.id}>
									{report.title || '(Untitled Report)'}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</Grid>
		</MuiPickersUtilsProvider>
	);
}
