export function getFormattedDate(date) {
	if (!date) {
		date = new Date();
	}
	return date.toISOString().slice(0, 10);
}

export function addDaysToDate(date, days) {
	date.setDate(date.getDate() + days);
	return date;
}

export function dateIsValid(date) {
	return date instanceof Date && !isNaN(date);
}
