import React from 'react';
import { makeStyles, withStyles, Typography, Paper } from '@material-ui/core';
import { mdiCodeJson, mdiPlusOutline, mdiRefresh } from '@mdi/js';
import { TooltipButton } from './mui';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
	root: {
		'&:not(:last-child)': {
			borderBottom: 0
		},
		'&:before': {
			display: 'none'
		},
		'&$expanded': {
			margin: 0
		}
	},
	expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		'&$expanded': {
			minHeight: 0
		}
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		margin: 0,
		order: 2,
		'&$expanded': {
			margin: 0
		}
	},
	expandIcon: {
		order: 1,
		margin: '-16px'
	},
	expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
	root: {
		padding: `0 ${theme.spacing(4)}px 0`,
		marginBottom: theme.spacing(1)
	}
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%'
	},
	controls: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	title: {
		paddingLeft: theme.spacing(2)
	}
}));

function TitleBarContent({ title, onClickRefreshButton, onClickAddButton, onClickDataButton, customButton }) {
	const classes = useStyles();
	return (
		<div className={classes.root} elevation={2}>
			<Typography component="h1" variant="h6">
				{title}
			</Typography>

			<div className={classes.controls}>
				{onClickRefreshButton && (
					<TooltipButton tooltip="Refresh" icon={mdiRefresh} iconSize={0.75} onClick={onClickRefreshButton} />
				)}
				{onClickAddButton && (
					<TooltipButton
						tooltip="Create New"
						icon={mdiPlusOutline}
						iconSize={0.75}
						onClick={onClickAddButton}
					/>
				)}
				{onClickDataButton && (
					<TooltipButton
						tooltip="Show Raw Data"
						icon={mdiCodeJson}
						iconSize={0.75}
						onClick={onClickDataButton}
					/>
				)}
				{customButton}
			</div>
		</div>
	);
}

function Wrapper(props) {
	const classes = useStyles();
	return (
		<div style={{ width: '100%' }}>
			{props.children ? (
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>{props.content}</AccordionSummary>
					<AccordionDetails>{props.children}</AccordionDetails>
				</Accordion>
			) : (
				<Paper className={classes.title}>{props.content}</Paper>
			)}
		</div>
	);
}

export default function TitleBar({
	title,
	onClickRefreshButton,
	onClickAddButton,
	onClickDataButton,
	children,
	customButton
}) {
	return (
		<Wrapper
			content={
				<TitleBarContent
					title={title}
					onClickRefreshButton={onClickRefreshButton}
					onClickAddButton={onClickAddButton}
					onClickDataButton={onClickDataButton}
					customButton={customButton}
				/>
			}
			children={children}
		/>
	);
}
