import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Checkbox, TextField } from 'final-form-material-ui';
import { validators, TooltipButton } from '../../../../../widgets';
import { makeStyles, Divider } from '@material-ui/core';
import { mdiTrashCan } from '@mdi/js';

export function EnabledField(props) {
	return (
		<>
			<Field name="enabled" component={Checkbox} type="checkbox" />
			<label>Enabled</label>
		</>
	);
}

export function CampaignIDField(props) {
	return (
		<Field
			name="message_campaign_id"
			component={TextField}
			type="string"
			label="Campaign ID"
			validate={validators.required}
			fullWidth
			disabled
			{...props}
		/>
	);
}

export function TitleField(props) {
	return (
		<Field
			name="title"
			component={TextField}
			type="text"
			label="Title"
			validate={validators.required}
			fullWidth
			required
			{...props}
		/>
	);
}

const useMessageFieldStyles = makeStyles(theme => ({
	messageContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: theme.spacing(2)
	},
	messageLabelBar: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	messageLabel: {
		flexGrow: 1
	},
	delayBar: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	delayInput: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		width: '75px'
	}
}));

export function MessagesField(props) {
	const classes = useMessageFieldStyles();
	return (
		<FieldArray name="messages" required validate={validators.required} label="Messages">
			{({ fields }) =>
				fields.map((name, index) => (
					<div className={classes.messageContainer} key={name}>
						<div className={classes.messageLabelBar}>
							<label className={classes.messageLabel}>Message #{index + 1}</label>
							<TooltipButton
								tooltip="Delete"
								icon={mdiTrashCan}
								iconSize={0.75}
								onClick={() => fields.remove(index)}
							/>
						</div>
						<div className={classes.delayBar}>
							<label>Delay:</label>
							<Field
								className={classes.delayInput}
								name={`${name}.delay`}
								component={TextField}
								type="number"
								validate={validators.all(validators.required, validators.isNumber)}
								required
								parse={value => (isNaN(parseInt(value)) ? null : parseInt(value))}
							/>
							<label> days</label>
						</div>
						<Field
							fullWidth
							name={`${name}.message`}
							component={TextField}
							multiline={true}
							placeholder="Message"
							validate={validators.required}
							required
						/>
						<Divider absolute />
					</div>
				))
			}
		</FieldArray>
	);
}
