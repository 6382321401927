import profileDetails from './profileDetails';
import profileDetailsByStatus from './profileDetailsByStatus';
import profileDetailsByRequestDate from './profileDetailsByRequestDate';
import statusCountByRequestDate from './statusCountByRequestDate';
import statusCountByStatusDate from './statusCountByStatusDate';

const REPORT_TYPES = {
	// none: {
	// 	key: 'none',
	// 	label: 'Not Applicable',
	// 	getGridState: () => null
	// },
	profileDetails: {
		key: 'profileDetails',
		label: 'Profile Details, Most Recent',
		getGridState: profileDetails
	},
	profileDetailsByStatus: {
		key: 'profileDetailsByStatus',
		label: 'Profile Details by Status',
		getGridState: profileDetailsByStatus
	},
	profileDetailsByRequestDate: {
		key: 'profileDetailsByRequestDate',
		label: 'Profile Details by Request Date',
		getGridState: profileDetailsByRequestDate
	},
	statusCountByRequestDate: {
		key: 'statusCountByRequestDate',
		label: 'Status Count by Request Date',
		getGridState: statusCountByRequestDate
	},
	statusCountByStatusDate: {
		key: 'statusCountByStatusDate',
		label: 'Status Count by Status Date',
		getGridState: statusCountByStatusDate
	}
};

export default REPORT_TYPES;
