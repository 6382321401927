import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { getFormattedDate, addDaysToDate } from './utils';

//endregion Actions

const stage = process.env.REACT_APP_RUN_ENVIRONMENT;
const API_DEV = 'https://zfb7oytr6i.execute-api.us-east-2.amazonaws.com/dev';
const API_PROD = 'https://acfzedeub2.execute-api.us-east-1.amazonaws.com/dev';
const API_HOSTS = {
	'dev-original': API_DEV,
	dev: API_PROD, // normally dev
	qa: API_PROD, // normally dev
	prod: API_PROD
};
export const API_HOST = API_HOSTS[stage];

const INITIAL_STATE = {
	num_records: 0,
	accountId: null,
	account: {},
	startDate: getFormattedDate(addDaysToDate(new Date(), -30)),
	endDate: getFormattedDate(),
	fetchRecordLimit: 1000,
	fetchRequestTimestamp: 0,
	statusFlags: {
		fetchingAccountData: false,
		fetchingAccountActivity: false
	},
	records: {}
};

//endregion Constants

//region Action Types

const SET_STATUS_FLAG = 'LI:DASH:SET_STATUS_FLAG';
const SET_FETCH_RECORD_LIMIT = 'LI:DASH:SET_FETCH_RECORD_LIMIT';
const SET_FETCH_REQUEST_TS = 'LI:DASH:SET_FETCH_REQUEST_TS';
const SAVE_ACCOUNT_ID = 'LI:DASH:SAVE_ACCOUNT_ID';
const SAVE_ACCOUNT_DATA = 'LI:DASH:SAVE_ACCOUNT_DATA';
const SAVE_DATE_RANGE = 'LI:DASH:SAVE_DATE_RANGE';
const SAVE_RECORDS = 'LI:DASH:SAVE_RECORDS';
const CLEAR_RECORDS = 'LI:DASH:CLEAR_RECORDS';

//endregion Action Types

//region Actions - Account Data Management

/** Sets the value of a status flag. */
function setStatusFlag(flag, value) {
	return {
		type: SET_STATUS_FLAG,
		flag: flag,
		value: value
	};
}

function setFetchRecordLimit(value) {
	return {
		type: SET_FETCH_RECORD_LIMIT,
		fetchRecordLimit: value
	};
}

function setFetchRequestTimestamp(value) {
	return {
		type: SET_FETCH_REQUEST_TS,
		fetchRequestTimestamp: value
	};
}

/** Sets the new account ID, resets dates and data, and triggers new load. */
function initializeWithAccountID(accountId) {
	return (dispatch, getState) => {
		// Set Account ID
		let cachedAccountId = getState().accountId;
		if (cachedAccountId === accountId) return;
		dispatch(setAccountId(accountId));

		// Fetch records
		let startDate = getState().startDate;
		let endDate = getState().endDate;
		dispatch(fetchRecordsForDateRange(startDate, endDate));
	};
}

function refreshRecordForDateRange(startDate, endDate) {
	return (dispatch, getState) => {
		dispatch(fetchRecordsForDateRange(startDate, endDate));
	};
}

/** Sets the new account ID and triggers data load */
function setAccountId(accountId) {
	return (dispatch, getState) => {
		let cachedAccountId = { ...getState().accountId };
		if (cachedAccountId === accountId) return;
		dispatch(saveAccountId(accountId));
		dispatch(fetchAccountInfo());
	};
}

/** Triggers the account ID to be saved in the reducer. */
function saveAccountId(accountId) {
	return {
		type: SAVE_ACCOUNT_ID,
		accountId: accountId
	};
}

/** Fetches account data from the server. */
function fetchAccountInfo() {
	return (dispatch, getState) => {
		let { accountId } = { ...getState() };
		const url = API_HOST + `/accounts/${accountId}`;

		dispatch(setStatusFlag('fetchingAccountData', true));
		fetch(url)
			.then(res => res.json())
			.then(data => {
				dispatch(saveAccountData(data));
				dispatch(setStatusFlag('fetchingAccountData', false));
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingAccountData', 'error'));
			});
	};
}

/** Triggers the account Data to be saved in the reducer. */
function saveAccountData(accountData) {
	return {
		type: SAVE_ACCOUNT_DATA,
		accountData: accountData
	};
}

function setDateRange(start, end) {
	return (dispatch, getState) => {
		// TODO clear out old data
		let startDate = getFormattedDate(new Date(start));
		let endDate = getFormattedDate(new Date(end));

		dispatch(saveDateRange(startDate, endDate));
		dispatch(fetchRecordsForDateRange(startDate, endDate));
	};
}

function saveDateRange(start, end) {
	return {
		type: SAVE_DATE_RANGE,
		start: start,
		end: end
	};
}

function fetchRecordsForDateRange(start, end) {
	return (dispatch, getState) => {
		const ts = new Date().getTime();
		dispatch(setFetchRequestTimestamp(ts));
		dispatch(clearRecords());
		dispatch(fetchPageOfRecordsForDateRange(start, end, ts));
	};
}

function fetchPageOfRecordsForDateRange(start, end, ts) {
	return (dispatch, getState) => {
		let { accountId, fetchRecordLimit, fetchRequestTimestamp } = {
			...getState()
		};

		// Cancel request if newer request has been made
		if (ts !== fetchRequestTimestamp) {
			return;
		}

		const url = API_HOST + `/account/activity/${accountId}?start=${start}&end=${end}&limit=${fetchRecordLimit}`;
		dispatch(setStatusFlag('fetchingAccountActivity', true));
		fetch(url)
			.then(res => res.json())
			.then(raw_data => {
				// throw out returned records if newer request has been made
				let { fetchRequestTimestamp } = { ...getState() };
				if (ts !== fetchRequestTimestamp) {
					return;
				}

				// save records
				dispatch(saveRecords(raw_data.data));
				dispatch(setStatusFlag('fetchingAccountActivity', false));

				// If raw_data.more === true, then fetch more records
				if (raw_data.more === true) {
					let new_end_date = addDaysToDate(new Date(end), -fetchRecordLimit);
					let new_end = getFormattedDate(new_end_date);
					setTimeout(() => {
						dispatch(fetchPageOfRecordsForDateRange(start, new_end, ts));
					}, 250);
				}
			})
			.catch(err => {
				console.error('Error', err);
				dispatch(setStatusFlag('fetchingAccountActivity', 'error'));
			});
	};
}

function saveRecords(records) {
	return {
		type: SAVE_RECORDS,
		records: records
	};
}

function clearRecords() {
	return {
		type: CLEAR_RECORDS
	};
}

// Action Dispatching...

function useLiDashboardActions() {
	return {
		initializeWithAccountID: initializeWithAccountID,
		refreshRecordForDateRange: refreshRecordForDateRange,
		setDateRange: setDateRange,
		setFetchRecordLimit: setFetchRecordLimit
	};
}

//endregion Actions

//region Reducer

const RECORD_SUM_TYPES = [
	'connection-requested',
	'connection-request-withdrawn',
	'connection-accepted',
	'connection-removed'
];

function updateRecords(state, records) {
	records.forEach(record => {
		RECORD_SUM_TYPES.forEach(key => {
			let count_key = 'count-' + key;
			if (key in record) {
				record[count_key] = Object.keys(record[key]).length;
				delete record[key];
			} else {
				record[count_key] = 0;
			}
		});
		state.records[record.date] = record;
	});
	state.num_records = Object.keys(state.records).length;
}

function reducer(state = INITIAL_STATE, action) {
	if (action.type.startsWith('LI:DASH:')) {
		let newState = { ...state };
		switch (action.type) {
			case SET_STATUS_FLAG:
				newState.statusFlags[action.flag] = action.value;
				break;
			case SET_FETCH_RECORD_LIMIT:
				newState.fetchRecordLimit = action.fetchRecordLimit;
				break;
			case SET_FETCH_REQUEST_TS:
				newState.fetchRequestTimestamp = action.fetchRequestTimestamp;
				break;
			case SAVE_ACCOUNT_ID:
				newState.accountId = action.accountId;
				break;
			case SAVE_ACCOUNT_DATA:
				newState.account = action.accountData;
				break;
			case SAVE_DATE_RANGE:
				newState.startDate = action.start;
				newState.endDate = action.end;
				break;
			case SAVE_RECORDS:
				updateRecords(newState, action.records);
				break;
			case CLEAR_RECORDS:
				newState.records = {};
				break;
			default:
				console.log('LI DASH Event Type not handled!', action);
		}
		return newState;
	}

	return state;
}

//endregion Reducer

//region Export

const store = createStore(reducer, applyMiddleware(thunk));
export { reducer, store, useLiDashboardActions };

//endregion Export
