import React from "react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
    Button, Dialog, DialogActions, DialogContent, Typography, 
    DialogContentText, DialogTitle, IconButton, createMuiTheme, ThemeProvider
} from "@material-ui/core";

function stopPropagation(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
}

const styles =  createMuiTheme({
    palette: {
        primary: {
            main: '#FF2951',
            compliment: 'rgba(255,41,81,.56)'
        },
        secondary: {
            light: '#65faff',
            main: '#0cc7cc',
            dark: '#00969b'
        },
        text: {
            primary: '#808080'
        }
    },
    dialogAction: {
        justifyContent: 'center',
    },
    button: {
        margin: '1 rem'
    }
})

/**
 * Upgrade dialog that allows for adidtion of close button, customizable title, dialog content and 
 * submit button text. Includes callbacks that are triggered when the user
 * either confirms or closes the dialog.
 * 
 * See also `useConfirmation()` hook and `ConfirmationRequiredButton`.
 */
export function UpgradeDialog({
    closeButton, title, text, cancelLabel="Cancel", confirmLabel="OK", 
    open, onClose, onConfirm, children
}) {
    return (
        <ThemeProvider theme={styles}>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClick={stopPropagation}
                style={{alignContent:'center', alignItems:'center'}}
            >
                {closeButton &&
                    < DialogActions
                        style={{padding: '5px 10px 0 0'}}
                    >
                        <IconButton 
                            color="primary" 
                            aria-label="add an alarm"
                            onClick={onClose}
                            style={{padding: 0}}
                        >
                            <HighlightOffIcon />
                        </IconButton>       
                    </DialogActions>    
                }
                {title && 
                    <DialogTitle
                        disableTypography
                        style={{padding: "10px 20px 20px 20px", textAlign: "center"}}
                    >
                        <Typography
                            varian="h2"
                            color="primary"
                            style={{fontSize: "1.2rem", fontWeight: 600}}
                        >
                            {title}
                        </Typography>
                    </DialogTitle> 
                }
                <DialogContent
                    style={{padding: "0 30px"  }}
                >
                    {text &&
                        <DialogContentText
                            style={{alignContent: "center", alignItems: "center", padding: "0"}}
                        >
                            {text}
                        </DialogContentText>
                        
                    }
                    {children &&
                        <DialogContentText
                        style={{alignContent: "center", alignItems: "center", padding: "0"}}
                        >
                            {children}
                        </DialogContentText>
                    }
                </DialogContent>
                < DialogActions className="dialogContent">
                    <Button
                        onClick={onConfirm}
                        variant="contained"
                        color="primary"
                        className="button"
                        fullWidth
                    >
                        {confirmLabel}
                    </Button>
                </DialogActions>
                < DialogActions
                    style={{padding: '0 8px', margin: '0 8px 15px 8px'}}
                >
                <Button
                        onClick={onClose}
                        variant="text"
                        color="inherit"
                        className="button"
                        style={{padding: '0 8px', margin: '0 8px'}}
                        fullWidth
                    >
                        {cancelLabel}
                    </Button>       
                </DialogActions>
            </Dialog >
        </ThemeProvider>
    )
}

export default UpgradeDialog;