import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createResource } from 'redux-rest-resource';
import { PROXY_API_HOST } from '../../../js/constants/env-settings';

const stage = process.env.REACT_APP_RUN_ENVIRONMENT;
const API_DEV = 'https://zfb7oytr6i.execute-api.us-east-2.amazonaws.com/dev';
const API_PROD = 'https://acfzedeub2.execute-api.us-east-1.amazonaws.com/dev';
const API_HOSTS = {
	local: 'http://localhost:3200',
	dev: API_DEV,
	qa: API_DEV,
	prod: API_PROD
};
export const API_HOST = API_HOSTS[stage];

const OPTIONAL_PROPERTIES = [
	'created',
	'enabled',
	'modified',
	'owner',
	'owner_id',
	'status',
	'saved_search',
	'notes',
	'proxy_host',
	'proxy_port',
	'proxy_password',
	'proxy_username',
	'connection_message'
];

function validate_response_data(data) {
	data.id = data.account_id;
	OPTIONAL_PROPERTIES.forEach(prop => {
		if (data[prop] === undefined) {
			data[prop] = '';
		}
	});
	return data;
}

function validate_request_data(data) {
	delete data.id;
	OPTIONAL_PROPERTIES.forEach(prop => {
		if (!data[prop]) {
			delete data[prop];
		}
	});
	if ('proxy_port' in data && data.proxy_port) {
		data.proxy_port = parseInt(data.proxy_port);
	}
	return data;
}

function account_update_reducer(action_state_field) {
	return (state, action) => {
		let newState = { ...state };
		switch (action.status) {
			case 'pending':
				newState[action_state_field] = true;
				return newState;
			case 'resolved': {
				const id = action.context.id;
				const update = action.body;
				const listItemIndex = state.items.findIndex(el => el.id === id);
				const updatedItems = state.items.slice();
				if (listItemIndex !== -1) {
					updatedItems[listItemIndex] = {
						...updatedItems[listItemIndex],
						...update
					};
				}
				newState[action_state_field] = false;
				newState.items = updatedItems;
				return newState;
			}
			case 'rejected':
				newState[action_state_field] = false;
				return newState;
			default:
				return state;
		}
	};
}

const { types, actions, rootReducer } = createResource({
	name: 'account',
	pluralName: 'accounts',
	url: `${API_HOST}/accounts/:account_id`,
	actions: {
		fetch: {
			transformResponse: res => {
				for (var i in res.body) {
					res.body[i] = validate_response_data(res.body[i]);
				}
				return res;
			}
		},
		create: {
			url: `${API_HOST}/accounts`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		update: {
			method: 'PUT',
			assignResponse: true,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		enable: {
			method: 'PATCH',
			url: `${API_HOST}/enable/:account_id`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			},
			reduce: account_update_reducer('isEnablingAccount')
		},
		reassignProxy: {
			method: 'PUT',
			url: `${PROXY_API_HOST}/assign/:account_id`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			reduce: account_update_reducer('isReassigningProxy'),
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			}
		},
		submitSecurityCode: {
			method: 'PATCH',
			url: `${API_HOST}/submit-code/:account_id`,
			transformResponse: res => {
				res.body = validate_response_data(res.body);
				return res;
			},
			body: (getState, { actionId, context, contextOpts }) => {
				return validate_request_data({ ...context });
			},
			reduce: account_update_reducer('isSubmittingSecurityCode')
		},
		displayCreationForm: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				creationFormVisible: action.context
			})
		},
		displayEditForm: {
			isPure: true,
			reduce: (state, action) => {
				return {
					...state,
					editFormVisible: action.context.visible,
					editFormData: state.items.find(item => item.account_id === action.context.account_id)
				};
			}
		},
		displayRawJson: {
			isPure: true,
			reduce: (state, action) => ({
				...state,
				rawJsonVisible: action.context
			})
		}
	}
});

//region Export

const store = createStore(rootReducer, applyMiddleware(thunk));
export { types, actions, rootReducer as reducer, store };

//endregion Export
