import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AllModules } from 'ag-grid-enterprise';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

//constants
import { PLOT_COLORS } from './constants';

const PLOT_COLORS_4 = PLOT_COLORS.slice(1, 5);
const PLOT_COLORS_2 = [PLOT_COLORS[0], PLOT_COLORS[5]];

const useStyles = makeStyles(theme => ({
	wrapper: {
		width: '100%',
		height: '100%',
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	chart: {
		flexGrow: 1,
		height: 0
	}
}));

function Chart(props) {
	const classes = useStyles();
	const [modules] = useState(AllModules);
	const [columnDefs] = useState([
		{
			field: 'date',
			width: 150,
			chartDataType: 'category'
		},
		{
			headerName: 'Total',
			field: 'num-connections',
			chartDataType: 'series'
		},
		{
			headerName: 'Requested',
			field: 'count-connection-requested',
			chartDataType: 'series'
		},
		{
			headerName: 'Withdrawn',
			field: 'count-connection-request-withdrawn',
			chartDataType: 'series'
		},
		{
			headerName: 'Accepted',
			field: 'count-connection-accepted',
			chartDataType: 'series'
		},
		{
			headerName: 'Removed',
			field: 'count-connection-removed',
			chartDataType: 'series'
		},
		{
			headerName: 'Pending',
			field: 'pending-connections',
			chartDataType: 'series'
		}
	]);
	const [defaultColDef] = useState({
		editable: true,
		sortable: true,
		flex: 1,
		minWidth: 100,
		filter: true,
		resizable: true
	});
	const [popupParent] = useState(document.body);
	const [rowData, setRowData] = useState([]);
	useEffect(() => {
		setRowData(props.plotData);
	}, [props.plotData]);

	const onGridReady = params => {
		params.api.sizeColumnsToFit();
	};

	const onFirstDataRendered = params => {
		var cellRange = {
			rowStartIndex: 0,
			// rowEndIndex: 30,
			columns: [
				'date',
				'count-connection-accepted',
				'count-connection-requested',
				'count-connection-request-withdrawn',
				'count-connection-removed'
			]
		};
		var createRangeChartParams = {
			cellRange: cellRange,
			chartType: 'line',
			chartContainer: document.querySelector('#generalChart'),
			aggFunc: 'sum'
		};
		params.api.createRangeChart(createRangeChartParams);
	};
	const onFirstDataRenderedForTotal = params => {
		var cellRange = {
			rowStartIndex: 0,
			rowEndIndex: 30,
			columns: ['date', 'num-connections', 'pending-connections']
		};
		var createRangeChartParams = {
			cellRange: cellRange,
			chartType: 'line',
			chartContainer: document.querySelector('#totalChart'),
			aggFunc: 'sum'
		};
		params.api.createRangeChart(createRangeChartParams);
	};

	const processChartOptions = params => {
		var options = params.options;
		var color = params.color;

		options.title = {
			text: params.titleText,
			fontStyle: 'italic',
			fontWeight: 'bold',
			fontSize: 18,
			fontFamily: 'Arial, sans-serif',
			color: 'black'
		};

		options.seriesDefaults.fill.colors = color;
		options.seriesDefaults.stroke.colors = color;
		options.seriesDefaults.stroke.width = 3;

		options.seriesDefaults.highlightStyle.fill = color;
		options.seriesDefaults.highlightStyle.stroke = color;

		if (params.type === 'line') {
			options.seriesDefaults.marker.type = 'diamond';
			options.seriesDefaults.marker.size = 4;
			options.seriesDefaults.marker.strokeWidth = 3;
		}
		options.seriesDefaults.tooltip.renderer = function(params) {
			var x = params.datum[params.xKey];
			var y = params.datum[params.yKey];
			return (
				'<u style="color: ' +
				params.color +
				'">' +
				(params.title || params.yName) +
				'</u><br><br><b>' +
				params.xName.toUpperCase() +
				':</b> ' +
				x +
				'<br/><b>' +
				params.yName.toUpperCase() +
				':</b> ' +
				y
			);
		};
		return options;
	};

	return (
		<div className={classes.wrapper}>
			<div id="myGrid" className="ag-theme-material">
				<AgGridReact
					modules={modules}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					popupParent={popupParent}
					enableRangeSelection={true}
					// enableCharts={true}
					processChartOptions={params =>
						processChartOptions({
							...params,
							color: PLOT_COLORS_4,
							titleText: 'Connections during time'
						})
					}
					onGridReady={onGridReady}
					onFirstDataRendered={onFirstDataRendered}
					rowData={rowData}
				/>
				<AgGridReact
					modules={modules}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					popupParent={popupParent}
					enableRangeSelection={true}
					// enableCharts={true}
					processChartOptions={params =>
						processChartOptions({
							...params,
							color: PLOT_COLORS_2,
							titleText: 'Connections during time'
						})
					}
					onGridReady={onGridReady}
					onFirstDataRendered={onFirstDataRenderedForTotal}
					rowData={rowData}
				/>
			</div>
			<div id="generalChart" className={clsx(classes.chart, 'ag-theme-material')} />
			<div id="totalChart" className={clsx(classes.chart, 'ag-theme-material')} />
		</div>
	);
}
export default Chart;
