import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Grid,
	Typography
} from '@material-ui/core';
import { JsonDisplay } from '../../../../widgets';
import FormActions from '../../../../widgets/forms/formActions';
import {
	AccountIDField,
	EnabledField,
	InstagramUsernameField,
	InstagramPasswordField,
	EmailField,
	EmailPasswordField,
	PhoneField,
	CustomerField,
	CustomerIDField,
	ProxyHostField,
	ProxyPortField,
	ProxyUsernameField,
	ProxyPasswordField
} from './components';

const useStyles = makeStyles(theme => ({
	paper: {
		margin: 20
	}
}));

function EditFormRenderer({ onClose, handleSubmit, form, submitting, pristine, values }) {
	let rawJsonVisible = useSelector(state => state.rawJsonVisible);
	rawJsonVisible = rawJsonVisible || false; // set to false if null
	const [proxyPanelOpen, setProxyPanelOpen] = useState(true);

	return (
		<form onSubmit={handleSubmit} noValidate>
			<Grid container alignItems="flex-start" spacing={1}>
				<Grid item xs={6}>
					<AccountIDField disabled />
				</Grid>
				<Grid item xs={6}>
					<EnabledField />
				</Grid>
				<Grid item xs={6}>
					<InstagramUsernameField />
				</Grid>
				<Grid item xs={6}>
					<InstagramPasswordField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={6}>
					<EmailField />
				</Grid>
				<Grid item xs={6}>
					<EmailPasswordField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={6}>
					<PhoneField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<Grid item xs={6}>
					<CustomerIDField />
				</Grid>
				<Grid item xs={6}>
					<CustomerField />
				</Grid>

				<Grid item xs={12}>
					<Divider absolute />
				</Grid>
				<ExpansionPanel expanded={proxyPanelOpen} onChange={() => setProxyPanelOpen(!proxyPanelOpen)}>
					<ExpansionPanelSummary aria-controls="proxy-panel-content" id="proxy-panel-header">
						<Typography>Proxy</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container alignItems="flex-start" spacing={1}>
							<Grid item xs={6}>
								<ProxyHostField disabled />
							</Grid>
							<Grid item xs={6}>
								<ProxyPortField validate="" disabled />
							</Grid>
							<Grid item xs={6}>
								<ProxyUsernameField disabled />
							</Grid>
							<Grid item xs={6}>
								<ProxyPasswordField disabled />
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<FormActions form={form} onClose={onClose} pristine={pristine} submitting={submitting} />
			</Grid>
			<JsonDisplay data={values} visible={rawJsonVisible} />
		</form>
	);
}

function EditFormDialog({ open, data, onSubmit, onClose }) {
	var classes = useStyles();
	return (
		<Dialog
			classes={{
				paper: classes.paper
			}}
			open={open}
			onClose={onClose}
		>
			<DialogTitle>Edit Proxy</DialogTitle>
			<DialogContent>
				<div style={{ padding: 2, margin: 1, maxWidth: 600 }}>
					<Form onSubmit={onSubmit} onClose={onClose} initialValues={data} render={EditFormRenderer} />
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default EditFormDialog;
