import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { JsonDisplay } from '../../../widgets';
import TitleBar from '../../../widgets/TitleBar';
import { store, useLiMessagingActions } from './store';
import { default as ConversationList } from './conversationList';
import { default as ConversationDetails } from './conversationDetails';
import ProfilesDisplay from './profilesDisplay';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	spacerHorizontal: {
		height: theme.spacing(2)
	},
	display: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		height: 0
	},
	conversationList: {
		minWidth: '36ch',
		maxWidth: '36ch',
		overflow: 'auto',
		marginRight: theme.spacing(2)
	},
	profilesDisplay: {
		minWidth: '50ch',
		maxWidth: '50ch',
		overflow: 'auto',
		marginRight: theme.spacing(2)
	},
	conversationDetails: {
		flex: 1
		// overflow: 'auto'
	},
	json: {
		padding: theme.spacing(2)
	},
	selectConversationText: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));

function WrappedMessagingPage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const actions = useLiMessagingActions();
	const data = useSelector(state => state);
	const [showData, setShowData] = useState(false);
	const selectedMessaging = useSelector(state => state.selectedMessaging);

	// Handle changes to the provided account ID
	const { accountId } = useParams();
	const cachedAccountId = useSelector(state => state.accountId);
	useEffect(() => {
		if (accountId !== cachedAccountId) {
			dispatch(actions.initializeWithAccountID(accountId));
		}
	}, [accountId, cachedAccountId]);

	// Misc Actions
	function handleClickRefreshButton(event) {
		if (cachedAccountId) {
			dispatch(actions.refreshData());
		}
	}
	function handleClickDataButton(event) {
		setShowData(!showData);
	}

	return (
		<div className={classes.root}>
			<TitleBar
				title={'Messages: ' + data.account.li_username}
				onClickRefreshButton={handleClickRefreshButton}
				onClickDataButton={handleClickDataButton}
			/>
			{showData ? (
				<Paper className={classes.display} elevation={2}>
					<div className={classes.json}>
						<JsonDisplay data={data} />
					</div>
				</Paper>
			) : (
				<div className={classes.display}>
					<Paper className={classes.conversationList} elevation={2}>
						<ConversationList />
					</Paper>
					{selectedMessaging ? (
						<Fragment>
							<Paper className={classes.profilesDisplay} elevation={2}>
								<ProfilesDisplay profiles={selectedMessaging.profiles} />
							</Paper>
							<Paper className={classes.conversationDetails} elevation={2}>
								<ConversationDetails />
							</Paper>
						</Fragment>
					) : (
						<Paper className={classes.conversationDetails} elevation={2}>
							<div className={classes.selectConversationText}>
								<Typography>Please select a conversation.</Typography>
							</div>
						</Paper>
					)}
				</div>
			)}
		</div>
	);
}

export default function MessagingPage() {
	return (
		<Provider store={store}>
			<WrappedMessagingPage />
		</Provider>
	);
}
