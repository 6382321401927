import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { JsonDisplay } from '../../../../widgets';
import FormActions from '../../../../widgets/forms/formActions';
import {
	HostField,
	PortField,
	UsernameField,
	PasswordField,
	LocationField,
	SourceField,
	TypeField,
	NotesField
} from './components';

const useStyles = makeStyles(theme => ({
	paper: {
		margin: 20
	}
}));

function ProxyEditFormRenderer({ onClose, handleSubmit, form, submitting, pristine, values }) {
	let rawJsonVisible = useSelector(state => state.proxies.rawJsonVisible);
	rawJsonVisible = rawJsonVisible || false; // set to false if null

	return (
		<form onSubmit={handleSubmit} noValidate>
			<Grid container alignItems="flex-start" spacing={1}>
				<Grid item xs={6}>
					<HostField disabled />
				</Grid>
				<Grid item xs={6}>
					<PortField disabled />
				</Grid>
				<Grid item xs={6}>
					<UsernameField disabled />
				</Grid>
				<Grid item xs={6}>
					<PasswordField disabled />
				</Grid>
				<Grid item xs={6}>
					<LocationField />
				</Grid>
				<Grid item xs={6}>
					<SourceField />
				</Grid>
				<Grid item xs={12}>
					<TypeField />
				</Grid>
				<Grid item xs={12}>
					<NotesField />
				</Grid>
				<FormActions form={form} onClose={onClose} pristine={pristine} submitting={submitting} />
			</Grid>
			<JsonDisplay data={values} visible={rawJsonVisible} />
		</form>
	);
}

function ProxyEditFormDialog({ open, data, onSubmit, onClose }) {
	const classes = useStyles();
	return (
		<Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
			<DialogTitle id="proxy-edit-form-dialog-title">Edit Proxy</DialogTitle>
			<DialogContent>
				<div style={{ maxWidth: 600 }}>
					<Form onSubmit={onSubmit} onClose={onClose} initialValues={data} render={ProxyEditFormRenderer} />
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default ProxyEditFormDialog;
